import {
  Component,
  OnInit,
  Input,
  OnDestroy,
  Injector,
  ReflectiveInjector,
  InjectionToken,
  EventEmitter,
  Output
} from '@angular/core'
import { Observable, Subject, BehaviorSubject } from 'rxjs'
import { map } from 'rxjs/operators'
import { ProcessRecordsService } from '../../process-records.service'
import { RecordChildSingleComponent } from '../record-child-single/record-child-single.component'
import { RecordChildListComponent } from '../record-child-list/record-child-list.component'
import { MatTableDataSource, MatDialog } from '@angular/material'
import { LoadingDialogBoxComponent } from '../../loading-dialog-box-component/loading-dialog-box-component.component'
import { SnackbarService } from 'src/app/snackbar.service'
import { RequestDialogComponent } from '../request-dialog/request-dialog.component'
import { RecordViewService } from '../record-view.service'
import { RequestsSocketService } from '../../requests-socket.service'

export const DATA = new InjectionToken<string>('DATA')

@Component({
  selector: 'record-child-view',
  templateUrl: './record-child-view.component.html',
  styleUrls: ['./record-child-view.component.css']
})
export class RecordChildViewComponent implements OnInit {
  injector: Injector
  data = []
  tableData = new MatTableDataSource<any>()
  actions
  access
  form
  closeDialog = new BehaviorSubject<boolean>(false)
  closeDialog$: Observable<boolean> = this.closeDialog.asObservable()
  renderingComponent: any = null
  @Output() requestEmitter = new EventEmitter()
  @Output() selectedFir = new EventEmitter()
  @Input() render: Observable<any>
  @Input() processId
  @Input() recordId
  @Input() recordFirs
  @Input() selectedStatus
  @Output() executeEvent = new EventEmitter()
  constructor(
    private processRecordService: ProcessRecordsService,
    private dialog: MatDialog,
    private _snackbar: SnackbarService,
    private recordViewService: RecordViewService,
    private requestSocketService: RequestsSocketService
  ) {}

  ngOnInit() {
    this.render.subscribe(renderData => {
      this.data = renderData.data
      this.actions = renderData.actions
      this.access = renderData.access
      this.form = renderData.form
      this.tableData = new MatTableDataSource<any>(this.data)
    })
  }

  pushItem(event) {
    const dialogRef = this.dialog.open(LoadingDialogBoxComponent, {
      disableClose: true,
      panelClass: 'loadingDialog',
      // height: '30%',
      data: {}
    })
    this.processRecordService
      .pushAction(this.form._id, this.recordId, this.processId, event.data)
      .subscribe(
        data => {
          this.data.push(data.data)
          this.tableData = new MatTableDataSource<any>(this.data)
          this.dialog.closeAll()
          this.processRecordService.setLoading(false)
          this._snackbar.showSnackbar(data.msg, 'success')
        },
        err => {}
      )
  }

  pushSingleItem(event) {
    this.processRecordService.setLoading(true)
    const dialogRef = this.dialog.open(LoadingDialogBoxComponent, {
      disableClose: true,
      panelClass: 'loadingDialog',
      // height: '30%',
      data: {}
    })
    this.processRecordService
      .pushAction(this.form._id, this.recordId, this.processId, event.data)
      .subscribe(
        data => {
          this.data.push(data.data)
          this.tableData = new MatTableDataSource<any>(this.data)
          this.dialog.closeAll()
          this.processRecordService.setLoading(false)
          this._snackbar.showSnackbar(data.msg, 'success')
        },
        err => {
          this.processRecordService.setLoading(false)
        }
      )
  }

  executeFromSingleChild(event) {
    this.executeEvent.emit(event)
  }

  updateItemEvent(data) {
    this.processRecordService.setLoading(true)
    const dialogRef = this.dialog.open(LoadingDialogBoxComponent, {
      disableClose: true,
      panelClass: 'loadingDialog',
      // height: '30%',
      data: {}
    })
    this.processRecordService
      .updateAction(this.form._id, this.recordId, this.processId, data)
      .subscribe(
        res => {
          this.data[data.index] = data.updatedData
          this.tableData = new MatTableDataSource<any>(this.data)
          this.processRecordService.setLoading(false)
          dialogRef.close()
          this._snackbar.showSnackbar(res.msg, 'success')
        },
        err => {
          this.processRecordService.setLoading(false)
          this.dialog.closeAll()
        }
      )
  }

  deleteItemEvent(event) {
    this.processRecordService.setLoading(true)
    const dialogRef = this.dialog.open(LoadingDialogBoxComponent, {
      disableClose: true,
      panelClass: 'loadingDialog',
      // height: '30%',
      data: {}
    })
    let childIds = event.selectedItems.map(elem => elem._id)
    this.processRecordService
      .deleteAction(this.form._id, this.recordId, this.processId, childIds)
      .subscribe(
        data => {
          this.processRecordService.setLoading(false)
          this.data = event.data
          this.data = this.data.filter((elem, index) => {
            if (event.selectedItems.indexOf(elem) < 0) {
              return elem
            }
          })
          this.tableData = new MatTableDataSource<any>(this.data)
          this.dialog.closeAll()
          this._snackbar.showSnackbar(data.msg, 'success')
        },
        err => {
          this.processRecordService.setLoading(false)
          this.dialog.closeAll()
        }
      )
  }

  onRequest(selectedAction) {
    this.requestEmitter.emit(selectedAction)
  }

  openDialog() {
    const dialogRef = this.dialog.open(LoadingDialogBoxComponent, {
      disableClose: true,
      panelClass: 'loadingDialog',
      // height: '30%',
      data: {}
    })
  }

  selectedFirEvent(value) {
    this.selectedFir.emit(value)
  }
}
