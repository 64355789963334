import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core'
import { FormGroup, FormBuilder } from '@angular/forms'
import { ModulesService } from 'src/app/modules/modules.service'
import { Observable } from 'rxjs'
import { debounceTime, mergeMap, map } from 'rxjs/operators'

@Component({
  selector: 'app-form-field',
  templateUrl: './form-field.component.html',
  styleUrls: ['./form-field.component.css']
})
export class FormFieldComponent implements OnInit {
  productByName: Observable<any>
  productById: Observable<any>
  selected
  previousSelected
  @Input() orderItems: Observable<any>
  @Input() orderId
  @Input() selectedItems
  @Output() selectedItemsEvent = new EventEmitter<any>()
  @Input() index
  @Input() formArrayLength

  @Input() itemGroup: FormGroup

  @Output() removeFormEmitter = new EventEmitter<any>()
  constructor(
    private modulesService: ModulesService,
    private fb: FormBuilder
  ) {}

  ngOnInit() {
    this.productByName = this.itemGroup
      .get('productNameControl')
      .valueChanges.pipe(
        debounceTime(500),
        mergeMap(value => {
          return this.modulesService
            .getProducts({ filters: [{ name: value }] })
            .pipe(
              map(data => {
                return data.map(product => {
                  return product
                })
              })
            )
        })
      )

    this.productById = this.itemGroup.get('productIdControl').valueChanges.pipe(
      debounceTime(500),
      mergeMap(value => {
        return this.modulesService
          .getProducts({ filters: [{ id: value }] })
          .pipe(
            map(data => {
              return data.map(product => {
                return product
              })
            })
          )
      })
    )
  }

  removeForm() {
    this.removeFormEmitter.emit({
      itemGroup: this.itemGroup.value.orderItemsControl,
      index: this.index
    })
    if (this.formArrayLength > 1) {
      this.onOptionSelect(this.itemGroup.value.orderItemsControl, 'delete')
    }
  }

  onOptionSelect(event, type = 'add') {
    if (type === 'add') {
      if (this.previousSelected) {
        const index = this.selectedItems.findIndex(
          selectedItem => this.previousSelected._id === selectedItem._id
        )
        this.selectedItems.splice(index, 1)
      }
      this.selectedItems.push(event.value)
      this.selectedItemsEvent.emit(this.selectedItems)
      this.previousSelected = event.value
    } else {
      if (event) {
        const index = this.selectedItems.findIndex(
          selectedItem => event._id === selectedItem._id
        )
        this.selectedItems.splice(index, 1)
      }
    }
  }

  isOptionDisabled(orderItem) {
    if (this.selectedItems.length) {
      const index = this.selectedItems.findIndex(
        selectedItem => orderItem._id === selectedItem._id
      )
      if (index >= 0) {
        return true
      } else {
        return false
      }
    } else {
      return false
    }
  }
}
