import { Pipe, PipeTransform } from '@angular/core'

@Pipe({ name: 'processStateFilter' })
export class ProcessStateFilter implements PipeTransform {
  transform(states: any[], searchStr: string) {
    if (!states) return []

    if (!searchStr) return states

    return states.map(state => {
      const matchingStatuses: any[] = state.statuses.filter(status => {
        return status.title.toLowerCase().includes(searchStr.toLowerCase())
      })

      // if (matchingStatuses.length == 0)
      // 	return;

      return Object.assign({}, state, {
        statuses: matchingStatuses
      })
    })
  }
}
