import { EscalationRoutingModule } from './escaltion/escaltion-routing.module'
import { ShipmentRoutingModule } from './shipment/shipment-routing.module'
import { NgModule } from '@angular/core'
import { RouterModule } from '@angular/router'
import { SalesRoutingModule } from './sales/sales-routing.module'
import { HrRoutingModule } from './hr/hr-routing.module'
import { FinanceRoutingModule } from './finance/finance-routing.module'
import { CollectionsRoutingModule } from './collections/collections-routing.module'
import { InventoryRoutingModule } from './inventory/inventory-routing.module'

export const appRoutes = []

@NgModule({
  imports: [
    SalesRoutingModule,
    HrRoutingModule,
    FinanceRoutingModule,
    ShipmentRoutingModule,
    EscalationRoutingModule,
    CollectionsRoutingModule,
    InventoryRoutingModule,
    RouterModule.forChild(appRoutes)
  ],

  exports: [RouterModule]
})
export class DepartmentRoutingModule {}
