import { ContactPersonModel } from './contact-person-model.model'

export class VendorModel {
  'Company Name': string
  contact: {
    name: string
    email: string
    phone: number
    mobile: number
  }
  address: {
    street1: string
    street2: string
    city: string
    state: string
    zip: number
    country: string
    phone: number
  }
  'Contact Persons': ContactPersonModel[]

  constructor(vendor) {
    this['Company Name'] = vendor['Company Name'] || ''
    this['contact'] = {
      name: vendor['contact'] ? vendor['contact'].name : '',
      email: vendor['contact'] ? vendor['contact'].name : '',
      phone: vendor['contact'] ? vendor['contact'].name : '',
      mobile: vendor['contact'] ? vendor['contact'].name : ''
    }
    this['address'] = {
      street1: vendor['address'] ? vendor['address'].street1 : '',
      street2: vendor['address'] ? vendor['address'].street2 : '',
      city: vendor['address'] ? vendor['address'].city : '',
      state: vendor['address'] ? vendor['address'].state : '',
      zip: vendor['address'] ? vendor['address'].zip : '',
      country: vendor['address'] ? vendor['address'].country : '',
      phone: vendor['address'] ? vendor['address'].phone : ''
    }
  }
}
