import { Component, OnInit } from '@angular/core'
import { FormControl, FormBuilder, Validators } from '@angular/forms'
import { ModulesService } from 'src/app/modules/modules.service'
import { Observable } from 'rxjs'

@Component({
  selector: 'app-mark-delivered-form',
  templateUrl: './mark-delivered-form.component.html',
  styleUrls: ['./mark-delivered-form.component.css']
})
export class MarkDeliveredFormComponent implements OnInit {
  constructor() {}

  ngOnInit() {}

  afterVerification(event) {}

  rengenerateOtp(event) {}
}
