import { Component, OnInit, Inject } from '@angular/core'
import { MAT_DIALOG_DATA } from '@angular/material'
import { Observable } from 'rxjs'
import mermaid from 'mermaid'

@Component({
  selector: 'app-show-dialog',
  templateUrl: './show-dialog.component.html',
  styleUrls: ['./show-dialog.component.css']
})
export class ShowDialogComponent implements OnInit {
  graphDefinition
  lt = 'Link text'
  statusLog = this.data.statusLog
  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {}

  ngOnInit() {
    if (this.statusLog) {
      mermaid.init()
      let initialDate = new Date(
        this.statusLog[0].executedAt
      ).toLocaleDateString()
      let initialTime = new Date(
        this.statusLog[0].executedAt
      ).toLocaleTimeString()
      let initialVal = `graph TD\nA(Start) --> |${this.statusLog[0].executedBy} ${initialDate} ${initialTime}| 0[${this.statusLog[0].status}]\n`
      this.graphDefinition = this.statusLog.reduce((acc, curr, idx, arr) => {
        if (idx == arr.length - 1) {
          return `${acc} ${idx}(${curr.status})`
        }
        let date = new Date(arr[idx + 1].executedAt).toLocaleDateString()
        let time = new Date(arr[idx + 1].executedAt).toLocaleTimeString()
        return `${acc} ${idx}(${curr.status}) --> |${
          arr[idx + 1].executedBy
        } ${date} ${time}| ${idx + 1}(${arr[idx + 1].status})\n`
      }, initialVal)
      mermaid.init()
      let output = document.getElementById('output')
      mermaid.render('graph', this.graphDefinition, function(svgCode) {
        output.innerHTML = svgCode
      })
    }
  }
}
