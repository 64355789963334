import { Component, OnInit } from '@angular/core'
import { FormGroup, FormControl, Validators } from '@angular/forms'
import { SESSION_STORAGE } from 'src/app/app.constant'
import { AuthService } from '../auth.service'
import { Title } from '@angular/platform-browser'
import { ModulesService } from '../../modules.service'

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
  profileForm
  changePassword: boolean = false
  workingDays
  variablePercentage
  advances
  bonus
  arrears

  constructor(
    private authService: AuthService,
    private titleService: Title,
    private moduleService: ModulesService
  ) {
    this.titleService.setTitle('Rmp-profile')
  }

  ngOnInit() {
    let userData = JSON.parse(localStorage.getItem(SESSION_STORAGE.DETAILS))
    let firstname = userData.firstName
    let lastname = userData.lastName
    let email = userData.email
    let username = userData.userName
    this.profileForm = new FormGroup({
      firstname: new FormControl(firstname, Validators.required),
      lastname: new FormControl(lastname, Validators.required),
      username: new FormControl(username, [
        Validators.required,
        Validators.required
      ]),
      email: new FormControl(email, [Validators.required, Validators.email])
    })
  }

  changePasswordEvent() {
    this.changePassword = true
  }

  closeComponentEvent() {
    this.changePassword = false
  }
}
