import { Component, OnInit, Input } from '@angular/core'
import { FormBuilder, FormGroup, FormControl } from '@angular/forms'

@Component({
  selector: 'process-form-primitive',
  templateUrl: './process-form-primitive.component.html',
  styleUrls: ['./process-form-primitive.component.css']
})
export class ProcessFormPrimitiveComponent implements OnInit {
  @Input() field
  @Input() displayType
  formFields: FormGroup

  constructor(private formBuilder: FormBuilder) {}

  ngOnInit() {
    if (this.displayType == 'first') {
      this.formFields = this.formBuilder.group({
        index: this.formBuilder.control(this.field.index),
        type: this.formBuilder.control(this.field.type),
        required: this.formBuilder.control(this.field.required),
        readonly: this.formBuilder.control(this.field.readonly)
      })
    } else if (this.displayType == 'second') {
      this.formFields = this.formBuilder.group({
        title: this.formBuilder.control(this.field.title),
        value: this.formBuilder.control(this.field.value)
      })
    }
  }

  getFormControl(formName: string): FormControl {
    return this.formFields.get(formName) as FormControl
  }
}
