export const SOCIAL_MEDIA = {
  FACEBOOK: '698608463952446',
  GOOGLE:
    '511703351806-njjjq8c8ratqgd8j4lsns62uqikv3iok.apps.googleusercontent.com'
}
export const STATUS_CODES = {
  SUCCESS: 200,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  METHOD_NOT_ALLOWED: 405,
  REQUEST_TIMEOUT: 408,
  INTERNAL_SERVER_ERROR: 500,
  BAD_GATEWAY: 502,
  SERVICE_UNAVAILABLE: 503,
  GATEWAY_TIMEOUT: 504
}
export const RESPONSE_MESSAGES = {
  SUCCESS: 'Success',
  NOT_LATEST_LOGIN: 'is_not_latest_login',
  INVALID_TOKEN: 'invalid_token',
  AUTH_HEADER_MISSING: 'authorization_header_missing'
}
export const UNKNOWN_ERROR_MESSAGE = 'Something went wrong'
export const SESSION_STORAGE = {
  TOKEN: 'token',
  ID: 'id',
  DETAILS: 'details',
  USER_ROLES: 'roles',
  BARCODES: 'barcodes',
  SELECTED_FIELDS: 'selectedFields',
  FILTERED_FIELD: 'filteredField',
  FILTER_OBJECT: 'filterObj',
  NAVIGATION: 'navigation',
  REQUEST_QUEUE: 'requestQueue',
  PROCESS_ID: 'processId',
  PAGE_INDEX: 'pageIndex',
  WAREHOUSE: 'warehouse',
  DEPARTMENT_OBJ: 'deptObj',
  PROCESS_OBJ: 'processObj'
}
export const MESSAGES = {
  MISSING_FIELDS: 'Please provide required fields',
  INCORRECT_OTP: 'Please provide the correct otp',
  INVALID_CREDENTIALS: 'Please provide valid credentials',
  FIELDS_UNIQUE: 'Phone number and Email Id should be unique',
  DATA_ADDED: 'Data added successfully',
  DATA_DELETED: 'Data deleted successfully',
  DATA_UPDATED: 'Data updated successfully',
  INVALID_EMAIL: 'Please provide correct email id',
  PO_CREATED: 'Purchase Order Created Successfully',
  PO_APPROVED: 'Purchase Order Approved Successfully',
  PO_REJECTED: 'Purchase Order Rejected Successfully',
  QC_APPROVED: 'Quality check data approved successfully',
  USER_CREATED: 'User Created Successfully',
  USERTYPE_CREATED: 'User type created successfully',
  WAREHOUSE_MISSING: 'Please select a warehouse',
  ORDER_TYPE_MISSING: 'Please select an order type',
  ALREADY_EXISTS: 'city and state already exists',
  FILE_CONTENT_MISSING: 'File content is missing',
  BARCODES_ASSIGNED: 'Barcodes are assigned successfully',
  BARCODE_NOT_SELECTED: 'No barcode details found',
  ITEM_STAGE_NOT_SELECTED: 'Please select an item stage',
  ITEM_STATUS_NOT_SELECTED: 'Please select an item status',
  INVENTORY_UPDATED: 'Inventory status updated successfully'
}
export const ROUTE_LIST = {
  HOME: '/users'
}

export const NUMBER_ONE = 1

export const NUMBER_ZERO = 0

export const EDIT_ROLES = 'Edit Roles'

export const MANAGE_USERS = 'User Management'

export const SPINNER_CONFIGS = {
  BDCOLOR: '#4e4e4e',
  SIZE: 'default',
  COLOR: '#a23f9d',
  TYPE: 'square-jelly-box',
  FULLSCREEN: true,
  SPINNER_TEXT: 'Loading...',
  SPINNER_TEXT_COLOR: '#ffffff'
}

export const ONLY_ALPHANUMERIC_PATTERN = /^[0-9]*[a-zA-Z]+[a-zA-Z0-9]*$/

export const INVALID_FORMAT_USER_TYPE_TEXT =
  'Only a single alphanumerical word is allowed as the user type'

export const SAFETY_STOCK_PERCENTAGE = 20

export const ITEM_STATUS_INFORMATION = {
  1: 'Ready for Delivery',
  2: 'Needs Refurbishment',
  3: 'Returned',
  4: 'Scrap'
}

export const ITEM_STAGE_INFORMATION = {
  1: 'Warehouse entry new item',
  2: 'Warehouse entry from customer',
  3: 'Warehouse exit for delivery',
  4: 'Item delivered',
  5: 'Item picked up',
  6: 'Warehouse exit for refurbishment',
  7: 'Warehouse entry from refurbishment'
}

export const INVENTORY_TO_CREATE_PO_ERROR_MESSAGE = {
  NO_CITY:
    'Cannot create purchase order as no city information found for this product',
  NO_WAREHOUSE:
    'Cannot create purchase order as no warehouse information found for this product',
  NO_PRODUCT:
    'Cannot create purchase order as no product related information found for this product',
  NO_PRODUCT_VARIANT:
    'Cannot create purchase order as no product variant information found for this product',
  NO_ORDER_TYPE:
    'Cannot create purchase order as no order type is selected for this product'
}

export const CUSTOMER_PORTAL = 'https://www.rentickle.com/'

/* Array of persons who have right to see report on the basis of date range */
export const EMAIL_DETAILS = [
  'amit.sodhi@rentickle.com',
  'deepak.miglani@rentickle.com',
  'mohit.chaudhary@rentickle.com',
  'atique.firoz@rentickle.com',
  'adarsh.bats@rentickle.com'
]
export const WIDGET_DETAILS = [
  'dateWiseDeliveredReport',
  'dateWisePickedUpReport'
]
