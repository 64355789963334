import { Component, OnInit, Input } from '@angular/core'
import { BarcodeFormat } from '@zxing/library'
import { FormBuilder, FormGroup } from '@angular/forms'

@Component({
  selector: 'mat-option-form',
  templateUrl: './mat-option-form.component.html',
  styleUrls: ['./mat-option-form.component.css']
})
export class MatOptionFormComponent implements OnInit {
  @Input() formInstance: FormGroup
  availableDevices: MediaDeviceInfo[]
  hasDevices: boolean
  qrResultString: string
  openScanner: boolean = false
  currentBarcodeIndex
  formatsEnabled = [
    BarcodeFormat.CODE_128,
    BarcodeFormat.DATA_MATRIX,
    BarcodeFormat.EAN_13,
    BarcodeFormat.QR_CODE
  ]
  constructor(private fb: FormBuilder) {}

  ngOnInit() {}

  clearResult(): void {
    this.qrResultString = null
  }

  onCamerasFound(devices: MediaDeviceInfo[]): void {
    this.availableDevices = devices
    this.hasDevices = Boolean(devices && devices.length)
  }

  onCodeResult(resultString: string) {
    this.qrResultString = resultString
    this.formInstance.get('barcode').patchValue(resultString)
    this.openScanner = false
  }

  openScannerEvent(index?) {
    this.openScanner = false
    this.openScanner = true
    if (index) this.currentBarcodeIndex = index
  }
}
