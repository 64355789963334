import { Component, OnInit, Inject, EventEmitter } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { ProcessRecordsService } from '../process-records.service'

@Component({
  selector: 'app-field-dialog-box',
  templateUrl: './field-dialog-box.component.html',
  styleUrls: ['./field-dialog-box.component.css']
})
export class FieldDialogBoxComponent implements OnInit {
  displayedColumns
  fieldData = new EventEmitter()
  selectedOptions = this.data.defaultFields
  constructor(
    public dialogRef: MatDialogRef<FieldDialogBoxComponent>,
    private processRecordService: ProcessRecordsService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {}
  handleFieldSelect(event) {
    //	Changing the mat table when fields are manually changed
    this.displayedColumns = [
      'checked',
      'view',
      'createdAt',
      'status',
      ...event.source._value
    ]
    this.processRecordService.handleFieldSelect(event, this.data.processId)
    this.fieldData.emit(this.displayedColumns)
  }
}
