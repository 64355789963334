import { Component, OnInit } from '@angular/core'
import { WarehouseDataService } from '../warehouse-data.service'
import { SESSION_STORAGE } from 'src/app/app.constant'

@Component({
  selector: 'warehouse',
  templateUrl: './warehouse.component.html',
  styleUrls: ['./warehouse.component.css']
})
export class WarehouseComponent implements OnInit {
  warehouses = [
    { title: 'gurgaon', value: 'gurgaon' },
    { title: 'delhi', value: 'delhi' },
    { title: 'noida', value: 'noida' },
    { title: 'hyderabad', value: 'hyderabad' },
    { title: 'bengaluru', value: 'bengaluru' },
    { title: 'pune', value: 'pune' },
    { title: 'mumbai', value: 'mumbai' }
  ]
  selected = []

  constructor(private warehouseService: WarehouseDataService) {}

  ngOnInit() {
    this.selected = JSON.parse(localStorage.getItem(SESSION_STORAGE.WAREHOUSE))
  }

  selectWarehouse(event) {
    this.warehouseService.selectWarehouse(event.value)
  }
}
