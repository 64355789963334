import { Component, OnInit } from '@angular/core'
import { ModulesService } from '../modules.service'
import { Router } from '@angular/router'
import { environment } from 'src/environments/environment'

@Component({
  selector: 'app-hr',
  templateUrl: './hr.component.html',
  styleUrls: ['./hr.component.css']
})
export class HrComponent implements OnInit {
  constructor(private moduleService: ModulesService, private router: Router) {}
  departmentId = environment.config.hr.id

  ngOnInit() {}
}
