import { Component, OnInit } from '@angular/core'
import { NavToggleService } from '../../services/navtoggle.service'
import { MatCard } from '@angular/material/card'
import { AuthService } from 'src/app/modules/auth/auth.service'
import { SESSION_STORAGE } from '../../../app.constant'
import { FirService } from '../fir/fir.service'
import { MatDialog } from '@angular/material'
import { PushToRmpDialogComponent } from './push-to-rmp-dialog/push-to-rmp-dialog.component'
import { ModulesService } from 'src/app/modules/modules.service'
import * as $ from 'jquery'

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  name
  email
  processName
  show = false
  navigation: boolean = true
  userdata
  openFirs
  constructor(
    private navTogService: NavToggleService,
    private AuthService: AuthService,
    private firService: FirService,
    private dialog: MatDialog,
    private moduleService: ModulesService
  ) {}

  ngOnInit() {
    this.userdata = JSON.parse(localStorage.getItem(SESSION_STORAGE.DETAILS))
    this.name = `${this.userdata.firstName} ${this.userdata.lastName}`
    this.email = `${this.userdata.email}`

    this.openFirs = this.firService.getOpenFirCounts()

    /************ DO NOT KNOW WHAT THIS IS FOR, COMMENTING ON 21st OCT 2020 ************/
    $(window).click(evt => {
      if (this.show) {
        if (evt.target.className)
          if (!evt.target.className.includes('trigger-profile'))
            this.show = false
      }
    })
  }

  toggleNavigate() {
    this.navigation = !this.navigation
    // localStorage.setItem(SESSION_STORAGE.NAVIGATION, JSON.stringify(this.navigation));
    this.navTogService.toggleDrawer()
  }

  triggerProfileCard() {
    this.show = !this.show
  }

  logout() {
    this.moduleService.breadcrumb.next(null)
    this.AuthService.resetLoginState()
  }

  pushToRmp() {
    let dialog = this.dialog.open(PushToRmpDialogComponent, {
      width: '300px',
      height: '30%'
    })
    dialog.componentInstance.close.subscribe(data => {
      this.dialog.closeAll()
    })
  }
}
