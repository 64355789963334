import { Component, OnInit, Input } from '@angular/core'
import { FormBuilder } from '@angular/forms'

@Component({
  selector: 'record-child-create',
  templateUrl: './record-child-create.component.html',
  styleUrls: ['./record-child-create.component.css']
})
export class RecordChildCreateComponent implements OnInit {
  @Input() childViewForm
  @Input() form
  controlsObject
  constructor(private fb: FormBuilder) {}

  ngOnInit() {
    this.controlsObject = Object.keys(this.childViewForm.controls).map(
      control => {
        return {
          key: control,
          value: this.childViewForm.controls[control]
        }
      }
    )
  }
}
