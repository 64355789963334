import { FormControl, FormGroup, FormArray, FormBuilder } from '@angular/forms'
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core'

@Component({
  selector: 'list-field',
  templateUrl: './list-field.component.html',
  styleUrls: ['./list-field.component.css']
})
export class ListFieldComponent implements OnInit {
  @Input() fieldInstance: FormArray
  @Input() label: string
  @Input() field: any
  @Input() create?: boolean = false
  @Input('fieldIndex') index: number
  @Input() isRequired: boolean = false
  @Input() inputValidations: any[] = []
  @Input() readOnly: boolean = false
  @Input() path: string
  @Input() isAdmin: boolean = false

  constructor(private _formBuilder: FormBuilder) {}

  ngOnInit() {}

  onaddListItem() {
    switch (this.field.type) {
      case 'string': {
        this.fieldInstance.push(this._formBuilder.control(''))
        break
      }
      case 'number': {
        this.fieldInstance.push(this._formBuilder.control(''))
        break
      }
      case 'group': {
        const newListGroup = this._formBuilder.group({})
        this.field.fields.map(internalField => {
          const controlName = internalField.title
          newListGroup.addControl(controlName, new FormControl(''))
        })
        this.fieldInstance.push(newListGroup)
        break
      }
      case 'file': {
        const fileGroup = this._formBuilder.group({
          title: this._formBuilder.control(''),
          fileUrl: this._formBuilder.control(''),
          password: this._formBuilder.control('')
        })
        this.fieldInstance.push(fileGroup)
        break
      }
    }
  }

  onDeleteListItem(index: number) {
    this.fieldInstance.removeAt(index)
  }
}
