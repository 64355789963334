import {
  Component,
  OnInit,
  Input,
  ChangeDetectorRef,
  OnDestroy,
  EventEmitter,
  Output
} from '@angular/core'
import { FormControl, FormGroup, FormBuilder } from '@angular/forms'
import { MatDialog } from '@angular/material'
import { ActivatedRoute, Router } from '@angular/router'
import { map, tap, catchError, last } from 'rxjs/operators'
import {
  HttpRequest,
  HttpClient,
  HttpEventType,
  HttpEvent
} from '@angular/common/http'
import { Observable, Subject } from 'rxjs'
import { RecordViewService } from '../../record-view/record-view.service'
import { FileViewDialogComponent } from '../../file-view-dialog/file-view-dialog.component'

@Component({
  selector: 'file-field',
  templateUrl: './file-field.component.html',
  styleUrls: ['./file-field.component.css']
})
export class FileFieldComponent implements OnInit, OnDestroy {
  @Input() fieldInstance: FormControl | FormGroup
  @Input() label: string
  @Input('fieldIndex') index: number
  @Input() field: any
  @Input() create: boolean = false
  @Input() isRequired: boolean = false
  @Input() inputValidations: any[] = []
  @Input() readOnly: boolean = false
  @Input() path: string
  @Input() isAdmin: boolean = false
  @Output() uploaded: EventEmitter<Boolean> = new EventEmitter<Boolean>()

  url
  fileUrl: string
  fileName: string = 'Select file'
  file: any
  public URL: string
  processId: string
  recordId: string
  baseUrl: string
  token: string
  fileForm: FormGroup
  uploadResponse: any = { status: '', message: 0, filePath: '' }
  title: 'Upload a File'
  isLoading: boolean = false
  stopLoad: boolean = false
  progress: Number = 0

  constructor(
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private recordService: RecordViewService,
    private cd: ChangeDetectorRef,
    private httpClient: HttpClient,
    private router: Router
  ) {}

  ngOnInit() {
    this.fileForm = this.formBuilder.group({
      fileName: ''
    })
    this.processId = this.router.url.split('/')[4]
    this.recordId = this.router.url.split('/')[6]
    if (!this.create) this.fileUrl = this.fieldInstance.get('fileUrl').value
  }

  onFileChange(event) {
    if (event.target.files.length > 0) {
      this.file = event.target.files[0]
      const fileName = event.target.files[0].name
      this.fileName = fileName
      this.fileForm.get('fileName').setValue(fileName)
      this.url = event.target.files[0].type.substr(
        event.target.files[0].type.indexOf('/') + 1
      )
      this.path = `/${this.field.title}`
    }
  }

  onSubmit() {
    this.recordService.disableEdit()
    this.isLoading = true
    this.stopLoad = true
    const formData = new FormData()
    formData.append('fileName', this.fileForm.get('fileName').value)
    formData.append('path', this.path)
    formData.append('recordId', this.recordId)

    this.recordService.upload(formData, this.processId).subscribe(
      res => {
        if (res['data']) {
          const paramsIndex = res['data'].indexOf('?')
          const url: string = res['data'].substring(0, paramsIndex)
          const uploadRequest = new HttpRequest('PUT', res['data'], this.file, {
            reportProgress: true
          })
          this.httpClient
            .request(uploadRequest)
            .pipe(
              map(event => {
                const percent = this.getEventMessage(event)
                this.progress = percent
                return percent
              })
            )
            .subscribe(response => {
              if (response == 101) {
                this.fileUrl = url
                const fileUrlControl: FormControl = this.fieldInstance.get(
                  'fileUrl'
                ) as FormControl
                const titleControl: FormControl = this.fieldInstance.get(
                  'title'
                ) as FormControl
                fileUrlControl.setValue(url)
                titleControl.setValue(url.split('/')[6])

                this.create = false
                this.stopLoad = false
                this.isLoading = false
                this.recordService.enableEdit()
                this.cd.markForCheck()
                this.cd.detectChanges()

                this.recordService.showNotification(
                  'success',
                  'Upload Success. Please Save Record!'
                )
              } else if (response == -1) {
                this.recordService.enableEdit()
                this.recordService.showNotification(
                  'error',
                  'S3 upload not complete!'
                )
              }
            })
        }
      },
      err => {
        this.stopLoad = false
        this.isLoading = false
        this.recordService.enableEdit()
        this.cd.markForCheck()
        this.cd.detectChanges()
        this.recordService.refreshRecord()
        this.recordService.showNotification(
          'error',
          'Server Error in file upload!.'
        )
      }
    )
  }

  getEventMessage(event: HttpEvent<any>) {
    switch (event.type) {
      case HttpEventType.Sent:
        return 0

      case HttpEventType.UploadProgress:
        // Compute and show the % done:
        const percentDone = Math.round((100 * event.loaded) / event.total)
        return percentDone

      case HttpEventType.Response:
        return 101

      default:
        return -1
    }
  }

  viewDocument() {
    const fileName: string = this.fieldInstance.get('title').value
    const password: string = this.fieldInstance.get('password').value
    const title: string = this.field.title

    const formData = new FormData()

    const filePath = this.fieldInstance.get('fileUrl').value
    const filepath = filePath.replace('https://', '')
    const fileUrl = filepath.substring(filepath.indexOf('/') + 1)

    formData.append('filePath', fileUrl)

    this.recordService.genDocSignedUrl(formData, this.processId).subscribe(
      res => {
        if (res['data']) {
          this.dialog.open(FileViewDialogComponent, {
            data: {
              fileName,
              fileUrl: res.data.signedViewUrl,
              title,
              password
            }
          })
        }
      },
      err => {
        this.recordService.showNotification(
          'error',
          'Server Error in view document!.'
        )
      }
    )
  }

  downloadDocument() {
    const formData = new FormData()

    const filePath = this.fieldInstance.get('fileUrl').value
    const filepath = filePath.replace('https://', '')
    const fileUrl = filepath.substring(filepath.indexOf('/') + 1)

    formData.append('filePath', fileUrl)

    this.recordService.genDocSignedUrl(formData, this.processId).subscribe(
      res => {
        if (res['data']) {
          this.fileUrl = res.data.signedViewUrl
          window.open(res.data.signedViewUrl, '_blank')
        }
      },
      err => {
        this.recordService.showNotification(
          'error',
          'Server Error in view document!.'
        )
      }
    )
  }

  deleteDocument() {
    console.log('hh', this.fileUrl)
    this.fieldInstance.reset({
      fileUrl: '',
      title: '',
      password: ''
    })
  }

  getFileTitle() {
    return this.fieldInstance.get('title').value
  }

  ngOnDestroy() {
    this.cd.detach()
  }
}
