import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core'
import * as _moment from 'moment'
import { ContactPersonModel } from 'src/app/models/contact-person-model.model'
import * as _ from 'lodash'
import { FormArray, FormControl, FormGroup } from '@angular/forms'
const moment = _moment

@Component({
  selector: 'vendor-view',
  templateUrl: './vendor-view.component.html',
  styleUrls: ['./vendor-view.component.css']
})
export class VendorViewComponent implements OnInit {
  edit: boolean = false
  @Input() selectedPos
  @Input() dataSource
  @Output() removeFormEvent = new EventEmitter<any>()
  customForm
  @Input() emptyFormSchema
  @Input() type
  schema
  comments

  constructor() {}

  ngOnInit() {
    this.schema = this.emptyFormSchema
    this.initialize(this.schema)
  }

  closeEdit() {
    this.edit = false
  }

  editEvent() {
    this.edit = true
  }

  editVendor(details) {
    let createdAt = moment(new Date()).format('YYYY-MM-DD')
    for (let key in this.dataSource) {
      if (details[key]) {
        this.dataSource[key] = details[key]
      }
    }
    this.edit = false
    this.initialize(this.schema)
  }

  close() {
    this.removeFormEvent.emit()
  }

  initialize(schema) {
    let cp = new ContactPersonModel('')
    schema['Contact Persons'] = []
    if (this.dataSource) {
      this.dataSource['Contact Persons'].forEach(() => {
        schema['Contact Persons'].push(cp)
      })
    } else {
      schema['Contact Persons'].push(cp)
    }
    let grp = {}
    let ar = []
    for (let field in schema) {
      if (_.isArray(schema[field])) {
        var sub = {}
        let mainAr = []
        let formAr = new FormArray([])
        schema[field].forEach((elem, index) => {
          var sub = {}
          let a = []
          for (let key in schema[field][index]) {
            if (this.dataSource) {
              sub[key] = new FormControl(this.dataSource[field][index][key])
            } else {
              sub[key] = new FormControl('')
            }
          }
          for (let key in schema[field][0]) {
            a.push([key, ''])
          }
          formAr.push(new FormGroup(sub))
          mainAr.push(a)
        })
        grp[field] = formAr
        ar.push([field, mainAr])
      } else if (
        _.isObject(schema[field]) &&
        !schema.hasOwnProperty('options')
      ) {
        let sub = {}
        for (let key in schema[field]) {
          if (this.dataSource) {
            sub[key] = new FormControl(this.dataSource[field][key])
          } else {
            sub[key] = new FormControl('')
          }
        }
        grp[field] = new FormGroup(sub)
        ar.push([field, schema[field]])
      } else {
        if (this.type == 'Add') {
          grp[field] = new FormControl('')
        } else if (this.type == 'Edit') {
          grp[field] = new FormControl(this.dataSource[field])
        }
        ar.push([field, schema[field]])
      }
    }

    this.emptyFormSchema = ar
    this.customForm = new FormGroup(grp)
  }
}
