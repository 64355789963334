import { Component, OnInit } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { ActivatedRoute } from '@angular/router'
import { MatDialog } from '@angular/material'
import { WidgetCreateDialogComponent } from './widget-create-dialog/widget-create-dialog.component'
import { HttpService } from 'src/app/core/services/http.service'
import { environment } from 'src/environments/environment'
import { Observable } from 'rxjs'
import { ShowWidgetItemComponent } from './show-widget-item/show-widget-item.component'
import { FormBuilder } from '@angular/forms'

@Component({
  selector: 'app-process-widget',
  templateUrl: './process-widget.component.html',
  styleUrls: ['./process-widget.component.css']
})
export class ProcessWidgetComponent implements OnInit {
  processId: string
  baseUrl
  widgetData: Observable<any>
  filteredColumns = ['view', 'index', 'displayTitle', 'description', 'type']
  existingColumns = ['index', 'displayTitle', 'description', 'type']

  constructor(
    private httpClient: HttpClient,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private httpService: HttpService,
    private fb: FormBuilder
  ) {}

  ngOnInit() {
    this.baseUrl = environment.baseURL
    this.route.params.subscribe(params => {
      if (params) {
        this.processId = params['processId']
        this.widgetData = this.httpService.post(
          `${this.baseUrl}/process/${this.processId}/widgets`
        )
      }
    })
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(WidgetCreateDialogComponent, {
      width: '70%',
      height: '70%',
      data: {
        processId: this.processId
      },
      disableClose: true
    })

    // dialogRef.componentInstance.addForm.subscribe((formData: any[]) => {
    // 	this.formLength++;
    // 	this.forms = formData;
    // });
  }

  viewElement(element) {
    this.dialog.open(ShowWidgetItemComponent, {
      width: '70%',
      height: '70%',
      data: {
        formData: element
      }
    })
  }
}
