import { Component, OnInit } from '@angular/core'
import { Subject } from 'rxjs'
import { FormGroup, FormBuilder, FormArray, FormControl } from '@angular/forms'
import { MatDialog } from '@angular/material'
import { VendorService } from '../vendor/vendor-service.service'
import { SESSION_STORAGE } from 'src/app/app.constant'
import { PurchaseModel } from '../../../models/purchase-model.model'
import { LineItemsModel } from 'src/app/models/line-items-model.model'
import * as _moment from 'moment'
import * as _ from 'lodash'
const moment = _moment

@Component({
  selector: 'app-purchase-order',
  templateUrl: './purchase-order.component.html',
  styleUrls: ['./purchase-order.component.css']
})
export class PurchaseOrderComponent implements OnInit {
  customForm
  addPo = false
  user = localStorage.getItem(SESSION_STORAGE.DETAILS)
  emptyFormSchema = new PurchaseModel('')
  dataSource: any[] = [
    {
      position: 0,
      createdAt: '25-05-2020',
      'Purchase Order#': 'P0-001',
      'Reference #': 123,
      'Vendor Name': 'Tanpreet Kaur',
      Status: 'opened',
      Received: '',
      Billed: '25-05-2020',
      Amount: 1000,
      'Expected Delivery Date': '15-06-2020',
      'Line Items': [
        {
          'Item Name': 'Washing Machine',
          Account: 'sjkh',
          Quantity: 20,
          Rate: 8000,
          Amount: 90000
        },
        {
          'Item Name': 'Washing Machine',
          Account: 'sjkh',
          Quantity: 20,
          Rate: 8000,
          Amount: 90000
        }
      ],
      comments: [
        {
          content: 'P0-001 comment 1',
          createdBy: 'Tanpreet Kaur'
        },
        {
          content: 'P0-001 comment 2',
          createdBy: 'Benedict'
        }
      ],
      billSource: [
        {
          'Bill#': 123,
          Date: '23/09/2020',
          Status: 'Paid',
          'Due Date': '24/09/2020',
          Amount: 4000,
          'Balance Due': 0
        }
      ],
      receiveSource: [
        {
          'Purchase Receive#': 10,
          'Received On': '24/09/2020',
          'Bill#': 123
        }
      ]
    },
    {
      position: 1,
      createdAt: '28-05-2020',
      'Purchase Order#': 'P0-002',
      'Reference #': 125,
      'Vendor Name': 'John Smith',
      Status: 'opened',
      Received: '',
      Billed: '28-05-2020',
      Amount: 1500,
      'Expected Delivery Date': '20-06-2020',
      'Line Items': [
        {
          'Item Name': 'Washing Machine',
          Account: 'sjkh',
          Quantity: 20,
          Rate: 8000,
          Amount: 90000
        }
      ],
      comments: [
        {
          content: 'P0-002 comment 1',
          createdBy: 'Tanpreet Kaur'
        },
        {
          content: 'P0-002 comment 2',
          createdBy: 'Benedict'
        }
      ],
      billSource: [
        {
          'Bill#': 233,
          Date: '24/09/2020',
          Status: 'Paid',
          'Due Date': '24/09/2020',
          Amount: 8000,
          'Balance Due': 0
        }
      ],
      receiveSource: [
        {
          'Purchase Receive#': 50,
          'Received On': '24/09/2020',
          'Bill#': 123
        }
      ]
    }
  ]
  lineItems: [
    {
      vendor: 'bill'
      items: [
        'Breto Centre Table',
        'Bevel Centre Table',
        'TT Table',
        'Executive Study Chair Red',
        'Livpure PEP (RO Water Purifier)'
      ]
    },
    {
      vendor: 'Max'
      items: [
        'Bevel Centre Table',
        'TT Table',
        'Executive Study Chair Red',
        'Livpure PEP (RO Water Purifier)'
      ]
    }
  ]
  displayedColumns = [
    'createdAt',
    'Purchase Order#',
    'Reference #',
    'Vendor Name',
    'Status',
    'Received',
    'Billed',
    'Amount',
    'Expected Delivery Date'
  ]
  listColspan = 399
  viewColspan = 1
  listHeader = 'All Purchase Orders'
  selectedPos = undefined
  professionalDetails
  detailsForm: FormGroup
  constructor(
    private fb: FormBuilder,
    private dialog: MatDialog,
    private vendorService: VendorService
  ) {}

  ngOnInit() {
    this.emptyFormSchema['Vendor Name'] = {
      value: '',
      options: ['Tanpreet Kaur', 'John Smith']
    }
    this.emptyFormSchema['Payment Terms'].options = [
      'Net 15',
      'Net 30',
      'Net 45',
      'Net 60',
      'Due end of the month',
      'Due end of next month',
      'Due on receipt'
    ]
  }

  vendorClicked(index) {
    this.displayedColumns = ['Vendor Name']
    this.selectedPos = index
  }

  add() {
    this.addPo = true
  }

  close() {
    this.addPo = false
    this.selectedPos = undefined
    this.displayedColumns = [
      'createdAt',
      'Purchase Order#',
      'Reference #',
      'Vendor Name',
      'Status',
      'Received',
      'Billed',
      'Amount',
      'Expected Delivery Date'
    ]
  }

  addPurchaseOrder(details) {
    let createdAt = moment(new Date()).format('YYYY-MM-DD')
    this.dataSource = [
      ...this.dataSource,
      {
        position: this.dataSource.length,
        createdAt: createdAt,
        ...details
      }
    ]
    this.addPo = false
  }

  closeEdit() {}
}
