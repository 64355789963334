import { Component, OnInit, OnDestroy, Input } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { environment } from '../../../../environments/environment'
import { HttpService } from 'src/app/core/services/http.service'
import { AuthService } from 'src/app/modules/auth/auth.service'
import { WarehouseDataService } from '../warehouse-data.service'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { ModulesService } from '../../modules.service'
@Component({
  selector: 'records',
  templateUrl: './process-records.component.html',
  styleUrls: ['./process-records.component.css']
})
export class ProcessRecordsComponent implements OnInit, OnDestroy {
  deptId
  loads: boolean = true
  processId
  apiUrl = environment.baseURL
  constructor(private router: Router, private moduleService: ModulesService) {}

  ngOnInit() {
    //Code for pushing records in the breadcrumb
    this.deptId = this.router.url.split('/')[2]
    this.processId = this.router.url.split('/')[4]
  }
  ngOnDestroy() {
    this.loads = false
  }
}
