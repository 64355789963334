import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core'
import { FormControl, FormArray } from '@angular/forms'

@Component({
  selector: 'app-field',
  templateUrl: './field.component.html',
  styleUrls: ['./field.component.css']
})
export class FieldComponent implements OnInit {
  @Input() field: any
  @Input() formInstance: FormControl
  @Input() fieldInstance: FormControl | FormArray
  @Input() readOnly: boolean = false
  @Input() path: string
  @Input() isAdmin: boolean = false
  @Input() create: boolean = false
  @Output() removeListItem: EventEmitter<any> = new EventEmitter<any>()

  constructor() {}

  ngOnInit() {}
}
