import { SESSION_STORAGE } from 'src/app/app.constant'
import { Component, OnInit, ViewEncapsulation, ViewChild } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { MatDialog } from '@angular/material'
import { ProcessFormDialogComponent } from './process-form-dialog/process-form-dialog.component'
import { environment } from 'src/environments/environment'
import { HttpService } from 'src/app/core/services/http.service'
import { Subject } from 'rxjs'

export interface form {
  formData: {
    title: string
    index: number
  }
  fields: []
}

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-process-form',
  templateUrl: './process-form.component.html',
  styleUrls: ['./process-form.component.css']
})
export class ProcessFormComponent implements OnInit {
  public processForms: any[] = []
  public fieldtypes: any[] = []
  private processId: string = null
  private baseUrl: string = null
  public formLength: number
  private token: string
  fieldTypesArray = []

  public slides: any[] = []

  constructor(
    private httpService: HttpService,
    private route: ActivatedRoute,
    public dialog: MatDialog
  ) {
    this.baseUrl = environment.baseURL
    this.token = localStorage.getItem(SESSION_STORAGE.TOKEN)
  }

  public ngOnInit() {
    this.route.params.subscribe(params => {
      if (params) this.processId = params['processId']
    })

    this.httpService
      .post(`${this.baseUrl}/process/${this.processId}/forms`, {})
      .subscribe((res: any[]) => {
        this.processForms = res
        this.formLength = this.processForms.length
      })

    this.httpService
      .post(`${this.baseUrl}/process/${this.processId}/fieldtypes`, {})
      .subscribe((res: any[]) => {
        this.fieldtypes = res
        this.fieldTypesArray = this.fieldtypes.map(
          fieldTypeObj => fieldTypeObj.value
        )
      })
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(ProcessFormDialogComponent, {
      height: '70%',
      data: {
        processId: this.processId,
        fieldtypes: this.fieldtypes,
        formLength: this.formLength
      },
      disableClose: true
    })

    dialogRef.componentInstance.addForm.subscribe((formData: any[]) => {
      this.formLength++
      this.processForms = formData
    })
  }

  saveForm() {}
}
