import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core'
import { Observable } from 'rxjs'
import { HttpService } from 'src/app/core/services/http.service'
import { environment } from 'src/environments/environment'
import { map, debounceTime } from 'rxjs/operators'

@Component({
  selector: 'delivery-helper',
  templateUrl: './delivery-helper.component.html',
  styleUrls: ['./delivery-helper.component.css']
})
export class DeliveryHelperComponent implements OnInit {
  baseUrl = environment.baseURL
  helperOptions: Observable<any>
  filteredHelpers: Observable<any>
  helperNumber = ''
  @Input() control
  @Output() removeEvent = new EventEmitter<any>()
  constructor(private httpService: HttpService) {}

  ngOnInit() {
    this.filteredHelpers = this.httpService
      .post(
        `${this.baseUrl}/records/${environment.config.shipment.helper}/list`
      )
      .pipe(
        map(data => {
          return data.map(elem => {
            return {
              name: elem.details[0][0].name,
              helperNumber: elem.details[0][0].phone
            }
          })
        })
      )

    this.control
      .get('helperName')
      .valueChanges.pipe(
        debounceTime(500),
        map(value => {
          if (value) {
            let body = {
              filters: [{ name: value }]
            }
            this.filteredHelpers = this.httpService
              .post(
                `${this.baseUrl}/records/${environment.config.shipment.helper}/list`,
                body
              )
              .pipe(
                map(data => {
                  return data.map(elem => {
                    return {
                      name: elem.details[0][0].name,
                      helperNumber: elem.details[0][0].phone
                    }
                  })
                })
              )
          } else {
            this.filteredHelpers = this.httpService
              .post(
                `${this.baseUrl}/records/${environment.config.shipment.helper}/list`
              )
              .pipe(
                map(data => {
                  return data.map(elem => {
                    return {
                      name: elem.details[0][0].name,
                      helperNumber: elem.details[0][0].phone
                    }
                  })
                })
              )
          }
        })
      )
      .subscribe()
  }

  removeHelper() {
    this.removeEvent.emit()
  }

  displayFn(option): string {
    return option && option.name ? option.name : ''
  }

  helperSelected(value) {
    this.control.get('helperNumber').patchValue(value.helperNumber)
  }
}
