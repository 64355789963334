import { CameraService } from './../../../shared/components/camera/camera.service'
import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  HostListener
} from '@angular/core'
import { FormControl, FormBuilder, Validators } from '@angular/forms'
import { Observable, Subject, BehaviorSubject } from 'rxjs'
import { ModulesService } from 'src/app/modules/modules.service'
import { HttpService } from '../../services/http.service'
import { SnackbarService } from 'src/app/snackbar.service'
import { LoadingDialogBoxComponent } from 'src/app/modules/process/process-records/loading-dialog-box-component/loading-dialog-box-component.component'
import { MatDialog } from '@angular/material'
import { ProcessRecordsService } from 'src/app/modules/process/process-records/process-records.service'
import { BarcodeScannerComponent } from 'src/app/modules/sales/out-for-delivery/barcode-scanner/barcode-scanner.component'
import { HttpRequest, HttpClient } from '@angular/common/http'
import { environment } from 'src/environments/environment'

@Component({
  selector: 'app-standalone-form',
  templateUrl: './standalone-form.component.html',
  styleUrls: ['./standalone-form.component.css']
})
export class StandaloneFormComponent implements OnInit {
  typeBtn
  baseUrl = environment.baseURL
  pickupOtp
  deliveryOtp
  orderIdControl: FormControl
  pickupFormControl: FormControl
  showOrderDetails: boolean = false
  orderItems: Observable<any>
  open: boolean = false
  deliveryDetails: Array<any> = []
  selectedOptions
  pickupItems: Array<String> = []
  packageItems
  treeData: Array<Object> = []
  treeDataSub = new BehaviorSubject<any>(null)
  treeData$ = this.treeDataSub.asObservable()
  @Input() picked
  @Input() header
  @Output() otpVerified: EventEmitter<any> = new EventEmitter<any>()
  @Output() regenerate: EventEmitter<any> = new EventEmitter<any>()
  verifyingOtp: string
  showOtpComponent = true
  getData = new Subject()
  getData$ = this.getData.asObservable()
  @ViewChild('ngOtpInput', { static: true }) ngOtpInput: any
  recordId
  newEmail: FormControl
  markDelivery: boolean
  markPickup: boolean
  config = {
    allowNumbersOnly: false,
    length: 5,
    isPasswordInput: false,
    disableAutoFocus: false,
    placeholder: '',
    inputStyles: {
      width: '50px',
      height: '50px'
    }
  }

  /** camera variables */
  test: string[]
  devices: any[]
  orientation: any
  picture: string
  uploaded: boolean = false
  url

  //acquaintanceInfo
  acquaintanceForm
  idUploaded

  constructor(
    private fb: FormBuilder,
    private modulesService: ModulesService,
    private _snackbar: SnackbarService,
    private dialog: MatDialog,
    private cameraService: CameraService,
    private httpClient: HttpClient,
    private processRecordService: ProcessRecordsService
  ) {}

  ngOnInit() {
    this.orderIdControl = this.fb.control('')
    this.pickupFormControl = this.fb.control('')
    this.newEmail = this.fb.control('')
    this.acquaintanceForm = this.fb.group({
      name: this.fb.control('', Validators.required),
      mobile: this.fb.control('', Validators.required)
    })
  }

  submitForm() {
    this.deliveryDetails = []
    if (!this.picked) {
      this.modulesService
        .getItemsByOrderId(this.orderIdControl.value)
        .subscribe(data => {
          this.initiateProcess('outForDelivery', 'shipped', data)
        })
    } else {
      this.modulesService
        .pickupitemsbyPickupId(this.orderIdControl.value)
        .subscribe(data => {
          this.initiateProcess('outForPickup', 'initiated', data)
        })
    }
    this.markDelivery = false
    this.markPickup = false
  }

  createData() {
    this.treeData = []
    // create tree-data in the format required by mat tree component.
    // also keep original data which has all info ( tree data will be a subset of this data set )

    this.deliveryDetails.forEach(item => {
      if (item.type === 'package') {
        let packageArray = item.packageItems.map(subitem => {
          return {
            name: subitem.name,
            id: subitem.productId,
            orderBarcode: subitem.barcode,
            barcode: subitem.pickupBarcode,
            url: null,
            uploaded: false
          }
        })
        this.treeData.push({
          name: item.name,
          id: item._id,
          type: item.type,
          barcode: item.barcode,
          children: packageArray
        })
      } else {
        this.treeData.push({
          name: item.name,
          id: item._id,
          type: item.type,
          orderBarcode: item.metaInfo.barcode,
          barcode: item.metaInfo.pickupBarcode,
          url: null,
          uploaded: false
        })
      }
    })
    this.treeDataSub.next(this.treeData)
    this.open = true
  }

  markDelivered() {
    const body = {
      recordId: this.recordId,
      processId: environment.config.sales.order,
      otp: this.deliveryOtp,
      reference: this.orderIdControl.value
    }
    this.openDialog()
    this.processRecordService.markDelivered(body).subscribe(
      data => {
        this._snackbar.showSnackbar(data.msg, 'success')
        this.dialog.closeAll()
        this.markDelivery = true
        this.orderIdControl.reset()
      },
      error => {
        this.dialog.closeAll()
      }
    )
  }

  markPicked() {
    this.getDataFromTree()
    const body = {
      recordId: this.recordId,
      itemInfo: this.treeData,
      otp: this.pickupOtp,
      processId: environment.config.collections.pickup,
      form: this.pickupFormControl.value,
      reference: this.orderIdControl.value
    }
    this.openDialog()
    this.processRecordService.markPicked(body).subscribe(
      data => {
        this._snackbar.showSnackbar(data.msg, 'success')
        this.markPickup = true
        this.uploaded = false
        this.orderIdControl.reset()
        this.dialog.closeAll()
      },
      error => {
        this.dialog.closeAll()
      }
    )
  }

  getDataFromTree() {
    this.getData.next(true)
  }

  generateOtp(event, type) {
    const body = {
      recordId: this.recordId,
      processId: environment.config.sales.order,
      itemInfo: event
    }
    this.openDialog()

    this.processRecordService.generateOtp(body).subscribe(
      data => {
        this._snackbar.showSnackbar(data.msg, 'success')
        this.submitForm()
        this.dialog.closeAll()
      },
      error => {
        this.dialog.closeAll()
      }
    )
  }

  onOtpChange(otp, type) {
    this.pickupOtp = type === 'pickup' ? otp : null
    this.deliveryOtp = type === 'delivery' ? otp : null
  }

  verifyPickupOtp() {}

  resendOtp() {
    const body = {
      recordId: this.recordId,
      processId: environment.config.sales.order
    }
    this.openDialog()
    this.processRecordService.resendOtp(body).subscribe(
      data => {
        this._snackbar.showSnackbar(data.msg, 'success')
        this.dialog.closeAll()
      },
      error => {
        this.dialog.closeAll()
      }
    )
  }

  changeDetails() {
    const body = {
      recordId: this.recordId,
      processId: environment.config.sales.order,
      orderId: this.orderIdControl.value,
      newEmail: this.newEmail.value
    }
    this.openDialog()
    this.processRecordService.emailChange(body).subscribe(
      data => {
        this._snackbar.showSnackbar(data.msg, 'success')
        this.newEmail.setValue('')
        this.dialog.closeAll()
      },
      error => {
        this.dialog.closeAll()
      }
    )
  }

  openDialog() {
    const dialogRef = this.dialog.open(LoadingDialogBoxComponent, {
      disableClose: true,
      panelClass: 'loadingDialog'
    })
  }

  initiateProcess(processStatus, itemStatus, data) {
    this.deliveryDetails = []
    this.recordId = data.recordId
    if (data.status != processStatus) {
      this._snackbar.showSnackbar(
        `This order does not have ${processStatus} status.`,
        'error'
      )
      return
    }
    data.items.forEach(elem => {
      if (
        !(elem.packageItems && elem.packageItems.length) &&
        elem.status === itemStatus
      ) {
        this.deliveryDetails.push(elem)
      } else if (elem.packageItems.length) {
        let pkgItemShipped = elem.packageItems.filter(
          subItem => subItem.status === itemStatus
        )
        if (pkgItemShipped.length) {
          this.deliveryDetails.push({
            ...elem,
            packageItems: pkgItemShipped
          })
        }
      }
    })

    this.createData()
  }

  openScannerEvent(node, subnode = undefined) {
    let dialogRef = this.dialog.open(BarcodeScannerComponent, {
      width: '70%'
    })
    dialogRef.componentInstance.result.subscribe(barcode => {
      this.dialog.closeAll()
      if (subnode) {
        subnode.barcode = barcode
        return
      }
      node.barcode = barcode
      return
    })
  }

  barcodeChanged(barcode, node, subnode = undefined) {
    if (subnode) {
      subnode.barcode = barcode
      return
    }
    node.barcode = barcode
    return
  }

  /** Handle camera operations */

  public async cameraSetup() {
    this.test = []
    this.test.push(
      'navigator: ' +
        (typeof navigator != 'undefined' ? 'suppported' : 'not supported')
    )
    this.test.push(
      'navigator.getUserMedia: ' +
        (typeof navigator.getUserMedia != 'undefined'
          ? 'suppported'
          : 'not supported')
    )
    this.test.push(
      'navigator.mediaDevices: ' +
        (typeof navigator.mediaDevices != 'undefined'
          ? 'suppported'
          : 'not supported')
    )
    this.test.push(
      'navigator.mediaDevices.getUserMedia: ' +
        (typeof navigator.mediaDevices.getUserMedia != 'undefined'
          ? 'suppported'
          : 'not supported')
    )
    this.test.push(
      'navigator.mediaDevices.enumerateDevices: ' +
        (typeof navigator.mediaDevices.enumerateDevices != 'undefined'
          ? 'suppported'
          : 'not supported')
    )

    const devices = await navigator.mediaDevices.enumerateDevices()
    this.devices = devices.filter(device => device.kind == 'videoinput')
  }

  @HostListener('window:deviceorientation', ['$event'])
  public onDeviceOrientation({
    alpha,
    gamma,
    beta,
    absolute
  }: DeviceOrientationEvent) {
    this.orientation = { alpha, gamma, beta, absolute }
  }

  public async captureImage(item?: any) {
    let newPic: string
    let urlResponse: any
    const body = { orderId: this.orderIdControl.value, path: 'pickupimages' }
    try {
      newPic = await this.cameraService.open()
      if (newPic) {
        urlResponse = await this.processRecordService
          .getPreSignedUrl(body)
          .toPromise()
        await this.handleUploadToS3(urlResponse.url, newPic)
        if (item) item.uploaded = true
        this.idUploaded = true
        this.url = urlResponse.url
      }
    } catch (error) {
      this.url = 'urlResponse.url'
    }
  }

  public async uploadForm() {
    let newPic: string
    let urlResponse: any
    this.uploaded = false
    const body = { orderId: this.orderIdControl.value, path: 'pickupform' }
    try {
      newPic = await this.cameraService.open()
      if (newPic) {
        urlResponse = await this.processRecordService
          .getPreSignedUrl(body)
          .toPromise()
        await this.handleUploadToS3(urlResponse.url, newPic)
        this.pickupFormControl.setValue(urlResponse.url)
        this.uploaded = true
      }
    } catch (error) {}
  }

  private async handleUploadToS3(url: string, file: string) {
    var blob = await this.convertDataURIToBinary(file)
    const uploadRequest = new HttpRequest('PUT', url, blob, {
      reportProgress: true
    })
    const res = await this.httpClient.request(uploadRequest).toPromise()
  }

  private async convertDataURIToBinary(dataURI: string) {
    const res = await fetch(dataURI)
    return await res.blob()
  }

  submitAcq() {
    if (this.acquaintanceForm.value && this.idUploaded) {
      this.typeBtn = 'reset'
      this.acquaintanceForm.reset()
      this.idUploaded = false
      this._snackbar.showSnackbar(
        'Acquantaince Details successfully saved!!',
        'success'
      )
    } else {
      this.typeBtn = 'button'
      this._snackbar.showSnackbar('Fill all necessary data!!', 'warn')
    }
  }
}
