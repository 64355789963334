import { Injectable } from '@angular/core'
import { AuthService } from './auth.service'
import { CanActivate, Router } from '@angular/router'
import { SnackbarService } from 'src/app/snackbar.service'

@Injectable()
export class AuthGuardService implements CanActivate {
  constructor(
    private authService: AuthService,
    private router: Router,
    private _snackbar: SnackbarService
  ) {}

  canActivate(): boolean {
    if (!this.authService.isAuthenticated()) {
      this.authService.resetLoginState()
      this._snackbar.showSnackbar('Authentication token expired', 'error')
      return false
    }
    return true
  }
}
