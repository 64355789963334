import { Component, OnInit, Input } from '@angular/core'
import { environment } from 'src/environments/environment'

@Component({
  selector: 'finance-list',
  templateUrl: './finance-list.component.html',
  styleUrls: ['./finance-list.component.css']
})
export class FinanceListComponent implements OnInit {
  injectRouter: boolean = false
  @Input() selected
  link: string
  financeActions = ['Update Ecs Information']

  constructor() {}

  ngOnInit() {
    this.link = `/department/${environment.config.finance.id}/`
  }
}
