import { Component, OnInit, Input } from '@angular/core'
import { environment } from 'src/environments/environment'
import { RecordViewService } from '../../process/process-records/record-view/record-view.service'

@Component({
  selector: 'collections-list',
  templateUrl: './collections-list.component.html',
  styleUrls: ['./collections-list.component.css']
})
export class CollectionsListComponent implements OnInit {
  @Input() selected
  collectionActions = ['Plan For Pickup', 'Out For Pickup']
  link: string
  constructor(private recordViewService: RecordViewService) {}

  ngOnInit() {
    this.link = `/department/${environment.config.collections.id}/`
    this.recordViewService
      .getUserPermission(environment.config.collections.pickup)
      .subscribe(permission => {
        if (permission.teams.some(team => team.title == 'Pickup Approval')) {
          this.collectionActions.push('Pickup Approval')
        }
      })
  }
}
