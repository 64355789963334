import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core'
import * as _moment from 'moment'
import * as _ from 'lodash'
import { FormControl, FormGroup } from '@angular/forms'
const moment = _moment

@Component({
  selector: 'vendor-sub-form',
  templateUrl: './vendor-sub-form.component.html',
  styleUrls: ['./vendor-sub-form.component.css']
})
export class VendorSubFormComponent implements OnInit {
  open = true
  @Input() field
  @Input() customForm
  @Input() emptyFormSchema
  @Input() i
  @Input() controls
  @Output() addEvent = new EventEmitter<any>()
  keys = Object.keys
  values = Object.values

  constructor() {}

  ngOnInit() {}

  typeof(field) {
    if (_.isArray(field)) {
      return 'array'
    } else if (_.isDate(field)) {
      return 'date'
    } else if (_.isObject(field) && field['options']) {
      return 'select'
    } else if (_.isObject(field)) {
      return 'group'
    } else {
      return 'single'
    }
  }

  changeDateFormat(event) {
    const date = moment(event.value)
    if (date.isValid()) this.controls.setValue(date.format('YYYY-MM-DD'))
  }

  add(field) {
    let grp = {}
    let ar = []
    for (let [key, val] of this.emptyFormSchema[this.i][1][0]) {
      grp[key] = new FormControl('')
      ar.push([key, val])
    }
    this.customForm.controls[field].push(new FormGroup(grp))
    this.emptyFormSchema[this.i][1].push(ar)
  }

  reduce(ind, field) {
    this.customForm.controls[field].removeAt(ind)
    this.emptyFormSchema[this.i][1].splice(ind, 1)
  }
}
