import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  OnDestroy
} from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import {
  MatTableDataSource,
  MatSort,
  MatPaginator,
  MatDialog
} from '@angular/material'
import * as XLSX from 'xlsx'
import * as moment from 'moment'
import {
  RadialChartOptions,
  ChartDataSets,
  ChartType,
  ChartOptions
} from 'chart.js'
import * as pluginDataLabels from 'chartjs-plugin-datalabels'
import { Label, SingleDataSet } from 'ng2-charts'
import {
  RecordViewService,
  UserPermission
} from '../process-records/record-view/record-view.service'
import { AuthService } from 'src/app/modules/auth/auth.service'
import { FilterDialogBoxComponent } from '../process-records/filter-dialog-box/filter-dialog-box.component'
import { FormBuilder } from '@angular/forms'
import { ShowDialogComponent } from '../show-dialog/show-dialog.component'
import { ModulesService } from '../../modules.service'
import { HttpService } from 'src/app/core/services/http.service'
import { environment } from 'src/environments/environment'
import { Subject } from 'rxjs'
import { ProcessRecordsService } from '../process-records/process-records.service'
import { WarehouseDataService } from '../warehouse-data.service'
import { SESSION_STORAGE } from 'src/app/app.constant'
import { element } from 'protractor'
import { EMAIL_DETAILS, WIDGET_DETAILS } from './../../../app.constant' // code added

@Component({
  selector: 'process-overview',
  templateUrl: './process-overview.component.html',
  styleUrls: ['./process-overview.component.css']
})
export class ProcessOverviewComponent implements OnInit, OnDestroy {
  startDate: any // code added
  endDate: any // code added
  userIndex: any // code added
  widgetIndex: any // code added
  userCheck = false // code added

  baseUrl = environment.baseURL
  loads: boolean = false
  processId: string
  widgets: any[] = []
  selectedWidget: any
  widgetSelectedValue: string
  widgetData: MatTableDataSource<any> = new MatTableDataSource<any>([])
  dataDownloaded: boolean = false
  displayedColumns: string[] = []
  userPermissions: UserPermission
  canDownload: boolean = false
  filterName: string
  filterObj: any
  overviewFilter
  param: string
  subscription
  body = {}
  limit = 10
  throttle = 300
  scrollDistance = 1
  scrollUpDistance = 2
  pageIndex
  deptId

  @ViewChild('TABLE', { static: false }) table: ElementRef

  /* table sort and paginattion CAUSE ngif was used */
  private sort: MatSort
  totalRecords
  @ViewChild(MatSort, { static: false }) set matSort(ms: MatSort) {
    this.sort = ms
    this.setDataSourceAttributes()
  }

  /* chart variables */
  // public polarAreaLegend: boolean;
  // public polarAreaChartType: ChartType;
  // public polarAreaChartLabels: Label[] = [];
  // public polarAreaChartData: SingleDataSet = [];

  OnInputStartDate(startDate: any) {
    //code added
    this.startDate = startDate
  }

  OnInputEndDate(endDate: any) {
    //code added
    this.endDate = endDate
  }

  public pieChartOptions: ChartOptions = {
    responsive: true,
    legend: {
      position: 'top'
    },
    plugins: {
      datalabels: {
        formatter: (value, ctx) => {
          const label = ctx.chart.data.labels[ctx.dataIndex]
          return label
        }
      }
    }
  }
  public pieChartLabels: Label[]
  public pieChartData: number[]
  public pieChartType: ChartType
  public pieChartLegend: boolean
  public pieChartPlugins = [pluginDataLabels]
  public pieChartColors = null

  constructor(
    private httpService: HttpService,
    private recordService: RecordViewService,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private dialog: MatDialog,
    private fb: FormBuilder,
    private moduleService: ModulesService,
    private processRecordService: ProcessRecordsService,
    private warehouseService: WarehouseDataService
  ) {}

  ngOnInit() {
    /* code added */
    const { email } = JSON.parse(localStorage.getItem('details'))
    this.userIndex = EMAIL_DETAILS.indexOf(email) + 1
    if (this.userIndex >= 1) {
      this.userCheck = true
    } else {
      this.userCheck = false
    }

    this.deptId = this.router.url.split('/')[2]
    this.processId = this.router.url.split('/')[4]
    this.moduleService.showBreadcrumb('overview', this.processId, this.deptId)
    this.route.params.subscribe(params => {
      if (params) this.processId = params['processId']
    })

    this.subscription = this.warehouseService.warehouseSubject$.subscribe(
      warehouses => {
        if (warehouses) {
          if (warehouses.length) {
            this.body['storeCode'] = warehouses
          } else {
            delete this.body['storeCode']
          }
        }
      }
    )

    let warehouses = JSON.parse(localStorage.getItem(SESSION_STORAGE.WAREHOUSE))
    if (warehouses) {
      if (warehouses.length) {
        this.body['storeCode'] = warehouses
      } else {
        delete this.body['storeCode']
      }
    }

    // this.recordService.getProcessWidgets(this.processId)
    // 	.subscribe((widgets: any[]) => {
    // 		this.widgets = widgets;
    // 	})

    this.recordService
      .getUserPermission(this.processId)
      .subscribe((res: UserPermission) => {
        this.userPermissions = res
        this.widgets = this.userPermissions.widgets
        this.canDownload = this.userPermissions.permissions.some(
          permission => permission == 'download'
        )
      })

    this.overviewFilter = this.fb.group({
      fromDate: this.fb.control(''),
      toDate: this.fb.control(''),
      monthYear: this.fb.control('')
    })
  }

  setDataSourceAttributes() {
    this.widgetData.sort = this.sort
  }

  downloadExcel() {
    let arrayElements = Object.keys(this.widgetData.data[0]).filter(key =>
      Array.isArray(this.widgetData.data[0][key])
    )
    let newWidgetData = this.widgetData.data.map(elem => {
      Object.keys(elem).forEach(key => {
        if (arrayElements.indexOf(key) >= 0) {
          elem[key] = elem[key].toString()
        }
      })
      return elem
    })
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(newWidgetData)
    const wb: XLSX.WorkBook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1')

    /* save to file */
    XLSX.writeFile(
      wb,
      `${this.selectedWidget['displayTitle']}_${moment().format(
        'DD-MM-YYYY-HH-mm'
      )}.xlsx`
    )
  }

  getSelectedWidget(): any {
    const widget = this.widgets.find(
      widget => widget.title == this.widgetSelectedValue
    )
    this.recordService
      .getProcessWidget(this.processId, widget._id)
      .subscribe(res => {
        this.selectedWidget = res
      })
  }

  getDisplayedColumns(): string[] {
    const displayColumns = []
    const widgetColumns = this.selectedWidget.columns
    displayColumns.push('sn')
    widgetColumns.map(column => {
      displayColumns.push(column.title)
    })
    return displayColumns
  }

  changeSelectedWidget(event) {
    this.widgetSelectedValue = event.value
    this.getSelectedWidget()
    this.widgetData = new MatTableDataSource([])
    this.canDownload = false
    this.dataDownloaded = false
  }

  fetchOverview() {
    let title = this.selectedWidget.title.split('')
    title.splice(title.length - 6, 6)
    title = title.join('')
    this.recordService
      .countOverviewRecords(this.processId, title)
      .subscribe(count => {
        this.totalRecords = count
      })
    if (this.selectedWidget) {
      this.pageIndex = 1
      this.body['paginate'] = { page: this.pageIndex, perPage: 10 }
      this.processRecordService.setLoading(true)
      this.dataDownloaded = false
      this.displayedColumns = this.getDisplayedColumns()
      if (this.param == 'date') {
        if (this.overviewFilter.get('toDate').value) {
          this.filterObj = {
            from: moment(this.overviewFilter.get('fromDate').value).format(
              'YYYY-MM-DD'
            ),
            to: moment(this.overviewFilter.get('toDate').value).format(
              'YYYY-MM-DD'
            )
          }
        } else {
          this.filterObj = {}
        }
      }
      if (this.body)
        if (this.filterObj && Object.keys(this.filterObj).length) {
          this.body['filters'] = [this.filterObj]
        } else {
          this.body['filters'] = []
        }

      this.authService.findProcessbyId(this.processId).subscribe(data => {
        if (this.selectedWidget.type == 'tabular') {
          /* Logic for date wise repoprt*/
          this.widgetIndex =
            WIDGET_DETAILS.indexOf(this.widgetSelectedValue) + 1
          if (this.userIndex && this.widgetIndex) {
            this.body = {
              startDate: this.startDate.value,
              endDate: this.endDate.value
            }
          }

          this.recordService
            .fetchWidgetData(
              this.processId,
              this.widgetSelectedValue,
              data.title.toLowerCase(),
              this.body
            )
            .subscribe((widgetData: any[]) => {
              this.widgetData = new MatTableDataSource(widgetData)
              this.dataDownloaded = true
              this.processRecordService.setLoading(false)
              this.canDownload = true
            })
        } else {
          this.recordService
            .fetchWidgetData(
              this.processId,
              this.widgetSelectedValue,
              data.title.toLowerCase(),
              this.body
            )
            .subscribe((chartData: any) => {
              this.prepareChart(chartData)
              this.processRecordService.setLoading(false)
            })
        }
      })
    }
  }

  prepareChart(data: any) {
    this.pieChartLegend = true
    this.pieChartType = 'pie'
    this.pieChartLabels = Object.keys(data)
    this.pieChartData = <number[]>Object.values(data)
  }

  filter(param) {
    if (param == 'month') {
      this.param = 'month'
      const dialogRef = this.dialog.open(FilterDialogBoxComponent, {
        width: '300px',
        data: {
          filteringParameter: 'Choose a month',
          processId: this.processId,
          type: 'monthType',
          fieldSelectOptions: '',
          options: '',
          formInstance: this.overviewFilter
        }
      })
      dialogRef.componentInstance.monthTypeOverview.subscribe(date => {
        this.filterName = date
        this.filterObj = {
          month: date.month,
          year: date.year
        }
      })
      this.overviewFilter.controls['fromDate'].setValue('')
      this.overviewFilter.controls['toDate'].setValue('')
      dialogRef.afterClosed().subscribe(() => {
        this.fetchOverview()
      })
    }
    if (param == 'date') {
      this.param = 'date'
      const dialogRef = this.dialog.open(FilterDialogBoxComponent, {
        width: '300px',
        data: {
          filteringParameter: 'Choose from and to',
          processId: this.processId,
          type: 'dateType',
          fieldSelectOptions: '',
          options: '',
          formInstance: this.overviewFilter,
          filterName: this.filterName
        }
      })
      this.filterName = ''
      this.filterObj = {}

      dialogRef.afterClosed().subscribe(() => {
        this.fetchOverview()
      })
    }
  }

  ngOnDestroy() {
    this.loads = false
  }

  closeFilter() {
    this.param = ''
    if (this.param == 'date') {
      this.overviewFilter.controls['fromDate'].setValue('')
      this.overviewFilter.controls['toDate'].setValue('')
    }
    if (this.param == 'month') {
      this.filterName = ''
    }
    this.fetchOverview()
  }

  showCellData(rowHeader, rowData) {
    if (rowData.length > 12 || Array.isArray(rowData)) {
      this.dialog.open(ShowDialogComponent, {
        width: '400px',
        height: '200px',
        data: {
          rowHeader: rowHeader,
          rowData: rowData
        }
      })
    }
  }

  onScroll() {
    this.processRecordService.setLoading(true)
    this.pageIndex = this.pageIndex + 1
    this.body['paginate'] = { page: this.pageIndex, perPage: 10 }
    this.authService.findProcessbyId(this.processId).subscribe(data => {
      if (this.selectedWidget.type == 'tabular') {
        this.recordService
          .fetchWidgetData(
            this.processId,
            this.widgetSelectedValue,
            data.title.toLowerCase(),
            this.body
          )
          .subscribe((widgetData: any[]) => {
            this.widgetData = new MatTableDataSource(widgetData)

            this.dataDownloaded = true
            this.processRecordService.setLoading(false)
            this.canDownload = true
          })
      } else {
        this.recordService
          .fetchWidgetData(
            this.processId,
            this.widgetSelectedValue,
            data.title.toLowerCase(),
            this.body
          )
          .subscribe((chartData: any) => {
            this.prepareChart(chartData)
            this.processRecordService.setLoading(false)
          })
      }
    })
  }
}
