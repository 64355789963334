import { environment } from 'src/environments/environment'
import { NgModule } from '@angular/core'
import { RouterModule } from '@angular/router'
import { AuthGuardService } from '../auth/auth-guard.service'
import { ProcessComponent } from '../process/process.component'
import { ProcessRecordsComponent } from '../process/process-records/process-records.component'
import { RecordViewComponent } from '../process/process-records/record-view/record-view.component'
import { ProcessAnalyticsComponent } from '../process/process-analytics/process-analytics.component'
import { ProcessOverviewComponent } from '../process/process-overview/process-overview.component'
import { ProcessManageComponent } from '../process/process-manage/process-manage.component'
import { CollectionsComponent } from './collections.component'
import { OutForPickupComponent } from './out-for-pickup/out-for-pickup.component'
import { PlanForPickupComponent } from './plan-for-pickup/plan-for-pickup.component'
import { PickupApprovalComponent } from './pickup-approval/pickup-approval.component'

export const appRoutes = [
  {
    path: `department/${environment.config.collections.id}`,
    component: CollectionsComponent,
    canActivate: [AuthGuardService],
    children: [
      {
        path: 'Out For Pickup',
        component: OutForPickupComponent
      },
      {
        path: 'Plan For Pickup',
        component: PlanForPickupComponent
      },
      {
        path: 'Pickup Approval',
        component: PickupApprovalComponent
      }
    ]
  },
  {
    path: `department/${environment.config.collections.id}/process/:processId`,
    component: ProcessComponent
  },
  {
    path: `department/${environment.config.collections.id}/process/:processId/records`,
    component: ProcessRecordsComponent
  },
  {
    path: `department/${environment.config.collections.id}/process/:processId/records/:recordId`,
    component: RecordViewComponent
  },
  {
    path: `department/${environment.config.collections.id}/process/:processId/analytics`,
    component: ProcessAnalyticsComponent
  },
  {
    path: `department/${environment.config.collections.id}/process/:processId/overview`,
    component: ProcessOverviewComponent
  },
  {
    path: `department/${environment.config.collections.id}/process/:processId/manage`,
    component: ProcessManageComponent
  }
]

@NgModule({
  imports: [RouterModule.forChild(appRoutes)],

  exports: [RouterModule]
})
export class CollectionsRoutingModule {}
