export class ContactPersonModel {
  name: string
  email: string
  phone: number
  mobile: number

  constructor(cp) {
    this.name = cp.name || ''
    this.email = cp.email || ''
    this.phone = cp.phone || ''
    this.mobile = cp.mobile || ''
  }
}
