import { Component, OnInit, Input } from '@angular/core'
import { FormControl, FormBuilder, FormGroup } from '@angular/forms'
import { SESSION_STORAGE } from 'src/app/app.constant'
import { VendorService } from '../../inventory/vendor/vendor-service.service'

@Component({
  selector: 'custom-comment',
  templateUrl: './custom-comment.component.html',
  styleUrls: ['./custom-comment.component.css']
})
export class CustomCommentComponent implements OnInit {
  @Input() type
  @Input() comments
  comment: FormGroup
  user = localStorage.getItem(SESSION_STORAGE.DETAILS)

  constructor(private fb: FormBuilder, private vendorService: VendorService) {}

  ngOnInit() {
    this.comment = this.fb.group({
      commentText: this.fb.control('')
    })
    // this.comment.disable();
  }

  addComment() {
    this.comments = this.comments ? this.comments : []
    this.comments.push({
      content: this.comment.controls.commentText.value,
      createdBy: JSON.parse(this.user).userName
    })
    if (this.type == 'vendor') {
      this.vendorService.log.next({
        title: `Comment added`,
        author: JSON.parse(this.user).userName
      })
    }
    this.comment.controls.commentText.patchValue('')
  }

  deleteComment(index) {
    this.comments.reverse().splice(index, 1)
  }
}
