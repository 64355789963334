import { Injectable, Input } from '@angular/core'
import { SESSION_STORAGE } from '../../../app.constant'
import { HttpService } from 'src/app/core/services/http.service'
import { map, debounceTime } from 'rxjs/operators'
import { environment } from '../../../../environments/environment'
import { BehaviorSubject, Subject } from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class ProcessRecordsService {
  apiUrl = environment.baseURL
  selectedListItems: BehaviorSubject<any> = new BehaviorSubject<any>(null)
  selectedListItems$ = this.selectedListItems.asObservable()

  totalItemsSelected: BehaviorSubject<any> = new BehaviorSubject<any>(null)
  totalItemsSelected$ = this.totalItemsSelected.asObservable()

  listItems: BehaviorSubject<any> = new BehaviorSubject<any>(null)
  listItems$ = this.listItems.asObservable()

  loading: BehaviorSubject<any> = new BehaviorSubject<any>(null)
  loading$ = this.loading.asObservable()

  constructor(private httpService: HttpService) {}

  handleFieldSelect(event, processId) {
    //	Changing the mat table when fields are manually changed
    let displayedColumns = [
      'checked',
      'view',
      'createdAt',
      'status',
      ...event.source._value
    ]
    let processFieldsArray = []
    let selectedConfig = {}
    selectedConfig = {
      process: processId,
      displayedColumns: displayedColumns
    }
    if (localStorage.getItem(SESSION_STORAGE.SELECTED_FIELDS)) {
      let indexOfExistingProcess = JSON.parse(
        localStorage.getItem(SESSION_STORAGE.SELECTED_FIELDS)
      ).findIndex(elem => elem.process === processId)
      processFieldsArray = JSON.parse(
        localStorage.getItem(SESSION_STORAGE.SELECTED_FIELDS)
      )
      if (indexOfExistingProcess != -1) {
        processFieldsArray[indexOfExistingProcess].displayedColumns =
          selectedConfig['displayedColumns']
      } else {
        processFieldsArray.push(selectedConfig)
      }
    } else {
      processFieldsArray.push(selectedConfig)
    }
    localStorage.setItem(
      SESSION_STORAGE.SELECTED_FIELDS,
      JSON.stringify(processFieldsArray)
    )
  }

  getData(body, processId) {
    return this.httpService
      .post(`${this.apiUrl}/records/${processId}/list`, body)
      .pipe(
        map(records => {
          return records.map(record => {
            return {
              createdAt: record.createdAt,
              status: record.status,
              recordId: record._id,
              ...record.details[0][0],
              items: record.details[1],
              shipment: record.details[6],
              pickupForm: record.details[4]
            }
          })
        })
      )
  }

  getTotalRecords(processId, body) {
    return this.httpService
      .post(`${this.apiUrl}/records/${processId}/count`, body)
      .pipe(map(size => size))
  }

  executeAction(recordId, actionId, childIds, processId) {
    const body = {
      actionId: actionId,
      recordId: recordId,
      childIds: childIds
    }
    return this.httpService.post(
      `${this.apiUrl}/records/${processId}/execute`,
      body
    )
  }

  pushAction(formId, recordId, processId, data) {
    const body = {
      data: data
    }
    return this.httpService.post(
      `${this.apiUrl}/records/${processId}/update/${recordId}/form/${formId}/add`,
      body
    )
  }

  updateAction(formId, recordId, processId, data) {
    const body = {
      data: data
    }
    setTimeout(function() {}, 3000)
    return this.httpService.post(
      `${this.apiUrl}/records/${processId}/update/${recordId}/form/${formId}/edit`,
      body
    )
  }

  deleteAction(formId, recordId, processId, childIds) {
    const body = {
      childIds: childIds
    }
    return this.httpService.post(
      `${this.apiUrl}/records/${processId}/update/${recordId}/form/${formId}/delete`,
      body
    )
  }

  changeListItems(listItemObject) {
    this.listItems.next(listItemObject)
  }

  changeTotalItemsSelected(value) {
    this.totalItemsSelected.next(value)
  }

  getProcessActions(processId) {
    return this.httpService.post(`${this.apiUrl}/process/${processId}/actions`)
  }

  getUserBulkActions(processId) {
    return this.httpService.post(
      `${this.apiUrl}/process/${processId}/bulkactions`
    )
  }

  executeList(processId, body) {
    return this.httpService.post(
      `${this.apiUrl}/records/${processId}/executelist`,
      body
    )
  }

  createBulkRequest(body) {
    return this.httpService.post(`${this.apiUrl}/firs/createbulk`, body)
  }

  setLoading(value) {
    this.loading.next(value)
  }

  generateOtp(body) {
    return this.httpService.post(
      `${this.apiUrl}/dept/sales/regenerateOtp`,
      body
    )
  }

  emailChange(body) {
    return this.httpService.post(`${this.apiUrl}/dept/sales/emailchange`, body)
  }

  resendOtp(body) {
    return this.httpService.post(`${this.apiUrl}/dept/sales/resendotp`, body)
  }

  updatestatus(processId, body) {
    return this.httpService.post(
      `${this.apiUrl}/records/${processId}/updatestatus`,
      body
    )
  }

  markDelivered(body) {
    return this.httpService.post(
      `${this.apiUrl}/dept/sales/markdelivered`,
      body
    )
  }

  markPicked(body) {
    return this.httpService.post(
      `${this.apiUrl}/dept/collections/markpicked`,
      body
    )
  }

  getPreSignedUrl(body) {
    return this.httpService.post(`${this.apiUrl}/records/uploadapiurl`, body)
  }
}
