import { Component, OnInit, Input } from '@angular/core'
import { environment } from 'src/environments/environment'

@Component({
  selector: 'sales-list',
  templateUrl: './sales-list.component.html',
  styleUrls: ['./sales-list.component.css']
})
export class SalesListComponent implements OnInit {
  injectRouter: boolean = false
  @Input() selected
  contentSelected
  processId
  link: string
  salesActions = ['Out For Delivery', 'Change Order Items']
  // salesActions = ['Change Order Items', 'Out For Delivery', 'Mark Delivered'];
  constructor() {}

  ngOnInit() {
    this.link = `/department/${environment.config.sales.id}/`
  }
}
