import { Component, OnInit, Input } from '@angular/core'
import * as _moment from 'moment'
import * as $ from 'jquery'
import { FormControl } from '@angular/forms'
const moment = _moment

@Component({
  selector: 'date-time-field',
  templateUrl: './date-time-field.component.html',
  styleUrls: ['./date-time-field.component.css']
})
export class DateTimeFieldComponent implements OnInit {
  @Input() fieldInstance: FormControl
  @Input() label: string
  @Input('fieldIndex') index: number
  @Input() field: any
  @Input() create?: boolean = false
  @Input() isRequired: boolean = false
  @Input() inputValidations: any[] = []
  @Input() readOnly: boolean = false
  @Input() path: string
  @Input() isAdmin: boolean = false
  date
  userDate
  defaultTime
  constructor() {}

  ngOnInit() {
    if (this.fieldInstance.value) {
      this.userDate = moment(this.fieldInstance.value).format('lll')
    }
    if (this.label == 'inTime') {
      this.defaultTime = '1:30 PM'
    }
    if (this.label == 'outTime') {
      this.defaultTime = '6:00 PM'
    }
  }

  changeDateFormat(event) {
    this.date = moment(event.value).format('DD/MM/YYYY')
    $('.' + this.label).click()
  }

  timeChanged(time) {
    let date = moment(this.date + ' ' + time, 'DD/MM/YYYY HH:mm A').format(
      'YYYY-MM-DDTHH:mm:ss.SSS[Z]'
    )
    this.fieldInstance.setValue(date)
    this.userDate = moment(this.date + ' ' + time, 'DD/MM/YYYY HH:mm A').format(
      'lll'
    )
  }
}
