import { Component, OnInit, Inject, EventEmitter } from '@angular/core'
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,
  FormArray,
  ValidatorFn,
  AbstractControl
} from '@angular/forms'
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material'
import { RecordListComponent } from '../record-list/record-list.component'
import { RecordViewService } from '../record-view/record-view.service'
import { SnackbarService } from 'src/app/snackbar.service'
import { SharedService } from 'src/app/shared/services/shared.service'
import { LoadingDialogBoxComponent } from '../loading-dialog-box-component/loading-dialog-box-component.component'
import { tap, flatMap, map } from 'rxjs/operators'
import { environment } from 'src/environments/environment'

@Component({
  selector: 'record-create-dialog',
  templateUrl: './record-create-dialog.component.html',
  styleUrls: ['./record-create-dialog.component.css']
})
export class RecordCreateDialogComponent implements OnInit {
  isLinear = false
  recordForm: FormGroup
  forms: any[] = []
  interviewForm
  response
  selected
  closeEvent: EventEmitter<any> = new EventEmitter()
  warehouses = [
    { title: 'Gurugram', value: 'gurgaon' },
    { title: 'Delhi', value: 'delhi' },
    { title: 'Noida', value: 'noida' },
    { title: 'Hyderabad', value: 'hyderabad' },
    { title: 'Bengaluru', value: 'bengaluru' },
    { title: 'Pune', value: 'pune' },
    { title: 'Mumbai', value: 'mumbai' }
  ]

  constructor(
    public dialogRef: MatDialogRef<RecordListComponent>,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private recordService: RecordViewService,
    private _snackbar: SnackbarService,
    private sharedService: SharedService,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    this.interviewForm = this.fb.array([])

    this.data.forms.map(form => {
      let childViewForm = this.fb.group({})
      form.fields.forEach(field => {
        switch (field.type) {
          case 'address': {
            const addressGroup = this.fb.group({
              lineOne: this.fb.control(''),
              lineTwo: this.fb.control(''),
              addressType: this.fb.control(''),
              city: this.fb.control(''),
              region: this.fb.control(''),
              pincode: this.fb.control(''),
              country: this.fb.control('')
            })

            childViewForm.addControl(field.title, addressGroup)
            break
          }
          case 'group': {
            const newListArray = this.fb.group({})
            field.fields.map(internalField => {
              if (internalField.type == 'file') {
                const fbGrp = this.fb.group({
                  title: this.fb.control(''),
                  fileUrl: this.fb.control(''),
                  password: this.fb.control('')
                })
                newListArray.addControl(internalField.title, fbGrp)
                return
              }
              const control = this.fb.control('')
              newListArray.addControl(internalField.title, control)
            })
            childViewForm.addControl(field.title, newListArray)
            break
          }
          case 'list': {
            switch (field.entity.type) {
              case 'group': {
                const newListArray = this.fb.array([])
                if (field.entity.fields) {
                  const newListGroup = this.fb.group({})

                  field.entity.fields.map(internalField => {
                    if (internalField.type == 'file') {
                      const fbGrp = this.fb.group({
                        title: this.fb.control(''),
                        fileUrl: this.fb.control(''),
                        password: this.fb.control('')
                      })
                      newListGroup.addControl(internalField.title, fbGrp)
                      return
                    }
                    const controlName = internalField.title
                    newListGroup.addControl(
                      controlName,
                      new FormControl(controlName)
                    )
                  })
                  newListArray.push(newListGroup)
                }
                childViewForm.addControl(field.title, newListArray)
                break
              }

              default: {
                const newListArray = this.fb.array([])
                childViewForm.addControl(field.title, newListArray)
              }
            }

            break
          }
          case 'file': {
            const fbGrp = this.fb.group({
              title: this.fb.control(''),
              fileUrl: this.fb.control(''),
              password: this.fb.control('')
            })
            childViewForm.addControl(field.title, fbGrp)
            break
          }
          default:
            const control = this.fb.control('')
            childViewForm.addControl(field.title, control)
        }
      })
      this.interviewForm.push(childViewForm)
      this.forms = this.data.forms
    })
  }

  submitResponse() {
    this.openDialog()
    let data = {}

    this.interviewForm.value.forEach((elem, index) => {
      data[index] = [elem]
    })
    const body = {
      store: this.selected,
      data: data
    }

    if (this.data.processId == environment.config.shipment.driver) {
      let content = {
        firstName: data[0][0].name.split(' ')[0],
        lastName: data[0][0].name.split(' ')[1],
        password: data[0][0].password,
        email: data[0][0].fakeEmail
      }
      this.sharedService
        .registerUser(content)
        .pipe(
          map(data => {
            return {
              ...body,
              _id: data.user._id
            }
          }),
          flatMap(user =>
            this.recordService.createRecord(this.data.processId, user)
          )
        )
        .subscribe(
          () => {
            this._snackbar.showSnackbar(
              `Your response has been successfully added.`,
              'success'
            )
            this.closeEvent.emit()
            this.dialog.closeAll()
          },
          error => {
            this.dialog.closeAll()
          }
        )
      return
    }

    this.recordService
      .createRecord(this.data.processId, body)
      .subscribe(res => {
        this._snackbar.showSnackbar(
          `Your response has been successfully added.`,
          'success'
        )
        this.closeEvent.emit()
      })
  }

  openDialog() {
    const dialogRef = this.dialog.open(LoadingDialogBoxComponent, {
      panelClass: 'loadingDialog',
      // height: '30%',
      data: {}
    })
  }

  onClose() {
    this.closeEvent.emit()
  }
}
