import { Injectable } from '@angular/core'
import { Subject, Observable } from 'rxjs'
import { HttpService } from 'src/app/core/services/http.service'
import { environment } from 'src/environments/environment'

@Injectable()
export class SharedService {
  baseUrl = environment.baseURL

  statesSub = new Subject<any>()
  currentStateSub = new Subject<any>()
  permissionSub = new Subject<any>()

  states$: Observable<any> = this.statesSub.asObservable()
  currentState$: Observable<any> = this.currentStateSub.asObservable()
  permissionSub$: Observable<any> = this.permissionSub.asObservable()

  constructor(private httpService: HttpService) {}
  getStates() {
    return this.states$
  }

  getCurrentState() {
    return this.currentState$
  }

  getPermission() {
    return this.permissionSub
  }

  registerUser(body) {
    return this.httpService.post(`${this.baseUrl}/user/register`, body)
  }
}
