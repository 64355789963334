import { Component, OnInit } from '@angular/core'
import { ModulesService } from '../modules.service'
import { Router } from '@angular/router'
import { environment } from 'src/environments/environment'

@Component({
  selector: 'app-inventory',
  templateUrl: './inventory.component.html',
  styleUrls: ['./inventory.component.css']
})
export class InventoryComponent implements OnInit {
  constructor(private moduleService: ModulesService, private router: Router) {}

  departmentId = environment.config.inventory.id

  ngOnInit() {}
}
