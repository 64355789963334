import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Input,
  ViewChildren,
  OnDestroy
} from '@angular/core'
import {
  Router,
  ActivatedRoute,
  Params,
  ActivationEnd,
  Route
} from '@angular/router'
import { NavToggleService } from '../../services/navtoggle.service'
import {
  BreakpointObserver,
  Breakpoints,
  BreakpointState
} from '@angular/cdk/layout'
import { Observable, from, Subscription } from 'rxjs'
import { map, filter } from 'rxjs/operators'
import { AuthService } from 'src/app/modules/auth/auth.service'
import { HttpService } from '../../services/http.service'
import { ParamChangeService } from 'src/app/shared/services/param-change.service'
import { SESSION_STORAGE } from 'src/app/app.constant'

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.css']
})
export class NavigationComponent implements OnInit, OnDestroy {
  links: Observable<any>
  linkArray: Observable<any>
  linkArrayNew: Observable<any>
  processid
  @ViewChild('navigationReference', { static: false }) navRefer
  // @ViewChildren("subnavigationReference") subnavRefer;
  processStyle = {
    opacity: 0.6
  }
  noStyle = {}
  applyStyleOnProcess: boolean = false
  private subscriptions: Array<Subscription> = []
  constructor(
    private breakpointObserver: BreakpointObserver,
    private router: Router,
    private navService: NavToggleService,
    private activedRoute: ActivatedRoute,
    private authService: AuthService,
    private paramchange: ParamChangeService,
    private navToggleService: NavToggleService
  ) {}

  ngOnInit() {
    // this.subscriptions.push(this.router.events
    // 	.pipe(filter(event => event instanceof ActivationEnd))
    // 	.subscribe((event: ActivationEnd) => {

    // 		if (event instanceof ActivationEnd) {

    // 			this.authService.breadcrumb.next(null);

    // 			debugger;
    // 			if (event.snapshot.params.deptId && event.snapshot.params.processId) {
    // 				this.paramchange.subject.next(event.snapshot.params.processId);
    // 				this.paramchange.deptSubject.next(event.snapshot.params.deptId);

    // 				this.authService.breadcrumb.next(null);

    // 				this.authService.findDeptbyId(event.snapshot.params.deptId).subscribe((data) => {
    // 					this.authService.breadcrumb.next([data]);
    // 				});

    // 				this.authService.findProcessbyId(event.snapshot.params.processId).subscribe((data) => {
    // 					if (this.authService.breadcrumb.getValue()) {
    // 						if (this.router.url.substr(this.router.url.lastIndexOf('/') + 1) === event.snapshot.params.processId) {
    // 							this.authService.breadcrumb.next([...this.authService.breadcrumb.getValue(), data]);
    // 						}
    // 					}

    // 				})
    // 				this.processid = event.snapshot.params.processId

    // 			}
    // 			else if (event.snapshot.params.deptId) {
    // 				this.authService.breadcrumb.next(null);

    // 				this.authService.findDeptbyId(event.snapshot.params.deptId).subscribe((data) => {
    // 					this.authService.breadcrumb.next([data]);
    // 				});

    // 				this.paramchange.deptSubject.next(event.snapshot.params.deptId);
    // 			}
    // 			else {
    // 				this.paramchange.subject.next(null);
    // 				this.paramchange.deptSubject.next(null);
    // 			}
    // 		}
    // 	}))
    this.links = this.authService.getLink()
  }

  resetNav() {
    this.paramchange.deptSubject.next(null)
    this.paramchange.subject.next(null)
  }

  ngOnDestroy() {
    this.subscriptions.forEach(x => x.unsubscribe())
  }
}
