import { Component, OnInit, EventEmitter, Inject } from '@angular/core'
import { FormGroup, FormBuilder } from '@angular/forms'
import { Observable } from 'rxjs'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material'
import { FirViewComponent } from '../fir-view/fir-view.component'
import { startWith, map } from 'rxjs/operators'
import { RecordViewService } from '../../../../modules/process/process-records/record-view/record-view.service'

@Component({
  selector: 'fir-forward',
  templateUrl: './fir-forward.component.html',
  styleUrls: ['./fir-forward.component.css']
})
export class FirForwardComponent implements OnInit {
  priorities: any[] = [
    { title: 'Low', value: 'low' },
    { title: 'Medium', value: 'medium' },
    { title: 'High', value: 'high' },
    { title: 'Urgent', value: 'urgent' }
  ]

  requestForm: FormGroup
  filteredUsers: Observable<any>
  firSubmit: EventEmitter<any> = new EventEmitter<any>()
  requestUsers: any[] = []

  constructor(
    public dialogRef: MatDialogRef<FirViewComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private recordService: RecordViewService
  ) {
    this.requestForm = this.formBuilder.group({
      forwardedTo: this.formBuilder.control(''),
      priority: this.formBuilder.control(this.data.fir.priority),
      comment: this.formBuilder.control('')
    })
    this.recordService
      .requestUsersList(
        this.data.processId,
        this.data.action._id,
        this.data.recordId
      )
      .subscribe((users: any[]) => {
        this.requestUsers = users
        this.filteredUsers = this.requestForm
          .get('forwardedTo')
          .valueChanges.pipe(
            startWith(''),
            map(user =>
              user ? this.filterUsers(user) : this.requestUsers.slice()
            )
          )
      })
  }

  ngOnInit() {}

  OnSubmit() {
    const data = this.requestForm.value
    const forwardedTo = this.requestUsers.find(
      user => user.userName == data.forwardedTo.toString()
    )
    data['forwardedTo'] = forwardedTo
    this.firSubmit.emit(data)
  }

  filterUsers(name: string) {
    return this.requestUsers.filter(
      user => user.userName.toLowerCase().indexOf(name.toLowerCase()) == 0
    )
  }
}
