import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { Component, OnInit, Inject, Output, EventEmitter } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material'
import { HttpClient } from '@angular/common/http'

export interface StatusDialogData {
  index: number
  title: string
  type: 'state' | 'status'
}

@Component({
  selector: 'status-create-dialog',
  templateUrl: './status-create-dialog.component.html',
  styleUrls: ['./status-create-dialog.component.css']
})
export class StatusCreateDialogComponent implements OnInit {
  stateForm: FormGroup
  type: string
  @Output() addStateEvent: EventEmitter<any> = new EventEmitter<any>()
  @Output() addStatusEvent: EventEmitter<any> = new EventEmitter<any>()

  constructor(
    public dialogRef: MatDialogRef<StatusCreateDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: StatusDialogData,
    private httpClient: HttpClient,
    public fb: FormBuilder
  ) {}

  ngOnInit() {
    this.stateForm = this.fb.group({
      index: [this.data.index, Validators.required],
      title: ['', Validators.required],
      code: ['', [Validators.required, Validators.pattern(/^[A-Za-z]+$/)]]
    })

    this.type = this.data.type
  }

  handleResponse(): void {
    this.stateForm.reset()
    this.dialogRef.close()
  }

  onSubmit() {
    if (this.type == 'state') this.addStateEvent.emit(this.stateForm.value)
    else this.addStatusEvent.emit(this.stateForm.value)
  }
}
