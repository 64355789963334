
export const environment = {
  production: false,
  baseURL: 'https://api.rentickle.com/rmp',
  inactiveTimeInSec: 40000,
  config: {
    sales: {
      id: '5c7a6fdbc2fa5f0bf79f0431',
      order: '5ce8c27efe39a00cabb868a8',
      customer: '5ce8c283fe39a00cabb868ab',
      dmi: '5ce8c288fe39a00cabb868ae',
      product: '5ce8c28efe39a00cabb868b1'
    },
    hr: {
      id: '5cfa3332c7ed26387e8bddc1',
      interviews: '5cfa3485c7ed26387e8bddf6',
      employee: '5e6e59e2094e8e1f808520f4'
    },
    finance: {
      id: '5d7032b3551fc734f3fa2348',
      ecs: '5d70332fc230fd34ff857d22'
    },
    collections: {
      id: '5c7aea759f45c295d186db9e',
      pickup: '5dce9d36d52c2f1b2081f416'
    },
    shipment: {
      id: '5f69ada9e4ca690a73283cee',
      lorry: '5f69ae46e4ca690a73283cf0',
      vehicle: '5f69ae63e4ca690a73283cf3',
      driver: '5f69ae9fe4ca690a73283cf6',
      helper: '5f69aed6e4ca690a73283cf9'
    },
    escalation: {
      id: '5f69af57e4ca690a73283cfc',
      report: '5f69b059e4ca690a73283d01'
    },
    inventory: {
      id: '5f69b07fe4ca690a73283d04',
      purchaseOrder: '5f69b0e8e4ca690a73283d06',
      vendor: '5f69b11fe4ca690a73283d09'
    }
  }
};
