import { Injectable } from '@angular/core'
import { environment } from '../../../environments/environment'

@Injectable()
export class ConfigService {
  baseURL = environment.baseURL

  getBaseURL() {
    return 'test'
  }
}
