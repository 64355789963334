import { Component, OnInit, Input } from '@angular/core'

@Component({
  selector: 'po-receive',
  templateUrl: './po-receive.component.html',
  styleUrls: ['./po-receive.component.css']
})
export class PoReceiveComponent implements OnInit {
  @Input() receiveSource
  displayedColumns = ['Purchase Receive#', 'Received On', 'Bill#']
  constructor() {}

  ngOnInit() {}
}
