import { Component, OnInit, Inject } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material'
import { HttpClient, HttpErrorResponse } from '@angular/common/http'
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
  FormArray
} from '@angular/forms'
import { environment } from 'src/environments/environment'
import { RecordViewService } from '../../../process-records/record-view/record-view.service'
import { HttpService } from 'src/app/core/services/http.service'

@Component({
  selector: 'app-widget-create-dialog',
  templateUrl: './widget-create-dialog.component.html',
  styleUrls: ['./widget-create-dialog.component.css']
})
export class WidgetCreateDialogComponent implements OnInit {
  private baseUrl: string = null
  private token: string
  public widgetForm: FormGroup
  selectedWidgetType: string
  selectedColumnType: any

  constructor(
    public dialogRef: MatDialogRef<WidgetCreateDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private httpClient: HttpClient,
    private formBuilder: FormBuilder,
    private recordService: RecordViewService,
    private httpService: HttpService
  ) {
    this.baseUrl = environment.baseURL
    this.token = JSON.parse(localStorage.getItem('x-auth-token'))
  }

  ngOnInit() {}

  getColumns(): FormArray {
    return this.widgetForm.get('columns') as FormArray
  }

  createColumn(type): FormGroup {
    return this.formBuilder.group({
      title: ['', Validators.required],
      displayTitle: ['', Validators.required],
      description: ['', Validators.required],
      type: [type, Validators.required],
      index: ['', Validators.required]
    })
  }

  onAddWidget() {
    if (this.selectedWidgetType == 'tabular') {
      this.widgetForm = this.formBuilder.group({
        widgetData: this.formBuilder.group({
          title: ['', Validators.required],
          displayTitle: ['', Validators.required],
          index: ['', Validators.required],
          type: ['tabular', Validators.required],
          description: ['', Validators.required]
        }),
        columns: this.formBuilder.array([])
      })
    } else {
      this.widgetForm = this.formBuilder.group({
        widgetData: this.formBuilder.group({
          title: ['', Validators.required],
          displayTitle: ['', Validators.required],
          index: ['', Validators.required],
          type: ['chart', Validators.required],
          description: ['', Validators.required]
        }),
        columns: this.formBuilder.array([])
      })
    }
  }

  onAddColumns() {
    this.getColumns().push(this.createColumn(this.selectedColumnType))
  }

  onAddChartColumns() {
    this.getColumns().push(this.createColumn('number'))
  }

  removeColumn(index: number) {
    const columns = this.getColumns()
    columns.removeAt(index)
  }

  onNoClick() {
    this.dialogRef.close()
  }

  onClear() {}

  onSubmit() {
    const widgetData = this.widgetForm.value
    this.httpService
      .post(
        `${this.baseUrl}/process/${this.data.processId}/createwidget`,
        widgetData
      )
      .subscribe(
        res => {
          setTimeout(() => {
            this.onNoClick()
          }, 1000)
          // this.addForm.emit(res['data']);
          this.recordService.showNotification('success', res['msg'])
        },
        (error: HttpErrorResponse) => {
          this.recordService.showNotification('success', error.error)
        }
      )
  }

  changeSelected(event) {
    this.selectedWidgetType = event.value
  }

  changeInternalSelected(event) {
    this.selectedColumnType = event.value
  }
}
