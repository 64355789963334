import { SESSION_STORAGE } from '../../../../app.constant'
import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Output
} from '@angular/core'
import { HttpClient, HttpErrorResponse } from '@angular/common/http'
import { ActivatedRoute } from '@angular/router'
import { MatDialog, MatSnackBar } from '@angular/material'
import {
  FormControl,
  FormGroup,
  Validators,
  ValidatorFn,
  AbstractControl
} from '@angular/forms'
import { environment } from 'src/environments/environment'
import { RecordViewService } from '../../process-records/record-view/record-view.service'
import { HttpService } from 'src/app/core/services/http.service'

export interface Status {
  _id: string
  title: string
  code: string
}

export interface StateGroup {
  disabled?: boolean
  _id: string
  code: string
  title: string
  statuses: Status[]
}

export function actionUrlValidator(existingActions: any[]): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const urlKey = control.value
    let duplicate = false

    existingActions.map(action => {
      if (action.urlKey == urlKey) {
        duplicate = true
      }
    })

    return duplicate ? { duplicateUrl: { value: control.value } } : null
  }
}

@Component({
  selector: 'app-process-action',
  templateUrl: './process-action.component.html',
  styleUrls: ['./process-action.component.css']
})
export class ProcessActionComponent implements OnInit {
  private baseUrl: string = null
  private processId: string = null
  private token: string
  actions: any[] = []

  states: any[] = []
  addActionBtn: Boolean = true

  actionForm = new FormGroup({
    title: new FormControl('', Validators.required),
    urlKey: new FormControl('', [
      Validators.required,
      Validators.pattern(/^[A-Za-z]+$/)
    ]),
    statusConfig: new FormControl('', Validators.required),
    status: new FormControl(''),
    state: new FormControl('')
  })
  stateGroups: StateGroup[] = []

  constructor(
    private httpService: HttpService,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private ref: ChangeDetectorRef,
    private recordService: RecordViewService
  ) {
    this.baseUrl = environment.baseURL
    this.token = localStorage.getItem(SESSION_STORAGE.TOKEN)
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      if (params) this.processId = params['processId']
    })

    this.httpService
      .post(`${this.baseUrl}/process/${this.processId}/actions`, {})
      .subscribe((data: []) => {
        this.actions = data
      })

    this.httpService
      .post(`${this.baseUrl}/process/${this.processId}/states`, {})
      .subscribe((res: []) => {
        this.states = res
        this.createStateGroups()
      })
  }

  private createStateGroups() {
    this.states.map(state => {
      const statuses = []
      state.statuses.map(status => {
        const newStatus: Status = {
          _id: status._id,
          title: status.title,
          code: status.code
        }

        statuses.push(newStatus)
      })

      const newGroup: StateGroup = {
        statuses,
        title: state.title,
        _id: state._id,
        code: state.code
      }

      this.stateGroups.push(newGroup)
    })
  }

  onSubmit() {
    let value: string = this.actionForm.value.statusConfig.split('/')
    this.actionForm.patchValue({ state: value[0] })
    this.actionForm.patchValue({ status: value[1] })

    this.httpService
      .post(
        `${this.baseUrl}/process/${this.processId}/addaction`,
        this.actionForm.value
      )
      .subscribe(
        (res: string) => {
          this.actionForm.reset()
          this.actions.push(res)
          this.recordService.showNotification('success', res['msg'])
        },
        (error: HttpErrorResponse) => {
          this.recordService.showNotification('error', error.error)
        }
      )
  }
}
