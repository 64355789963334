import {
  Component,
  OnInit,
  ViewChild,
  AfterViewInit,
  OnDestroy
} from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { Observable, Subject, BehaviorSubject } from 'rxjs'
import { map } from 'rxjs/operators'
import { AuthService } from 'src/app/modules/auth/auth.service'
import { ProcessRecordsComponent } from './process-records/process-records.component'
import { ProcessService } from './process.service'
import { environment } from '../../../environments/environment'
import { Title } from '@angular/platform-browser'
import { ParamChangeService } from 'src/app/shared/services/param-change.service'
import { HttpService } from 'src/app/core/services/http.service'
import { ModulesService } from '../modules.service'
import { ProcessRecordsService } from './process-records/process-records.service'
import { SESSION_STORAGE } from 'src/app/app.constant'
import { RecordViewService } from './process-records/record-view/record-view.service'

@Component({
  selector: 'records',
  templateUrl: './process.component.html',
  styleUrls: ['./process.component.css']
})
export class ProcessComponent implements OnInit, OnDestroy {
  baseUrl = environment.baseURL
  @ViewChild(ProcessRecordsComponent, { static: false })
  ProcessRecordsComponent: ProcessRecordsComponent
  processId$: Observable<any>
  processId
  deptId
  loading$: Observable<any>
  constructor(
    private activedRoute: ActivatedRoute,
    private paramChange: ParamChangeService,
    private router: Router,
    private authService: AuthService,
    private processService: ProcessService,
    private httpService: HttpService,
    private titleService: Title,
    private moduleService: ModulesService,
    private processRecordService: ProcessRecordsService,
    private recordViewService: RecordViewService
  ) {
    this.activedRoute.params.subscribe((data: any) => {
      this.authService
        .findProcessbyId(data.processId)
        .subscribe(processInfo => {
          this.titleService.setTitle('Rmp-' + processInfo.title)
        })
    })
  }

  ngOnInit() {
    this.router.routeReuseStrategy.shouldReuseRoute = () => {
      return false
    }
    this.loading$ = this.processRecordService.loading$
    this.activedRoute.params.subscribe((data: any) => {
      this.deptId = this.router.url.split('/')[2]
      this.processId = data.processId
    })
  }

  ngOnDestroy() {}
}
