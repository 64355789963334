import { Component, OnInit, EventEmitter } from '@angular/core'
import { BarcodeFormat } from '@zxing/library'

@Component({
  selector: 'app-barcode-scanner',
  templateUrl: './barcode-scanner.component.html',
  styleUrls: ['./barcode-scanner.component.css']
})
export class BarcodeScannerComponent implements OnInit {
  availableDevices: MediaDeviceInfo[]
  hasDevices: boolean
  qrResultString: string
  formatsEnabled = [
    BarcodeFormat.CODE_128,
    BarcodeFormat.DATA_MATRIX,
    BarcodeFormat.EAN_13,
    BarcodeFormat.QR_CODE,
    BarcodeFormat.AZTEC,
    BarcodeFormat.CODABAR,
    BarcodeFormat.CODE_39,
    BarcodeFormat.CODE_93,
    BarcodeFormat.EAN_8,
    BarcodeFormat.ITF,
    BarcodeFormat.MAXICODE,
    BarcodeFormat.PDF_417,
    BarcodeFormat.RSS_14,
    BarcodeFormat.RSS_EXPANDED,
    BarcodeFormat.UPC_A,
    BarcodeFormat.UPC_E,
    BarcodeFormat.UPC_EAN_EXTENSION
  ]
  result = new EventEmitter<any>()
  constructor() {}

  ngOnInit() {}

  clearResult(): void {
    this.qrResultString = null
  }

  onCamerasFound(devices: MediaDeviceInfo[]): void {
    this.availableDevices = devices
    this.hasDevices = Boolean(devices && devices.length)
  }

  onCodeResult(resultString: string) {
    this.result.emit(resultString)
  }
}
