import { Injectable } from '@angular/core'
import { Observable, Subject, BehaviorSubject } from 'rxjs'
import { SESSION_STORAGE } from 'src/app/app.constant'

@Injectable()
export class WarehouseDataService {
  warehouseSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null)
  warehouseSubject$ = this.warehouseSubject.asObservable()

  selectWarehouse(warehouses) {
    this.warehouseSubject.next(warehouses)
    localStorage.setItem(SESSION_STORAGE.WAREHOUSE, JSON.stringify(warehouses))
  }
}
