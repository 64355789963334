import { Component, OnInit } from '@angular/core'
import { FormControl, FormBuilder, Validators } from '@angular/forms'
import { ModulesService } from '../../modules.service'
import { Observable } from 'rxjs'
import * as moment from 'moment'
import { environment } from 'src/environments/environment'
import { ProcessRecordsService } from '../../process/process-records/process-records.service'
import { HttpService } from 'src/app/core/services/http.service'
import { MatDialog, MatSnackBar } from '@angular/material'
import { LoadingDialogBoxComponent } from '../../process/process-records/loading-dialog-box-component/loading-dialog-box-component.component'
import { SnackbarService } from 'src/app/snackbar.service'

@Component({
  selector: 'app-plan-for-pickup',
  templateUrl: './plan-for-pickup.component.html',
  styleUrls: ['./plan-for-pickup.component.css']
})
export class PlanForPickupComponent implements OnInit {
  baseUrl = environment.baseURL
  pickupProcessId = environment.config.collections.pickup
  pickupFormIndex = '5e8f3f591da915a3f1e9d1b0' //what is this?
  pickupIdControl: FormControl
  pickupId
  planPickupForm
  priorityList = ['normal', 'urgent']
  open: boolean = false
  recordId
  constructor(
    private fb: FormBuilder,
    private modulesService: ModulesService,
    private processRecordService: ProcessRecordsService,
    private httpService: HttpService,
    public dialog: MatDialog,
    private _snackbar: SnackbarService
  ) {}

  ngOnInit() {
    this.pickupIdControl = this.fb.control('')
  }

  submitForm() {
    this.planPickupForm = this.fb.group({
      plannedDate: this.fb.control('', Validators.required),
      priority: this.fb.control(''),
      alternateNumber: this.fb.control(''),
      alternateAddress: this.fb.control(''),
      slotStart: this.fb.control('', Validators.required),
      slotEnd: this.fb.control('', Validators.required),
      pendingAmount: this.fb.control('')
    })
    this.pickupId = this.pickupIdControl.value
    if (!this.pickupId.includes('-')) {
      this._snackbar.showSnackbar('Type pickup id instead of order id', 'error')
      return
    }
    this.modulesService.pickupitemsbyPickupId(this.pickupId).subscribe(
      data => {
        if (data.status === 'pendingForPickup') {
          this.open = true
        } else {
          this._snackbar.showSnackbar(
            'Order does not have pendingForPickup status.',
            'error'
          )
        }
        this.recordId = data.recordId
      },
      error => {}
    )
  }

  changeDateFormat(event) {
    const date = moment(event.value)
    if (date.isValid())
      this.planPickupForm.get('plannedDate').setValue(date.format('YYYY-MM-DD'))
  }

  planPickup() {
    this.openDialog()
    const body = {
      processId: this.pickupProcessId,
      recordId: this.recordId,
      formIndex: this.pickupFormIndex,
      updatedData: this.planPickupForm.value
    }
    this.modulesService.planPickup(body).subscribe(
      data => {
        this.dialog.closeAll()
        this._snackbar.showSnackbar(data.msg, 'success')
        this.pickupIdControl.setValue('')
        this.open = false
      },
      error => {
        this.dialog.closeAll()
        this._snackbar.showSnackbar(error.msg, 'error')
      }
    )
  }

  openDialog() {
    const dialogRef = this.dialog.open(LoadingDialogBoxComponent, {
      disableClose: true,
      panelClass: 'loadingDialog'
    })
  }
}
