import { FormGroup, FormBuilder, FormControl } from '@angular/forms'
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core'
import { MatDialog } from '@angular/material'
import { AddItemDialogComponent } from '../add-item-dialog/add-item-dialog.component'
import { ProcessRecordsService } from '../../process-records.service'
import { Observable } from 'rxjs'
import { ConfirmationDialogComponent } from '../../confirmation-dialog/confirmation-dialog.component'

@Component({
  selector: 'record-child-form',
  templateUrl: './record-child-form.component.html',
  styleUrls: ['./record-child-form.component.css']
})
export class RecordChildFormComponent implements OnInit {
  @Input() childViewForm?: FormGroup
  @Input() data: any
  @Input() form: any
  @Input() access: any = null
  @Input() isNew = false
  @Input() isList = false
  @Input() closeDialog$: Observable<boolean>
  @Input() isChildListView: boolean
  fields: any[]
  canDelete: Boolean = false
  readOnly: Boolean = true
  isAdmin: Boolean = false
  @Input() recordListItem: boolean = false
  totalItemsSelected: number
  loading$
  @Output() pushItem: EventEmitter<any> = new EventEmitter<any>()
  @Output() updateItem: EventEmitter<any> = new EventEmitter<any>()
  @Output() deleteItem: EventEmitter<any> = new EventEmitter<any>()

  constructor(
    private formBuilder: FormBuilder,
    private dialog: MatDialog,
    private processRecordService: ProcessRecordsService
  ) {}

  ngOnInit() {
    this.loading$ = this.processRecordService.loading$
    if (this.closeDialog$) {
      this.closeDialog$.subscribe(value => {
        if (value) {
          this.dialog.closeAll()
        }
      })
    }
    this.evaluatePermissions()

    if (this.data) {
      if (this.isNew) this.createNewForm()
      else this.populateForm()
    }
  }

  evaluatePermissions() {
    this.readOnly = !this.access.permissions.some(
      permission => permission == 'edit'
    )
    this.canDelete = this.access.permissions.some(
      permission => permission == 'delete'
    )
    this.isAdmin = this.access.teams.some(team => team.title == 'admin')
  }

  populateForm() {
    const fields: any[] = this.form.fields
    this.childViewForm = this.formBuilder.group({})
    this.childViewForm.addControl(
      '_id',
      this.formBuilder.control(this.data['_id'])
    )

    fields.map(field => {
      const fieldTitle = field.title
      switch (field.type) {
        case 'group': {
          const newGroup = this.formBuilder.group({})
          field.fields.map(internalField => {
            const name = internalField['title']
            if (internalField.type == 'file') {
              let fileTitle = this.data[fieldTitle]
                ? this.data[fieldTitle]['title']
                : ''
              let fileUrl = this.data[fieldTitle]
                ? this.data[fieldTitle]['fileUrl']
                : ''
              let filePass = this.data[fieldTitle]
                ? this.data[fieldTitle]['password']
                : ''

              let fileGroup = this.formBuilder.group({
                title: this.formBuilder.control(fileTitle),
                fileUrl: this.formBuilder.control(fileUrl),
                password: this.formBuilder.control(filePass)
              })
              newGroup.addControl(name, fileGroup)
            } else {
              const control = this.formBuilder.control(
                this.data[fieldTitle] ? this.data[fieldTitle][name] : ''
              )
              newGroup.addControl(name, control)
            }
          })
          this.childViewForm.addControl(field.title, newGroup)
          break
        }
        case 'list': {
          const newListArray = this.formBuilder.array([])
          switch (field.entity.type) {
            case 'number': {
              this.data[fieldTitle].map(item => {
                newListArray.push(this.formBuilder.control(item))
              })
              break
            }
            case 'string': {
              this.data[fieldTitle].map(item => {
                newListArray.push(this.formBuilder.control(item))
              })
              break
            }
            case 'group': {
              if (this.data[fieldTitle]) {
                this.data[fieldTitle].map(item => {
                  const newListGroup = this.formBuilder.group({})
                  field.entity.fields.map(internalField => {
                    const controlName = internalField.title
                    if (internalField.type == 'file') {
                      let fileTitle = item[controlName]
                        ? item[controlName]['title']
                        : ''
                      let fileUrl = item[controlName]
                        ? item[controlName]['fileUrl']
                        : ''
                      let filePass = item[controlName]
                        ? item[controlName]['password']
                        : ''

                      let fileGroup = this.formBuilder.group({
                        title: this.formBuilder.control(fileTitle),
                        fileUrl: this.formBuilder.control(fileUrl),
                        password: this.formBuilder.control(filePass)
                      })
                      newListGroup.addControl(controlName, fileGroup)
                    } else {
                      newListGroup.addControl(
                        controlName,
                        new FormControl(item[controlName])
                      )
                    }
                  })

                  newListArray.push(newListGroup)
                })
              }
              break
            }
            case 'file': {
              if (this.data[fieldTitle]) {
                this.data[fieldTitle].map(img => {
                  let fileTitle = new FormControl(img['title'])
                  let fileUrl = new FormControl(img['fileUrl'])
                  let filePass = new FormControl(img['password'])

                  const newListGroup = this.formBuilder.group({
                    title: fileTitle,
                    fileUrl: fileUrl,
                    password: filePass
                  })

                  newListArray.push(newListGroup)
                })
              }
              break
            }
          }

          this.childViewForm.addControl(field.title, newListArray)
          break
        }
        case 'address': {
          const addressGroup = this.formBuilder.group({
            lineOne: this.formBuilder.control(this.data[fieldTitle]['lineOne']),
            lineTwo: this.formBuilder.control(this.data[fieldTitle]['lineTwo']),
            addressType: this.formBuilder.control(
              this.data[fieldTitle]['addressType']
            ),
            city: this.formBuilder.control(this.data[fieldTitle]['city']),
            region: this.formBuilder.control(this.data[fieldTitle]['region']),
            pincode: this.formBuilder.control(this.data[fieldTitle]['pincode']),
            country: this.formBuilder.control(this.data[fieldTitle]['country'])
          })

          this.childViewForm.addControl(field.title, addressGroup)
          break
        }
        case 'select': {
          this.childViewForm.addControl(
            field.title,
            this.formBuilder.control(this.data[fieldTitle])
          )
          break
        }
        case 'file': {
          let fileTitle = this.data[fieldTitle]
            ? this.data[fieldTitle]['title']
            : ''
          let fileUrl = this.data[fieldTitle]
            ? this.data[fieldTitle]['fileUrl']
            : ''
          let filePass = this.data[fieldTitle]
            ? this.data[fieldTitle]['password']
            : ''

          let fileGroup = this.formBuilder.group({
            title: this.formBuilder.control(fileTitle),
            fileUrl: this.formBuilder.control(fileUrl),
            password: this.formBuilder.control(filePass)
          })

          this.childViewForm.addControl(field.title, fileGroup)
          break
        }
        case 'dateRange': {
          const dateRangeGroup = this.formBuilder.group({
            from: this.formBuilder.control(this.data[fieldTitle]['from']),
            to: this.formBuilder.control(this.data[fieldTitle]['to'])
          })

          this.childViewForm.addControl(field.title, dateRangeGroup)
          break
        }
        default: {
          this.childViewForm.addControl(
            field.title,
            this.formBuilder.control(this.data[fieldTitle])
          )
          break
        }
      }
    })
  }

  createNewForm() {
    this.fields = this.form.fields
    this.childViewForm = this.formBuilder.group({})
    this.childViewForm.addControl('_id', this.formBuilder.control(null))

    this.fields.map(field => {
      const fieldTitle = field.title
      switch (field.type) {
        case 'group': {
          const newGroup = this.formBuilder.group({})
          field.fields.map(internalField => {
            const name = internalField['title']
            const control = this.formBuilder.control('')
            newGroup.addControl(name, control)
          })
          this.childViewForm.addControl(field.title, newGroup)
          break
        }
        case 'list': {
          const newListArray = this.formBuilder.array([])
          this.childViewForm.addControl(field.title, newListArray)
          break
        }
        case 'address': {
          const addressGroup = this.formBuilder.group({
            lineOne: this.formBuilder.control(''),
            lineTwo: this.formBuilder.control(''),
            addressType: this.formBuilder.control(''),
            city: this.formBuilder.control(''),
            region: this.formBuilder.control(''),
            pincode: this.formBuilder.control(''),
            country: this.formBuilder.control('')
          })

          this.childViewForm.addControl(field.title, addressGroup)
          break
        }
        case 'select': {
          this.childViewForm.addControl(
            field.title,
            this.formBuilder.control('')
          )
          break
        }
        case 'file': {
          let fileGroup = this.formBuilder.group({
            title: '',
            fileUrl: '',
            password: ''
          })

          this.childViewForm.addControl(field.title, fileGroup)
          break
        }
        case 'dateRange': {
          const dateRangeGroup = this.formBuilder.group({
            from: '',
            to: ''
          })

          this.childViewForm.addControl(field.title, dateRangeGroup)
          break
        }
        default: {
          this.childViewForm.addControl(
            field.title,
            this.formBuilder.control('')
          )
          break
        }
      }
    })
  }

  addItem() {
    let childViewForm
    this.fields = this.form.fields
    childViewForm = this.formBuilder.group({})
    childViewForm.addControl('_id', this.formBuilder.control(null))

    this.fields.map(field => {
      const fieldTitle = field.title
      switch (field.type) {
        case 'group': {
          const newGroup = this.formBuilder.group({})
          field.fields.map(internalField => {
            const name = internalField['title']
            const control = this.formBuilder.control('')
            newGroup.addControl(name, control)
          })
          childViewForm.addControl(field.title, newGroup)
          break
        }
        case 'list': {
          const newListArray = this.formBuilder.array([])
          childViewForm.addControl(field.title, newListArray)
          break
        }
        case 'address': {
          const addressGroup = this.formBuilder.group({
            lineOne: this.formBuilder.control(''),
            lineTwo: this.formBuilder.control(''),
            addressType: this.formBuilder.control(''),
            city: this.formBuilder.control(''),
            region: this.formBuilder.control(''),
            pincode: this.formBuilder.control(''),
            country: this.formBuilder.control('')
          })

          childViewForm.addControl(field.title, addressGroup)
          break
        }
        case 'select': {
          childViewForm.addControl(field.title, this.formBuilder.control(''))
          break
        }
        case 'file': {
          let fileGroup = this.formBuilder.group({
            title: '',
            fileUrl: '',
            password: ''
          })

          childViewForm.addControl(field.title, fileGroup)
          break
        }
        case 'dateRange': {
          const dateRangeGroup = this.formBuilder.group({
            from: '',
            to: ''
          })

          childViewForm.addControl(field.title, dateRangeGroup)
          break
        }
        default: {
          childViewForm.addControl(field.title, this.formBuilder.control(''))
          break
        }
      }
    })
    let dialogRef = this.dialog.open(AddItemDialogComponent, {
      width: '600px',
      height: '75%',
      data: {
        form: this.form,
        childViewForm: childViewForm,
        isNew: true,
        access: this.access
      },
      disableClose: true
    })
    dialogRef.componentInstance.pushItem.subscribe(data => {
      this.pushItem.emit(data)
    })
  }

  addItemEvent() {
    this.pushItem.emit(this.childViewForm.value)
  }

  updateItemEvent() {
    this.updateItem.emit(this.childViewForm.value)
  }

  removeChildItem() {
    let dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '300px',
      height: '30%',
      data: {
        header: 'Delete item',
        content: 'Do you wish to delete this item?'
      }
    })
    dialogRef.componentInstance.confirmationEvent.subscribe(data => {
      this.deleteItem.emit({ data: [this.data], selectedItems: [this.data] })
    })
  }

  closeAddItemDialog() {
    this.dialog.closeAll()
  }
}
