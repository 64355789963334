import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core'

@Component({
  selector: 'record-info',
  templateUrl: './record-info.component.html',
  styleUrls: ['./record-info.component.css']
})
export class RecordInfoComponent implements OnInit {
  @Input() recordData: any
  @Input() actions: any[] = []
  @Input() recordFirs?: any[] = []
  @Output() loading?: EventEmitter<any> = new EventEmitter<any>()
  @Input() recordId
  @Input() processId

  constructor() {}

  ngOnInit() {}

  loadingResult(event) {
    this.loading.emit(event)
  }
}
