import { DepartmentContentComponent } from './department/department-content/department-content.component'
import { NgModule } from '@angular/core'
import { RouterModule } from '@angular/router'
import { AuthRoutingModule } from './modules/auth/auth-routing.module'
import { LoginComponent } from './modules/auth/login/login.component'
import { FirViewComponent } from './core/components/fir/fir-view/fir-view.component'
import { AuthGuardService } from './modules/auth/auth-guard.service'
import { DepartmentRoutingModule } from './modules/department-routing.module'
import { InterviewFormComponent } from './core/components/interview-form/interview-form.component'
import { MarkDeliveredFormComponent } from './core/components/mark-delivered-form/mark-delivered-form.component'
import { MarkPickedFormComponent } from './core/components/mark-picked-form/mark-picked-form.component'
import { SetRentalsComponent } from './core/components/set-rentals/set-rentals.component'
import { FirComponent } from './core/components/fir/fir.component'
import { ForgotPasswordComponent } from './modules/auth/forgot-password/forgot-password.component'
import { ResetPasswordComponent } from './modules/auth/reset-password/reset-password.component'

const appRoutes = [
  {
    path: 'interview-form',
    component: InterviewFormComponent
  },
  {
    path: 'mark-delivered-form',
    component: MarkDeliveredFormComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'mark-picked-form',
    component: MarkPickedFormComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'forgot',
    component: ForgotPasswordComponent
  },
  {
    path: 'resetPassword',
    component: ResetPasswordComponent
  },
  {
    path: 'dashboard',
    component: FirComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'request/:id',
    component: FirViewComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'set-rentals',
    component: SetRentalsComponent
  },
  {
    path: '**',
    redirectTo: 'login'
  }
]

@NgModule({
  imports: [
    DepartmentRoutingModule,
    AuthRoutingModule,
    RouterModule.forRoot(appRoutes)
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
