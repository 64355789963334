import {
  Component,
  OnInit,
  HostListener,
  Inject,
  ViewChild,
  OnDestroy,
  ElementRef,
  Input,
  ChangeDetectorRef
} from '@angular/core'
import { HttpService } from 'src/app/core/services/http.service'
import { environment } from '../../../../../environments/environment'
import { ActivatedRoute, Router } from '@angular/router'
import { SESSION_STORAGE } from '../../../../app.constant'
import { ProcessRecordsService } from '../process-records.service'
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { map, findIndex, throwIfEmpty } from 'rxjs/operators'
import { zip, BehaviorSubject, Subject } from 'rxjs'
import * as _ from 'lodash'

import { FilterDialogBoxComponent } from '../filter-dialog-box/filter-dialog-box.component'
import { Observable } from 'rxjs'
import { FieldDialogBoxComponent } from '../field-dialog-box/field-dialog-box.component'
import { MatPaginator } from '@angular/material/paginator'
import { WarehouseDataService } from '../../warehouse-data.service'
import { SelectionModel, DataSource } from '@angular/cdk/collections'
import { MatTableDataSource, MatTable } from '@angular/material/table'
import { ProcessService } from '../../process.service'
import { HttpErrorResponse } from '@angular/common/http'
import { RequestDialogComponent } from '../record-view/request-dialog/request-dialog.component'
import { NotifierService } from 'angular-notifier'
import { RequestsSocketService } from '../requests-socket.service'
import { MatSnackBar } from '@angular/material'
import { SnackbarService } from 'src/app/snackbar.service'
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component'
import { RecordViewService } from '../record-view/record-view.service'
import { RecordCreateDialogComponent } from '../record-create-dialog/record-create-dialog.component'
@HostListener('scroll', ['$event'])
@Component({
  selector: 'record-list',
  templateUrl: './record-list.component.html',
  styleUrls: ['./record-list.component.css']
})
export class RecordListComponent implements OnInit, OnDestroy {
  @Input() deptId
  @Input() processId
  perPage = 10
  apiUrl = environment.baseURL
  recordData
  dataSource: Observable<any[]>
  dataSourceSubject: BehaviorSubject<any> = new BehaviorSubject<any>([])
  dataSourceObservable = this.dataSourceSubject.asObservable()
  formFields
  displayedColumns
  filteredColumns
  defaultFields = []
  filteredFormFields
  processFields
  processFieldsArray: any[] = []
  fieldArray$: Observable<any[]>
  filterObj = {}
  activeFilters: Observable<any>
  filteredTypes = []
  forms
  totalRecords
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator
  @ViewChild('matTable', { static: false }) matTable
  objectKeys = Object.keys
  objectValues = Object.values
  body = []
  subscription
  selection = new SelectionModel(true, [])
  datasource: MatTableDataSource<any> = new MatTableDataSource()
  dataLoaded: boolean = false
  fieldToolTip = 'click to copy!'
  allowExecute: boolean = false
  allowRequest: boolean = false
  selectedState: string = null
  selectedStatus: string = null
  processActions: any[] = []
  listActions: any[] = []
  actionSelectValue: string
  requestMapper: any = {}
  selectedRecordIds: any[] = []
  actions: any[] = []
  selectedAction: string = null
  sender: any
  pageIndex$: Observable<any>
  permissions
  deletePermission
  visibleDataLength = 0
  tableData
  filterSubscription
  pageSubscription
  dataSubscription
  @ViewChild('customNotification', { static: true }) customNotificationTmpl

  constructor(
    private httpService: HttpService,
    public activatedRoute: ActivatedRoute,
    private processRecordService: ProcessRecordsService,
    private dialog: MatDialog,
    private warehouseService: WarehouseDataService,
    private router: Router,
    private processService: ProcessService,
    private notifier: NotifierService,
    private requestSocketService: RequestsSocketService,
    private _snackBar: SnackbarService,
    private recordService: RecordViewService,
    private cdr: ChangeDetectorRef
  ) {
    // this.processRecordService.setLoading(true);
  }

  ngOnInit() {
    this.fieldArray$ = this.processService.getFiltersObservable(this.processId)
    this.filterSubscription = this.fieldArray$.subscribe(bodyData => {
      this.body['filters'] = bodyData
    })
    this.pageIndex$ = this.processService.getPageIndexObservable()
    this.pageSubscription = this.pageIndex$.subscribe(pageIndex => {
      this.body['paginate'] = {
        page: pageIndex + 1,
        perPage: 10
      }
    })

    this.subscription = this.warehouseService.warehouseSubject$.subscribe(
      warehouses => {
        if (warehouses) {
          this.body['storeCode'] = warehouses
          this.initialize()
        }
      }
    )

    let warehouses = JSON.parse(localStorage.getItem(SESSION_STORAGE.WAREHOUSE))
    this.body['storeCode'] = warehouses
    this.initialize()
    // this.getTotalRecords(Object.assign({}, this.body));
    // this.dataSource.subscribe(data => {
    // 	this.dataSourceSubject.next(data);
    // });

    //Extracting form fields and making them as header
    this.httpService
      .post(`${this.apiUrl}/process/${this.processId}/forms`)
      .subscribe(forms => {
        this.forms = forms
        this.processRecordService.setLoading(false)
        this.formFields = forms[0].fields
        this.filteredFormFields = this.formFields.filter(
          formField => formField.type !== 'file'
        )
        this.filteredColumns = this.filteredFormFields.map(
          formField => formField.title
        )
        this.filteredTypes = this.filteredFormFields.map(
          formField => formField.type
        )

        this.displayedColumns = [
          'checked',
          'view',
          'createdAt',
          'status',
          ...this.filteredColumns
        ].slice(0, 6)
        if (localStorage.getItem(SESSION_STORAGE.SELECTED_FIELDS)) {
          let selectedFields = JSON.parse(
            localStorage.getItem(SESSION_STORAGE.SELECTED_FIELDS)
          )
          let indexOfExistingProcess = selectedFields.findIndex(
            elem => elem.process === this.processId
          )
          if (indexOfExistingProcess != -1) {
            if (selectedFields[indexOfExistingProcess].displayedColumns) {
              this.displayedColumns =
                selectedFields[indexOfExistingProcess].displayedColumns
            }
          }
        }
        this.defaultFields = this.displayedColumns //Some fields get selected by default
      })

    this.selection.onChange.subscribe(selectionObject => {
      const selectedRecord = selectionObject.added[0]
      const removedRecord = selectionObject.removed[0]
      const actionableRecord = selectedRecord ? selectedRecord : removedRecord

      let canChange = true
      let finalState = actionableRecord.state
      let finalStatus = actionableRecord.status

      if (selectedRecord) {
        this.selection.selected.map(listRecord => {
          if (listRecord._id != selectedRecord._id)
            if (
              !(
                listRecord.status == finalStatus &&
                listRecord.state == finalState
              )
            )
              canChange = false
        })
      } else if (removedRecord) {
        if (this.selection.selected.length == 0) {
          canChange = false
        } else {
          finalState = this.selection.selected[0].state
          finalStatus = this.selection.selected[0].status

          this.selection.selected.map(listRecord => {
            if (
              !(
                finalState == listRecord.state &&
                finalStatus == listRecord.status
              )
            ) {
              canChange = false
            } else {
              finalStatus = listRecord.status
              finalState = listRecord.state
            }
          })
        }
      }

      if (!canChange) {
        this.allowExecute = false
        this.allowRequest = false
        this.selectedState = null
        this.selectedStatus = null

        this.listActions = []
      } else {
        this.selectedState = finalState
        this.selectedStatus = finalStatus

        if (this.actionSelectValue)
          this.showGeneratedRequests(this.actionSelectValue)
        this.listActions = this.processActions.filter(
          action => action.status == finalStatus
        )
      }
      selectionObject.removed.forEach(elem => {
        this.requestMapper[elem._id] = undefined
      })
    })

    this.processRecordService
      .getProcessActions(this.processId)
      .subscribe((res: any[]) => {
        this.processActions = res
      })

    this.processRecordService
      .getUserBulkActions(this.processId)
      .subscribe((res: any[]) => {
        this.actions = res
      })

    // this.requestSocketService.listen('request event').subscribe((data) => {
    // 	debugger;
    // 	if (data['receiver']['userName'] == JSON.parse(localStorage.getItem(SESSION_STORAGE.DETAILS)).userName) {
    // 		this.notifier.show({
    // 			message: `Sender: ${data['sender']} \u00A0\u00A0 Action: ${data['action']}`,
    // 			type: data['type'],
    // 			template: this.customNotificationTmpl,
    // 			id: data['sender']
    // 		});
    // 	}
    // });
  }

  initialize() {
    this.permissions = this.recordService.getUserPermission(this.processId)
    this.dataSource = this.processRecordService.getData(
      Object.assign({}, this.body),
      this.processId
    )
    this.dataSubscription = this.dataSource.subscribe(data => {
      this.dataSourceSubject.next(data)
      this.processRecordService.setLoading(false)
      this.visibleDataLength = data.length
    })
    this.getTotalRecords(Object.assign({}, this.body))
  }

  onViewRecord(recordId) {
    this.router.navigate([
      'department',
      this.deptId,
      'process',
      this.processId,
      'records',
      recordId
    ])
  }

  changePage(pageEvent) {
    this.processService.setPageIndex(pageEvent.pageIndex)
    this.matTable._data.map(row => {
      this.selection.deselect(row)
    })
    this.processRecordService.setLoading(true)
    this.body['paginate'] = {
      page: pageEvent.pageIndex + 1,
      perPage: pageEvent.pageSize
    }
    this.perPage = pageEvent.pageSize

    this.fieldArray$.subscribe(fieldArray => {
      this.body['filters'] = fieldArray
    })

    this.processRecordService
      .getData(Object.assign({}, this.body), this.processId)
      .subscribe(newData => {
        if (newData.length) {
          let existingData = []
          this.processRecordService.setLoading(false)
          this.dataSourceObservable.subscribe(data => {
            existingData = data
            this.visibleDataLength = data.length
          })

          this.dataSourceSubject.next([...newData])
        }
      })
  }

  filterFields(field) {
    let indexOfField = this.filteredColumns.findIndex(
      filterField => filterField === field
    )
    let typeOfField = this.filteredTypes[indexOfField]

    let options
    if (
      typeOfField == 'select' ||
      field == 'status' ||
      typeOfField == 'yesNo'
    ) {
      this.fieldArray$.subscribe(data => {
        if (
          data &&
          data.findIndex(filterObj => Object.keys(filterObj)[0] === field) >= 0
        ) {
          options =
            data[
              data.findIndex(filterObj => Object.keys(filterObj)[0] === field)
            ][field]
        }
      })
    }

    let fieldSelectOptions
    if (
      this.formFields.findIndex(formField => formField.title === field) >= 0
    ) {
      fieldSelectOptions = this.formFields[
        this.formFields.findIndex(formField => formField.title === field)
      ].options
    }
    const dialogRef = this.dialog.open(FilterDialogBoxComponent, {
      width: '300px',
      // height: '30%',
      data: {
        filteringParameter: field,
        processId: this.processId,
        type: typeOfField,
        fieldSelectOptions,
        options
      }
    })

    dialogRef.componentInstance.filterData.subscribe(result => {
      this.body['paginate'] = { page: 1, perPage: this.perPage }
      this.processService.setPageIndex(0)
      this.paginator.pageIndex = 0
      let index
      let existingData
      this.fieldArray$.subscribe(data => {
        if (data) {
          existingData = data
          index = existingData.findIndex(
            filterObj => Object.keys(filterObj)[0] === field
          )
        }
      })
      if (index >= 0) {
        existingData[index][Object.keys(existingData[index])[0]] = result[field]
      } else {
        if (existingData) {
          existingData = [result, ...existingData]
        } else {
          existingData = [result]
        }
      }
      let newBody = {
        ...this.body,
        filters: existingData
      }
      this.processService.setBody(newBody)
      this.processService.getBody().subscribe(body => {
        this.dataSource = this.processRecordService.getData(
          body,
          this.processId
        )
      })

      this.dataSource.subscribe(data => {
        this.dataSourceSubject.next(data)
        this.visibleDataLength = data.length
      })
      this.dataSourceObservable.subscribe(() => {
        this.dataLoaded = true
      })
      if (this.dataLoaded == true) {
        this.processService.setFilters(this.processId, existingData)
      }
      this.getTotalRecords(Object.assign({}, this.body))
    })
  }

  selectFields() {
    this.defaultFields = this.displayedColumns
    const dialogRef = this.dialog.open(FieldDialogBoxComponent, {
      width: '300px',
      height: '60%',
      data: {
        processId: this.processId,
        filteredFormFields: this.filteredFormFields,
        defaultFields: this.defaultFields
      }
    })

    dialogRef.componentInstance.fieldData.subscribe(result => {
      this.displayedColumns = result
    })
  }
  clearField(field) {
    this.matTable._data.map(row => {
      this.selection.deselect(row)
    })
    let existingData, index
    this.fieldArray$.subscribe(data => {
      if (data && data.length) {
        index = data.findIndex(filterObj => Object.keys(filterObj)[0] === field)
        existingData = [...data.slice(0, index), ...data.slice(index + 1)]
      }
    })

    this.processService.setFilters(this.processId, existingData)

    this.body['paginate'] = { page: 1, perPage: this.perPage }
    this.processService.setPageIndex(0)
    this.fieldArray$.subscribe(fieldArray => {
      this.processService.setBody(fieldArray)
    })
    this.dataSource = this.processRecordService.getData(
      Object.assign({}, this.body),
      this.processId
    )
    this.dataSource.subscribe(data => {
      this.dataSourceSubject.next(data)
      this.visibleDataLength = data.length
    })

    this.paginator.pageIndex = 0
    this.getTotalRecords(Object.assign({}, this.body))
  }
  isObject(val) {
    if (_.isObject(val) && !_.isArray(val)) {
      return true
    }
  }
  isArray(val) {
    if (_.isArray(val)) {
      return true
    }
  }

  getFirstValues(data: any): any[] {
    return this.objectValues(data)[0] as Array<any>
  }

  getTotalRecords(body) {
    this.httpService
      .post(`${this.apiUrl}/records/${this.processId}/count`, body)
      .subscribe(size => {
        this.totalRecords = size
      })
  }
  isAllSelected() {
    const numSelected = +this.selection.selected.length
    const numRows = this.visibleDataLength
    return numSelected == numRows
  }
  masterToggle() {
    let data = this.matTable
    const selection = this.isAllSelected()
    selection
      ? this.selection.clear()
      : data._data.map(row => {
          this.selection.select(row)
        })
  }

  showGeneratedRequests(actionId) {
    this.requestMapper = {}
    this.selectedRecordIds = this.selection.selected.map(record => record._id)
    this.checkIfFirExists(
      this.processId,
      this.selectedRecordIds,
      actionId
    ).subscribe((res: any[]) => {
      res.map(request => {
        this.requestMapper[`${request.recordId}`] = request
      })
    })
  }

  checkIfActionAllowed(event) {
    this.allowExecute = false
    this.allowRequest = false
    const actionId: string = event.value
    this.actionSelectValue = actionId

    if (actionId) this.showGeneratedRequests(actionId)

    if (
      actionId &&
      this.actions.find(action => action._id == actionId && action.bulk)
    ) {
      this.allowExecute = this.selection.selected.length > 0
      this.selectedAction = actionId
    } else if (actionId) this.allowRequest = true
  }

  checkIfFirExists(processId, recordIds, actionId) {
    return this.httpService.post(
      `${this.apiUrl}/records/${processId}/fircheck`,
      { recordIds, actionId }
    )
  }

  onExecute() {
    this.selectedRecordIds = this.selection.selected.map(
      record => record.recordId
    )
    this.processRecordService.setLoading(true)
    this.processRecordService
      .executeList(this.processId, {
        recordIds: this.selectedRecordIds,
        actionId: this.actionSelectValue
      })
      .subscribe(
        res => {
          this._snackBar.showSnackbar(res['msg'], 'success')
          this.processRecordService.setLoading(false)
          this.allowExecute = false
          // this.miscService.onExecutionComplete(true);
          this.refresh()
          // this.initialize();
          this.selection.clear()
          this.actionSelectValue = ''
        },
        (error: HttpErrorResponse) => {
          this._snackBar.showSnackbar(error['msg'], 'error')
          this.processRecordService.setLoading(false)
        }
      )
  }

  refresh() {
    this.processRecordService.setLoading(true)
    this.initialize()
  }

  delete() {
    let recordIds = this.selection.selected.map(record => record.recordId)
    let dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '300px',
      height: '30%',
      data: {
        header: 'Delete item',
        content: 'Do you wish to delete these records?'
      }
    })
    dialogRef.componentInstance.confirmationEvent.subscribe(data => {
      this.processRecordService.setLoading(true)

      this.recordService.deleteRecords(this.processId, recordIds).subscribe(
        data => {
          this.dialog.closeAll()
          this.processRecordService.setLoading(false)
          this.refresh()
          this._snackBar.showSnackbar(`Record deleted.`, 'success')
        },
        err => {
          this.processRecordService.setLoading(false)
          this.dialog.closeAll()
        }
      )
    })
  }

  createRecord() {
    const dialogRef = this.dialog.open(RecordCreateDialogComponent, {
      width: '65%',
      height: '70%',
      panelClass: 'overflow',
      data: {
        forms: this.forms,
        processId: this.processId
      },
      disableClose: true
    })

    dialogRef.componentInstance.closeEvent.subscribe(data => {
      this.initialize()
      this.dialog.closeAll()
    })
  }

  ngOnDestroy() {
    this.subscription.unsubscribe()
    this.filterSubscription.unsubscribe()
    this.pageSubscription.unsubscribe()
    this.dataSubscription.unsubscribe()
  }

  viewRequest(id: string) {
    // TO BE IMPLEMENTED
  }

  closeNotification(id) {
    // TO BE IMPLEMENTED
  }
}
