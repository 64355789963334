import { environment } from 'src/environments/environment'
import { Component, OnInit } from '@angular/core'
import { FormGroup, FormBuilder, FormArray, FormControl } from '@angular/forms'
import { Observable } from 'rxjs'
import { RecordViewService } from '../../../modules/process/process-records/record-view/record-view.service'
import { Router } from '@angular/router'

@Component({
  selector: 'interview-form',
  templateUrl: './interview-form.component.html',
  styleUrls: ['./interview-form.component.css']
})
export class InterviewFormComponent implements OnInit {
  forms
  activeIndex = -1
  formObservable: Observable<any>
  interviewForm: FormArray
  response
  constructor(
    private fb: FormBuilder,
    private recordService: RecordViewService,
    private router: Router
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = function() {
      return false
    }
  }

  ngOnInit() {
    this.router.onSameUrlNavigation = 'reload'

    this.interviewForm = this.fb.array([])
    this.formObservable = this.recordService.getProcessForms(
      environment.config.hr.interviews
    )

    this.formObservable.subscribe(forms => {
      forms.map(form => {
        let childViewForm = this.fb.group({})
        form.fields.forEach(field => {
          switch (field.type) {
            case 'address': {
              const addressGroup = this.fb.group({
                lineOne: this.fb.control(''),
                lineTwo: this.fb.control(''),
                addressType: this.fb.control(''),
                city: this.fb.control(''),
                region: this.fb.control(''),
                pincode: this.fb.control(''),
                country: this.fb.control('')
              })

              childViewForm.addControl(field.title, addressGroup)
              break
            }
            case 'list': {
              switch (field.entity.type) {
                case 'group': {
                  const newListArray = this.fb.array([])
                  if (field.entity.fields) {
                    const newListGroup = this.fb.group({})

                    field.entity.fields.map(internalField => {
                      const controlName = internalField.title
                      newListGroup.addControl(
                        controlName,
                        new FormControl(controlName)
                      )
                    })
                    newListArray.push(newListGroup)
                  }
                  childViewForm.addControl(field.title, newListArray)
                  break
                }

                default: {
                  const newListArray = this.fb.array([])
                  childViewForm.addControl(field.title, newListArray)
                }
              }

              break
            }
            default:
              const control = this.fb.control('')
              childViewForm.addControl(field.title, control)
          }
        })
        this.interviewForm.push(childViewForm)
        this.forms = forms
      })
    })
  }

  submitResponse() {
    this.response = 'Your response has been recorded.'
  }
}
