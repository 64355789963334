import { Component, OnInit, Input } from '@angular/core'
import { HttpService } from 'src/app/core/services/http.service'
import { environment } from '../../../../../../environments/environment'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { MatDialog } from '@angular/material'
import { ShowDialogComponent } from '../../../show-dialog/show-dialog.component'

@Component({
  selector: 'status-bar',
  templateUrl: './status-bar.component.html',
  styleUrls: ['./status-bar.component.css']
})
export class StatusBarComponent implements OnInit {
  baseUrl = environment.baseURL
  @Input() states: Observable<any>
  @Input() statuses: Observable<any>
  showStatus: boolean
  @Input() processId
  @Input() state$: Observable<any>
  stateObj: object
  indexCurrentState
  newStates
  @Input() statusLog
  constructor(private httpService: HttpService, public dialog: MatDialog) {}

  ngOnInit() {
    if (this.state$) {
      this.state$.subscribe(stateObj => {
        this.states.subscribe(states => {
          this.newStates = states
          this.indexCurrentState = states.findIndex(state => {
            return state.code === stateObj.state
          })
        })
        this.statusLog = stateObj.statusLog
        this.stateObj = stateObj
      })
    }
  }

  mouseEnter(states) {
    this.dialog.open(ShowDialogComponent, {
      width: '400px',
      height: '90%',
      data: {
        rowHeader: 'Status Execution Flow',
        rowData: states,
        statusLog: this.statusLog
      }
    })
  }
}
