import { Component, OnInit, Inject } from '@angular/core'
import { MAT_DIALOG_DATA } from '@angular/material'

@Component({
  selector: 'app-add-comment-dailog',
  templateUrl: './add-comment-dailog.component.html',
  styleUrls: ['./add-comment-dailog.component.css']
})
export class AddCommentDailogComponent implements OnInit {
  commentLevels
  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {}

  ngOnInit() {
    this.commentLevels = this.data.commentLevels
  }
}
