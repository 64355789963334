import { Component, OnInit, Input } from '@angular/core'
import { VendorService } from '../vendor-service.service'

@Component({
  selector: 'vendor-log',
  templateUrl: './vendor-log.component.html',
  styleUrls: ['./vendor-log.component.css']
})
export class VendorLogComponent implements OnInit {
  logMessages = []
  constructor(private vendorService: VendorService) {}

  ngOnInit() {
    this.vendorService.log.subscribe(commit => {
      this.logMessages.push(commit)
    })
  }
}
