import { FormBuilder, FormGroup, FormControl } from '@angular/forms'
import { Component, OnInit, Input } from '@angular/core'

@Component({
  selector: 'process-form-field-card',
  templateUrl: './process-form-field-card.component.html',
  styleUrls: ['./process-form-field-card.component.css']
})
export class ProcessFormFieldCardComponent implements OnInit {
  @Input() field: FormGroup
  @Input() fieldTypesArray
  @Input() controls
  @Input() formInstance
  objectValues = Object.values
  booleanOptions = [true, false]
  formKeys = []
  constructor() {}

  ngOnInit() {
    this.formKeys = Object.keys(this.field.controls)
  }
}
