import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input
} from '@angular/core'
import { ParamChangeService } from 'src/app/shared/services/param-change.service'
import { Observable, Subject } from 'rxjs'
import {
  ActivatedRoute,
  Router,
  NavigationEnd,
  NavigationStart,
  ActivationEnd,
  Event as NavigationEvent,
  ActivationStart
} from '@angular/router'
import { filter, map } from 'rxjs/operators'
import { AuthService } from 'src/app/modules/auth/auth.service'
import { SharedService } from 'src/app/shared/services/shared.service'
import { ModulesService } from '../modules.service'

@Component({
  selector: 'breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.css']
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class BreadcrumbsComponent implements OnInit {
  processType
  data: Observable<any>
  // breadcrumbs: Array<any> = [];
  breadcrumbs: Observable<any>
  showStatusSub = new Subject<any>()
  showStatus = this.showStatusSub.asObservable()
  @Input() departmentId
  @Input() processId
  @Input() type
  recordId

  constructor(
    private paramchange: ParamChangeService,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private sharedService: SharedService,
    private moduleService: ModulesService
  ) {}

  ngOnInit() {
    this.breadcrumbs = this.moduleService.breadcrumb$
    // this.showStatus = this.sharedService.getStates();

    this.router.events
      .pipe(filter(event => event instanceof ActivationStart))
      .subscribe((event: NavigationEvent) => {
        if (event instanceof ActivationStart) {
          if (event.snapshot.params.recordId) {
            this.showStatusSub.next(true)
            this.recordId = event.snapshot.params.recordId
          } else {
            this.showStatusSub.next(false)
          }
        }
      })

    this.moduleService.showBreadcrumb(
      this.type,
      this.processId,
      this.departmentId
    )
  }

  ngAfterContentInit() {
    this.breadcrumbs = this.moduleService.breadcrumb$
  }
}
