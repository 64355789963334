import { Component, OnInit, Input } from '@angular/core'
import { FormControl, FormGroup } from '@angular/forms'

@Component({
  selector: 'email-field',
  templateUrl: './email-field.component.html',
  styleUrls: ['./email-field.component.css']
})
export class EmailFieldComponent implements OnInit {
  @Input() fieldInstance: FormControl | FormGroup
  @Input() label: string
  @Input('fieldIndex') index: number
  @Input() field: any
  @Input() create?: boolean = false
  @Input() isRequired: boolean = false
  @Input() inputValidations: any[] = []
  @Input() readOnly: boolean = false
  @Input() path: string
  @Input() isAdmin: boolean = false

  constructor() {}

  ngOnInit() {}
}
