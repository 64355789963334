import { Component, OnInit, Inject, Output, EventEmitter } from '@angular/core'
import { MAT_DIALOG_DATA } from '@angular/material'

@Component({
  selector: 'app-add-item-dialog',
  templateUrl: './add-item-dialog.component.html',
  styleUrls: ['./add-item-dialog.component.css']
})
export class AddItemDialogComponent implements OnInit {
  childViewForm = this.data.childViewForm
  form = this.data.form
  access = this.data.access
  isNew = this.data.isNew

  @Output() pushItem: EventEmitter<any> = new EventEmitter<any>()
  @Output() updateItem: EventEmitter<any> = new EventEmitter<any>()

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {}

  ngOnInit() {}

  addItemEvent(event) {
    this.pushItem.emit(event)
  }
}
