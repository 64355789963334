import { environment } from 'src/environments/environment'
import { Component, OnInit } from '@angular/core'
import { ModulesService } from '../modules.service'
import { Router, ActivatedRoute } from '@angular/router'

@Component({
  selector: 'app-finance',
  templateUrl: './finance.component.html',
  styleUrls: ['./finance.component.css']
})
export class FinanceComponent implements OnInit {
  selected = 'none'

  constructor(
    private moduleService: ModulesService,
    private router: Router,
    public route: ActivatedRoute
  ) {}

  departmentId = environment.config.finance.id

  ngOnInit() {}
}
