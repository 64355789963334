import { Component, OnInit, Inject, EventEmitter, Input } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { FormBuilder } from '@angular/forms'
import { Observable } from 'rxjs'
import { debounceTime, map, ignoreElements } from 'rxjs/operators'
import { HttpService } from 'src/app/core/services/http.service'
import { environment } from '../../../../../environments/environment'
import * as moment from 'moment'

@Component({
  selector: 'app-filter-dialog-box',
  templateUrl: './filter-dialog-box.component.html',
  styleUrls: ['./filter-dialog-box.component.css']
})
export class FilterDialogBoxComponent implements OnInit {
  public filterStr
  filterData = new EventEmitter()
  filterForm
  filterDate
  fromDate
  toDate
  temp: any
  statuses = []
  apiUrl = environment.baseURL
  selectedStatus
  chosenStatuses = []
  @Input() processId = this.data.processId
  @Input() options = this.data.options
  selectedOptions = this.data.options
  filterDateOverview
  monthTypeOverview = new EventEmitter()
  year
  months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ]

  constructor(
    public dialogRef: MatDialogRef<FilterDialogBoxComponent>,
    private httpService: HttpService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit() {
    this.temp = this.data
    this.filterForm = this.formBuilder.group({
      filterInput: this.formBuilder.control('')
    })
    this.filterDate = this.formBuilder.group({
      fromDate: this.formBuilder.control(''),
      toDate: this.formBuilder.control('')
    })
    this.filterForm
      .get('filterInput')
      .valueChanges.pipe(
        debounceTime(500),
        map(value => {
          const obj = {}
          obj[this.data.filteringParameter] = value
          this.filterData.emit(obj)
        })
      )
      .subscribe()
  }

  onDateChange() {
    const obj = {}
    obj[this.data.filteringParameter] = {
      from: moment(this.filterDate.get('fromDate').value).format('YYYY-MM-DD'),
      to: moment(this.filterDate.get('toDate').value).format('YYYY-MM-DD')
    }
    this.filterData.emit(obj)
  }

  statusChange(statuses) {
    this.filterData.emit(statuses)
  }

  onSelectOptionChange(event) {
    const obj = {}
    obj[this.data.filteringParameter] = event.source._value
    this.filterData.emit(obj)
  }

  dateChangeOverviewComponent() {
    this.dialogRef.close()
  }

  chosenMonthHandler(event, dp) {
    this.monthTypeOverview.emit({
      month: this.months[event.getMonth()],
      year: event.getFullYear()
    })
    this.dialogRef.close()
  }
}
