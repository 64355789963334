import {
  Component,
  OnInit,
  Inject,
  EventEmitter,
  Input,
  ViewChild,
  AfterViewInit,
  ElementRef
} from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef, MatDatepicker } from '@angular/material'
import { RecordListComponent } from '../record-list/record-list.component'
import { FormGroup, FormBuilder } from '@angular/forms'
import { debounceTime, tap, switchMap, throttleTime } from 'rxjs/operators'
import { of } from 'rxjs'
import { RecordViewService } from '../record-view/record-view.service'

@Component({
  selector: 'app-process-filter',
  templateUrl: './process-filter.component.html',
  styleUrls: ['./process-filter.component.css']
})
export class ProcessFilterComponent implements OnInit {
  filterForm: FormGroup
  isLoading: boolean = false
  filterList: EventEmitter<any> = new EventEmitter<any>()
  recordsList: any
  compactOptions: any[] = []
  loading: boolean = false
  fromDate: any
  toDate: any

  constructor(
    public dialogRef: MatDialogRef<RecordListComponent>,
    private formBuilder: FormBuilder,
    private recordService: RecordViewService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.recordsList = []
  }

  ngOnInit() {
    this.filterForm = this.formBuilder.group({
      searchInput: this.data.colValue
    })
    if (this.data) {
      this.recordsList = this.data.recordList
    }
    // this.compactOptions = [...new Set(this.data.recordList.map(record => record.details[0][0][this.data.colName]))];
    this.filterForm
      .get('searchInput')
      .valueChanges.pipe(
        debounceTime(500),

        switchMap(value => {
          this.data.colValue = value
          this.loading = true
          this.filterList.emit(this.data)
          return of(null)
        })
      )
      .subscribe()
  }

  setDateFilter() {
    if (this.fromDate && this.toDate) {
      const timeFilter = {
        from: this.fromDate.toISOString(),
        to: this.toDate.toISOString()
      }
      this.data.colValue = timeFilter
      this.filterList.emit(this.data)
    }
  }
}
