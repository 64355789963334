import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  EventEmitter,
  Output,
  Input
} from '@angular/core'
import { FormGroup, FormBuilder, Validators } from '@angular/forms'
import { ActivatedRoute } from '@angular/router'
import { RecordViewService } from '../record-view.service'

@Component({
  selector: 'add-comment',
  templateUrl: './add-comment.component.html',
  styleUrls: ['./add-comment.component.css']
})
export class AddCommentComponent implements OnInit {
  @Input() processId: string
  @Input() recordId: string
  commentForm: FormGroup
  commentLevels: any[] = ['low', 'medium', 'high', 'critical']
  @Output() closeCommentBox = new EventEmitter<any>(null)
  @ViewChild('writeComment', { static: false }) writeComment: ElementRef
  constructor(
    private _formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private recordService: RecordViewService
  ) {}

  ngOnInit() {
    this.commentForm = this._formBuilder.group({
      type: this._formBuilder.control('low', Validators.required),
      message: this._formBuilder.control('', Validators.required)
    })
  }

  ngAfterViewInit() {
    this.writeComment.nativeElement.focus()
  }

  collapseCommentBox() {
    this.closeCommentBox.emit(false)
  }

  addComment() {
    // this.isLoadingResults = true;
    this.recordService
      .addComments(this.processId, this.recordId, this.commentForm.value)
      .subscribe(
        (res: any) => {
          this.closeCommentBox.emit(true)
        },
        err => {}
      )
  }
}
