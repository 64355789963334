import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core'
import { LineItemsModel } from 'src/app/models/line-items-model.model'
import * as _ from 'lodash'
import { FormControl, FormArray, FormGroup } from '@angular/forms'
import { PurchaseModel } from 'src/app/models/purchase-model.model'
import * as _moment from 'moment'
const moment = _moment

@Component({
  selector: 'po-view',
  templateUrl: './po-view.component.html',
  styleUrls: ['./po-view.component.css']
})
export class PoViewComponent implements OnInit {
  edit: boolean = false
  @Input() selectedPos
  @Input() dataSource
  @Output() removeFormEvent = new EventEmitter<any>()
  emptyFormSchema = new PurchaseModel('')
  schema
  customForm
  constructor() {}

  ngOnInit() {}

  closeEdit() {
    this.edit = false
  }

  editEvent() {
    this.edit = true
  }

  editPurchaseOrder(details) {
    let createdAt = moment(new Date()).format('YYYY-MM-DD')
    for (let key in this.dataSource) {
      if (details[key]) {
        this.dataSource[key] = details[key]
      }
    }
    this.edit = false
  }

  close() {
    this.removeFormEvent.emit()
  }
}
