import { Pipe, PipeTransform } from '@angular/core'

@Pipe({ name: 'recordActionFilter' })
export class RecordActionFilter implements PipeTransform {
  transform(actionsArray, selectedStatus) {
    if (!actionsArray) return []

    return actionsArray.filter(action => action.status === selectedStatus)
  }
}
