import { HttpClient } from '@angular/common/http'
import { Injectable, EventEmitter } from '@angular/core'
import { HttpService } from 'src/app/core/services/http.service'
import { Router, ActivatedRoute } from '@angular/router'
import { Observable, Subject, BehaviorSubject } from 'rxjs'
import { NotifierService } from 'angular-notifier'
import { ParamChangeService } from 'src/app/shared/services/param-change.service'
import { environment } from 'src/environments/environment'
import { map } from 'rxjs/operators'
import { SESSION_STORAGE } from 'src/app/app.constant'
import { JwtHelperService } from '@auth0/angular-jwt'
import { MatSnackBar } from '@angular/material'
import { SnackbarService } from 'src/app/snackbar.service'

@Injectable({
  providedIn: 'root'
})
export class AuthService extends HttpService {
  departmentObservable: Observable<any>
  authenticated = new EventEmitter<boolean>()
  login: boolean
  token: string
  loginSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
  loginSubject$ = this.loginSubject.asObservable()
  apiUrl: any
  processType
  processPermission$: Observable<any>
  deptObj: Object = {}
  processObj: Object = {}

  constructor(
    protected httpClient: HttpClient,
    private router: Router,
    private paramChange: ParamChangeService,
    private route: ActivatedRoute,
    public jwtHelper: JwtHelperService,
    private httpService: HttpService,
    public _snackBar: SnackbarService
  ) {
    super(httpClient, _snackBar)
    this.apiUrl = environment.baseURL
    this.registerSubscriptions()

    this.token = localStorage.getItem(SESSION_STORAGE.TOKEN)
    if (this.token) {
      this.loginSubject.next(true)
    }
  }

  loginUser(loginForm) {
    this.post(`${this.apiUrl}/auth/login`, loginForm.value, {})
    return this.post(`${this.apiUrl}/auth/login`, loginForm.value, {})
  }

  registerUser(profileForm) {
    return this.post(`${this.apiUrl}/user/register`, profileForm)
  }

  showNotification(type: string, message: string): void {
    this._snackBar.showSnackbar(message, 'success')
  }

  updateLoginState(bool: boolean) {
    this.loginSubject.next(bool)
  }

  resetLoginState() {
    window.localStorage.clear()
    this.updateLoginState(false)
    this.paramChange.changeProcess(null)
    this.paramChange.changeDept(null)
    this.router.navigate(['login'])
  }

  registerSubscriptions() {
    this.loginSubject$.subscribe(login => {
      if (login) {
        this.departmentObservable = this.post(`${this.apiUrl}/auth/account`, {})

        if (this.departmentObservable) {
          this.processPermission$ = this.departmentObservable.pipe(
            map(processDetails => {
              return processDetails.map(process => ({ ...process }))
            })
          )
          this.processPermission$ = this.processPermission$.pipe(
            map(newProcessDetails => {
              return newProcessDetails.map(newProcess => {
                return { processId: newProcess._id }
              })
            })
          )
        }
        this.departmentObservable = this.departmentObservable.pipe(
          map(links => {
            let departments = []
            links.map(link => {
              this.processObj[link._id] = link.title
              const departmentTitle = link.department.title
              let existingDepartment = departments[departmentTitle]
              const newProcess = {
                _id: link._id,
                title: link.title,
                deptId: link.department._id
              }
              if (existingDepartment) {
                existingDepartment.push(newProcess)
              } else {
                const department = []
                department.push(newProcess)
                this.deptObj[department[0]['deptId']] = departmentTitle
                departments[departmentTitle] = department
              }
            })
            localStorage.setItem(
              SESSION_STORAGE.DEPARTMENT_OBJ,
              JSON.stringify(this.deptObj)
            )
            localStorage.setItem(
              SESSION_STORAGE.PROCESS_OBJ,
              JSON.stringify(this.processObj)
            )
            departments = Object.entries(departments)

            return departments
          })
        )
      } else {
        this.departmentObservable = null
      }
    })
  }

  getLink() {
    return this.departmentObservable
  }

  findProcessbyId(processId) {
    return this.post(`${this.apiUrl}/process/${processId}`, {}).pipe(
      map(value => {
        return {
          title: value.title,
          url: `/department/${value.department._id}/process/${processId}`
        }
      })
    )
  }

  findDeptbyId(deptId) {
    return this.post(`${this.apiUrl}/department/${deptId}`, {}).pipe(
      map(value => {
        return { title: value.title, url: `/department/${deptId}` }
      })
    )
  }

  public isAuthenticated(): boolean {
    const token = localStorage.getItem('token')
    return !this.jwtHelper.isTokenExpired(token)
  }

  sendPasswordResetLink(email) {
    return this.post(`${this.apiUrl}/auth/sendPasswordResetLink`, { email })
  }

  changePassword(body) {
    return this.post(`${this.apiUrl}/auth/changePassword`, body)
  }
}
