export class LineItemsModel {
  'Item Name': {
    value: string
    options: Array<string>
  }
  'Account': string
  'Quantity': string
  'Rate': string
  'Amount': string

  constructor(lineItems) {
    this['Item Name'] = lineItems['Item Name'] || { value: '', options: [] }
    this['Quantity'] = lineItems['Quantity'] || ''
    this['Rate'] = lineItems['Rate'] || ''
    this['Account'] = lineItems['Account'] || ''
    this['Amount'] = lineItems['Amount'] || ''
  }
}
