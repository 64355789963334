import { Component, OnInit, Input } from '@angular/core'
import { FormBuilder } from '@angular/forms'

@Component({
  selector: 'interview-child-form',
  templateUrl: './interview-child-form.component.html',
  styleUrls: ['./interview-child-form.component.css']
})
export class InterviewChildFormComponent implements OnInit {
  @Input() childViewForm
  @Input() form
  controlsObject
  constructor(private fb: FormBuilder) {}

  ngOnInit() {
    this.controlsObject = Object.keys(this.childViewForm.controls).map(
      control => {
        return {
          key: control,
          value: this.childViewForm.controls[control]
        }
      }
    )

    // this.childViewForm = this.fb.group({});
    // this.form.fields.forEach((field) => {
    // 	switch (field.type) {
    // 		case 'address': {
    // 			const addressGroup = this.fb.group({
    // 				lineOne: this.fb.control(''),
    // 				lineTwo: this.fb.control(''),
    // 				addressType: this.fb.control(''),
    // 				city: this.fb.control(''),
    // 				region: this.fb.control(''),
    // 				pincode: this.fb.control(''),
    // 				country: this.fb.control('')
    // 			});

    // 			this.childViewForm.addControl(field.title, addressGroup);
    // 			break;
    // 		}
    // 		default:
    // 			const control = this.fb.control('');
    // 			this.childViewForm.addControl(field.title, control);
    // 	}
    // })
  }
}
