import { ActivatedRoute } from '@angular/router'
import { map } from 'rxjs/operators'
import { Injectable } from '@angular/core'
import { Subject, BehaviorSubject } from 'rxjs'

@Injectable()
export class ParamChangeService {
  processId$
  mySub

  subject: BehaviorSubject<any> = new BehaviorSubject<any>(null)
  subject$ = this.subject.asObservable()

  deptSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null)
  deptSubject$ = this.deptSubject.asObservable()

  deptName: Subject<any> = new Subject()
  deptname$ = this.deptName.asObservable()

  processName: Subject<any> = new Subject()
  processname$ = this.processName.asObservable()

  constructor(private activedRoute: ActivatedRoute) {}

  changeProcess(process) {
    if (process == null) {
      this.subject.next(null)
    } else {
      this.subject.next(process.processId)
    }
  }

  changeDept(dept) {
    if (dept == null) this.deptSubject.next(null)
    else {
      this.deptSubject.next(dept.deptId)
    }
  }

  getProcessId() {
    return this.activedRoute.params.pipe(map((data: any) => data.id))
    // return this.processId$;
  }
}
