import { Component, OnInit, Input } from '@angular/core'
import { FormBuilder, FormControl, FormArray, FormGroup } from '@angular/forms'
import { MatDialog } from '@angular/material'
import { VendorService } from '../vendor-service.service'
import { SESSION_STORAGE } from 'src/app/app.constant'

@Component({
  selector: 'vendor-overview',
  templateUrl: './vendor-overview.component.html',
  styleUrls: ['./vendor-overview.component.css']
})
export class VendorOverviewComponent implements OnInit {
  @Input() detailsForm: FormGroup
  @Input() emptyFormSchema

  user = localStorage.getItem(SESSION_STORAGE.DETAILS)

  constructor(
    private fb: FormBuilder,
    private dialog: MatDialog,
    private vendorService: VendorService
  ) {}

  ngOnInit() {}
}
