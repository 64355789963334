import { Component, OnInit, Inject, EventEmitter, Output } from '@angular/core'
import { FormBuilder, FormGroup, FormControl } from '@angular/forms'
import { HttpService } from 'src/app/core/services/http.service'
import { environment } from '../../../../../environments/environment'
import { HttpClient } from '@angular/common/http'
import { DOCUMENT } from '@angular/common'
import { SnackbarService } from 'src/app/snackbar.service'
import { MatDialog } from '@angular/material'
import { LoadingDialogBoxComponent } from 'src/app/modules/process/process-records/loading-dialog-box-component/loading-dialog-box-component.component'

@Component({
  selector: 'app-push-to-rmp-dialog',
  templateUrl: './push-to-rmp-dialog.component.html',
  styleUrls: ['./push-to-rmp-dialog.component.css']
})
export class PushToRmpDialogComponent implements OnInit {
  pushOrderForm: FormControl
  baseUrl
  @Output() close = new EventEmitter<any>()
  constructor(
    private fb: FormBuilder,
    private httpService: HttpService,
    private http: HttpClient,
    private _snackbar: SnackbarService,
    private dialog: MatDialog,
    @Inject(DOCUMENT) private document: Document
  ) {}

  ngOnInit() {
    this.baseUrl = environment.baseURL
    this.pushOrderForm = this.fb.control('')
  }

  onSubmit() {
    this.openDialog()
    let orderId = this.pushOrderForm.value
    this.http
      .get(`https://rentickle.com/scripts/rmp.php?order_id=${orderId}`)
      .subscribe(
        res => {
          this._snackbar.showSnackbar('Pushed to rmp', 'success')
          this.close.emit()
          this.dialog.closeAll()
        },
        error => {
          this.dialog.closeAll()
        }
      )
  }

  openDialog() {
    const dialogRef = this.dialog.open(LoadingDialogBoxComponent, {
      disableClose: true,
      panelClass: 'loadingDialog'
    })
  }
}
