import { SESSION_STORAGE } from './../../../../app.constant'
import { Observable } from 'rxjs'
import { FirService } from './../fir.service'
import { Component, OnInit, Input, ViewChild, OnDestroy } from '@angular/core'
import {
  MatTableDataSource,
  MatDialog,
  MatPaginator,
  MatSort
} from '@angular/material'
import { Router, ActivatedRoute } from '@angular/router'
import * as moment from 'moment'
import { ProcessFilterComponent } from '../../../../modules/process/process-records/process-filter/process-filter.component'

@Component({
  selector: 'fir-table',
  templateUrl: './fir-table.component.html',
  styleUrls: ['./fir-table.component.css']
})
export class FirTableComponent implements OnInit, OnDestroy {
  firColumns: string[] = [
    'requestId',
    'action',
    'status',
    'priority',
    'raisedBy',
    'assignedTo'
  ]
  displayedColumns: string[] = [
    'view',
    'requestId',
    'action',
    'status',
    'priority',
    'raisedBy',
    'assignedTo'
  ]
  user: any
  firsCount: Observable<any>

  totalLength: Observable<number>
  pageLimit: number[] = [5]
  limit: number = 5
  paginate: any
  filters: any
  initFilters: any[] = []
  filterDone: boolean = false

  firs: MatTableDataSource<any[]> = new MatTableDataSource<any[]>()
  firData: any[] = []
  @Input() firType: string

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator
  @ViewChild(MatSort, { static: false }) sort: MatSort

  constructor(
    public dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
    private firService: FirService
  ) {
    this.user = JSON.parse(localStorage.getItem(SESSION_STORAGE.DETAILS))
  }

  ngOnInit() {
    switch (this.firType) {
      case 'Open':
        this.initFilters.push({ 'assignedTo._id': this.user._id })
        this.initFilters.push({ status: 'open' })
        this.totalLength = this.firService.getOpenFirCounts()
        break
      case 'Forwarded':
        this.initFilters.push({ 'forwardedBy._id': this.user._id })
        this.totalLength = this.firService.getForwardedFirCounts()
        break
      case 'Closed':
        this.initFilters.push({ 'closedBy._id': this.user._id })
        this.initFilters.push({ status: 'closed' })
        this.totalLength = this.firService.getClosedFirCounts()
        break
      default:
        this.totalLength = this.firService.getTotalFirCounts()
    }
    const paginate = { page: 1, perPage: 5 }
    this.getFirs({ filters: this.initFilters, paginate })
  }

  getFirs(filterObject, dialogRef?, clear?) {
    this.firService.getFirList(filterObject).subscribe((firs: any[]) => {
      this.firs = new MatTableDataSource(firs)
      if (dialogRef) {
        dialogRef.close()
        this.filterDone = true
      }
      if (clear) this.filterDone = false
    })
  }

  viewFir(rowData) {
    // this.router.navigate([`reques/${rowData._id}`], { relativeTo: this.route, queryParamsHandling: 'preserve' });
    this.router.navigate([`request/${rowData._id}`])
  }

  getData(row, column) {
    switch (column) {
      case 'requestId':
        return row.firId
      case 'action':
        return row.action.title
      case 'status':
        return row.status
      case 'priority':
        return row.priority
      case 'raisedBy':
        return row.raisedBy.userName
      case 'assignedTo':
        return row.assignedTo.userName
      case 'date':
        return moment(row.createdAt).format('ll')
    }
  }

  getColor(type) {
    if (type == 'open') return 'red'
    else return 'green'
  }

  headerClicked(col, isDate?: boolean) {
    const filterDialogRef = this.dialog.open(ProcessFilterComponent, {
      width: '30%',
      height: '30%',
      data: {
        isDate,
        colName: col,
        recordList: this.firData
      }
    })

    filterDialogRef.componentInstance.filterList.subscribe(data => {
      const newFilter = this.getAppliedFilter(data.colName, data.colValue)
      this.filters = newFilter
      this.getFirs(newFilter, filterDialogRef)
    })
  }

  changePage(event) {
    this.limit = event.pageSize
    this.paginator.pageIndex = event.pageIndex
    const paginate = { page: event.pageIndex + 1, perPage: event.pageSize }
    this.getFirs({ paginate, filters: this.initFilters, ...this.filters })
  }

  getAppliedFilter(colName?, colValue?) {
    const filters = []
    const input = {}
    if (colName) input[colName] = colValue

    switch (this.firType) {
      case 'Open':
        if (colName && colName != 'status') filters.push(input)
        filters.push({ 'assignedTo._id': this.user._id })
        filters.push({ status: 'open' })
        this.totalLength = this.firService.getOpenFirCounts(input)
        break
      case 'Forwarded':
        if (colName) filters.push(input)
        filters.push({ 'forwardedBy._id': this.user._id })
        this.totalLength = this.firService.getForwardedFirCounts(input)
        break
      case 'Closed':
        if (colName && colName != 'status') filters.push(input)
        filters.push({ 'closedBy._id': this.user._id })
        filters.push({ status: 'closed' })
        this.totalLength = this.firService.getClosedFirCounts(input)
        break
      default:
        if (colName) filters.push(input)
        this.totalLength = this.firService.getTotalFirCounts(input)
    }
    return { filters }
  }

  clearFilter() {
    const filters = this.getAppliedFilter()
    const paginate = { page: 1, perPage: 5 }
    this.getFirs({ ...filters, paginate }, null, true)
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.filters = {}
  }
}
