import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'create-record',
  templateUrl: './create-record.component.html',
  styleUrls: ['./create-record.component.css']
})
export class CreateRecordComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
