import { Component, OnInit, EventEmitter, Output } from '@angular/core'
import { FormBuilder, FormGroup, Validators, ValidatorFn } from '@angular/forms'

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {
  changePasswordForm: FormGroup
  password: string = 'password'
  confirmPassword: string = 'password'
  newPassword: string = 'password'
  @Output() closeComponent: EventEmitter<any> = new EventEmitter()

  constructor(private fb: FormBuilder) {}

  ngOnInit() {
    this.changePasswordForm = this.fb.group(
      {
        oldPassword: this.fb.control(''),
        newPassword: this.fb.control(''),
        confirmPassword: this.fb.control('')
      },
      { validator: this.checkPasswords() }
    )
  }

  changeType(password) {
    this[password] = this[password] === 'password' ? 'text' : 'password'
  }

  closeEvent() {
    this.closeComponent.emit('')
  }

  checkPasswords(): ValidatorFn {
    return group => {
      let newPasswordControl = group.get('newPassword')
      let confirmPasswordControl = group.get('confirmPassword')
      if (
        newPasswordControl.dirty &&
        confirmPasswordControl.dirty &&
        newPasswordControl.value != confirmPasswordControl.value
      ) {
        return { mismatchError: true }
      } else {
        return null
      }
    }
  }

  changePassword() {}
}
