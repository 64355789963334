import { Validators } from '@angular/forms'
import { Component, OnInit, Inject, EventEmitter, Output } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material'
import { FormGroup, FormArray, FormBuilder, FormControl } from '@angular/forms'
import { HttpClient, HttpErrorResponse } from '@angular/common/http'
import { environment } from 'src/environments/environment'
import { RecordViewService } from '../../../process-records/record-view/record-view.service'
import { HttpService } from 'src/app/core/services/http.service'

export interface FormDialog {
  processId: string
  fieldtypes: any[]
  formLength: number
}

@Component({
  selector: 'app-process-form-dialog',
  templateUrl: './process-form-dialog.component.html',
  styleUrls: ['./process-form-dialog.component.css']
})
export class ProcessFormDialogComponent implements OnInit {
  processForm: FormGroup
  fields: FormArray
  fieldtypes: any[] = []
  @Output() addForm: EventEmitter<any> = new EventEmitter()
  private baseUrl: string = null
  private token: string
  selectedFieldType: string
  internalSelectedFieldType: string
  listSelectedFieldType: string
  listSelectedGroupFieldType: string

  constructor(
    public dialogRef: MatDialogRef<ProcessFormDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: FormDialog,
    private httpClient: HttpClient,
    private formBuilder: FormBuilder,
    private recordService: RecordViewService,
    private httpService: HttpService
  ) {
    this.baseUrl = environment.baseURL
    this.token = JSON.parse(localStorage.getItem('x-auth-token'))
  }

  ngOnInit() {
    this.fieldtypes = this.data.fieldtypes
    // create reactive form on initialisation
    this.processForm = this.formBuilder.group({
      formData: this.formBuilder.group({
        title: ['', Validators.required],
        index: new FormControl(this.data.formLength)
      }),
      fields: this.formBuilder.array([])
    })
  }

  createField(type: string): FormGroup {
    return this.formBuilder.group({
      title: ['', Validators.required],
      description: ['', Validators.required],
      type: [type, Validators.required],
      index: ['', Validators.required],
      required: ['', Validators.required],
      readonly: ['', Validators.required]
    })
  }

  createGroupField(): FormGroup {
    return this.formBuilder.group({
      title: ['', Validators.required],
      description: ['', Validators.required],
      type: ['group', Validators.required],
      index: ['', Validators.required],
      required: ['', Validators.required],
      fields: this.formBuilder.array([])
    })
  }

  createListField(type: string): FormGroup {
    return this.formBuilder.group({
      title: ['', Validators.required],
      description: ['', Validators.required],
      type: ['list', Validators.required],
      index: ['', Validators.required],
      required: ['', Validators.required],
      entity: type == 'group' ? this.createGroupField() : this.createField(type)
    })
  }

  createSelectField(type): FormGroup {
    return this.formBuilder.group({
      title: ['', Validators.required],
      description: ['', Validators.required],
      type: [type, Validators.required],
      index: ['', Validators.required],
      required: ['', Validators.required],
      options: this.formBuilder.array([])
    })
  }

  getFields(): FormArray {
    return this.processForm.get('fields') as FormArray
  }

  getFormData(): FormGroup {
    return this.processForm.get('formData') as FormGroup
  }

  getInternalFields(index): FormArray {
    const arrayControl = this.getFields().at(index) as FormGroup
    return arrayControl.get('fields') as FormArray
  }

  getInternalGroup(outerIndex, internalFieldIndex): FormGroup {
    const arrayControl = this.getFields().at(outerIndex) as FormGroup
    const internalFields = arrayControl.get('fields') as FormArray
    return internalFields.at(internalFieldIndex) as FormGroup
  }

  getInternalFieldControls(form: FormGroup): FormArray {
    return form.get('fields') as FormArray
  }

  getInternalFieldControlAt(form: FormGroup, index: number): FormGroup {
    return this.getInternalFieldControls(form).at(index) as FormGroup
  }

  getInternalList(outerIndex): FormGroup {
    const arrayGroup = this.getFields().at(outerIndex) as FormGroup
    return arrayGroup.get('entity') as FormGroup
  }

  getListFields(outerIndex): FormArray {
    return this.getInternalList(outerIndex).get('fields') as FormArray
  }

  getListFieldsGroup(outerIndex, index): FormGroup {
    return this.getListFields(outerIndex).at(index) as FormGroup
  }

  getSelectOptionsArray(index): FormArray {
    return this.getFields()
      .at(index)
      .get('options') as FormArray
  }

  getSelectFormGroup(innerIndex: number, outerIndex: number): FormGroup {
    return this.getSelectOptionsArray(innerIndex).at(outerIndex) as FormGroup
  }

  onListAddGroupField(index) {
    this.getListFields(index).push(
      this.createField(this.listSelectedGroupFieldType)
    )
  }

  getOptionsArrayFromGroup(form: FormGroup): FormArray {
    return form.get('options') as FormArray
  }

  getSelectOptionsGroup(form: FormGroup, index: number): FormGroup {
    return this.getOptionsArrayFromGroup(form).at(index) as FormGroup
  }

  onInternalAddField(index): void {
    if (this.internalSelectedFieldType == 'select') {
      const newSelectGroup: FormGroup = this.createSelectField('select')
      const optionsArray = newSelectGroup.get('options') as FormArray
      optionsArray.push(
        this.formBuilder.group({
          title: this.formBuilder.control('', Validators.required),
          value: this.formBuilder.control('', Validators.required)
        })
      )
      this.getInternalFields(index).push(newSelectGroup)
    } else if (this.internalSelectedFieldType == 'multiSelect') {
      const newSelectGroup: FormGroup = this.createSelectField('multiSelect')
      const optionsArray = newSelectGroup.get('options') as FormArray
      optionsArray.push(
        this.formBuilder.group({
          title: this.formBuilder.control('', Validators.required),
          value: this.formBuilder.control('', Validators.required)
        })
      )
      this.getInternalFields(index).push(newSelectGroup)
    } else {
      this.getInternalFields(index).push(
        this.createField(this.internalSelectedFieldType)
      )
    }
  }

  onAddingOptionInGroupSelect(form: FormGroup) {
    this.getOptionsArrayFromGroup(form).push(
      this.formBuilder.group({
        title: this.formBuilder.control('', Validators.required),
        value: this.formBuilder.control('', Validators.required)
      })
    )
  }

  onSelectOptionAdd(index: number): void {
    const arrayGroup = this.getFields().at(index) as FormGroup
    const selectArray = arrayGroup.get('options') as FormArray
    selectArray.push(
      this.formBuilder.group({
        title: this.formBuilder.control('', Validators.required),
        value: this.formBuilder.control('', Validators.required)
      })
    )
  }

  onAddField(): void {
    if (this.selectedFieldType == 'group') {
      this.getFields().push(this.createGroupField())
    } else if (this.selectedFieldType == 'list') {
      this.getFields().push(this.createListField(this.listSelectedFieldType))
    } else if (this.selectedFieldType == 'select') {
      this.getFields().push(this.createSelectField('select'))
      const index = this.getFields().length - 1
      this.onSelectOptionAdd(index)
    } else if (this.selectedFieldType == 'multiSelect') {
      this.getFields().push(this.createSelectField('multiSelect'))
      const index = this.getFields().length - 1
      this.onSelectOptionAdd(index)
    } else {
      this.getFields().push(this.createField(this.selectedFieldType))
    }
  }

  removeSelectOptionFromGroup(form: FormGroup, indexToRemove: number): void {
    this.getOptionsArrayFromGroup(form).removeAt(indexToRemove)
  }

  removeInternalField(fieldIndex: number, internalIndex: number): void {
    const field = this.getInternalFields(fieldIndex)
    field.removeAt(internalIndex)
  }

  removeSelectOption(innerIndex: number, outerIndex: number): void {
    const fields = this.getSelectOptionsArray(innerIndex)
    fields.removeAt(outerIndex)
  }

  removeListField(fieldIndex: number, internalIndex: number): void {
    const field = this.getListFields(fieldIndex)
    if (field) field.removeAt(internalIndex)
  }

  removeField(index: number): void {
    const field = this.getFields()
    field.removeAt(index)
  }

  onClear(): void {
    for (let i = this.getFields().length - 1; i >= 0; i--) {
      this.getFields().removeAt(i)
    }
    this.processForm.reset({
      formData: {
        title: ''
      }
    })
    this.processForm
      .get('formData')
      .setValue({ title: '', index: this.data.formLength })
  }

  onNoClick(): void {
    this.dialogRef.close()
  }

  onSubmit() {
    const formData = this.processForm.value
    this.httpService
      .post(
        `${this.baseUrl}/process/${this.data.processId}/createform`,
        formData
      )
      .subscribe(
        res => {
          setTimeout(() => {
            this.onNoClick()
          }, 1000)
          this.addForm.emit(res['data'])
          this.recordService.showNotification('success', res['msg'])
        },
        (error: HttpErrorResponse) => {
          this.recordService.showNotification('error', error.error)
        }
      )
  }

  changeSelected(event) {
    this.selectedFieldType = event.value
  }

  changeInternalSelected(event) {
    this.internalSelectedFieldType = event.value
  }

  changeListEntitySelection(event) {
    this.listSelectedFieldType = event.value
  }

  changeListEntityTypeSelection(event) {
    this.listSelectedGroupFieldType = event.value
  }

  isFieldPrimitive(value: string) {
    return (
      value != 'list' &&
      value != 'group' &&
      value != 'select' &&
      value != 'multiSelect'
    )
  }
}
