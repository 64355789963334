import { Component, OnInit } from '@angular/core'
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormArray,
  Validators
} from '@angular/forms'
import { Observable } from 'rxjs'
import { ModulesService } from '../../modules.service'
import { HttpService } from 'src/app/core/services/http.service'
import { environment } from '../../../../environments/environment'
import { SelectionModel } from '@angular/cdk/collections'
import { BarcodeFormat, Result } from '@zxing/library'
import { MatDialog } from '@angular/material'
import { BarcodeScannerComponent } from './barcode-scanner/barcode-scanner.component'
import { debounceTime, map, startWith } from 'rxjs/operators'
import { SnackbarService } from 'src/app/snackbar.service'
import { LoadingDialogBoxComponent } from '../../process/process-records/loading-dialog-box-component/loading-dialog-box-component.component'
import * as _moment from 'moment'

@Component({
  selector: 'app-out-for-delivery',
  templateUrl: './out-for-delivery.component.html',
  styleUrls: ['./out-for-delivery.component.css']
})
export class OutForDeliveryComponent implements OnInit {
  isAllItemsSelected = true
  baseUrl = environment.baseURL
  orderId
  open: boolean = false
  orderIdControl: FormControl
  orderItems$: Observable<any>
  orderItems: Array<any>
  driverForm: FormGroup
  itemBarcodeArray: Array<any> = []
  selectedOptions
  itemsForm: FormGroup
  barcode: FormControl
  barcodeArray = []
  orderDetailsForm: FormArray
  selection = new SelectionModel<any>(true, [])
  openScanner: boolean = false
  currentBarcodeIndex
  barcodeItemIndex: any
  barcodePkgItemIndex: any
  transporterName = ''
  driverNumber = ''
  vehicleOptions: Observable<any>
  filteredVehicles: Observable<any>
  driverOptions: Observable<any>
  filteredDrivers: Observable<any>
  helper = 0
  recordId: string
  dateControl: FormControl

  constructor(
    private fb: FormBuilder,
    private modulesService: ModulesService,
    private httpService: HttpService,
    public dialog: MatDialog,
    private _snackbar: SnackbarService
  ) {}

  ngOnInit() {
    this.orderIdControl = this.fb.control('')
    this.dateControl = this.fb.control('')
    this.driverForm = this.fb.group({
      transporterName: this.fb.control('', Validators.required),
      vehicleNumber: this.fb.control('', Validators.required),
      driverName: this.fb.control('', Validators.required),
      driverNumber: this.fb.control('', Validators.required),
      helpers: this.fb.array([])
    })

    this.filteredVehicles = this.httpService
      .post(
        `${this.baseUrl}/records/${environment.config.shipment.vehicle}/list`
      )
      .pipe(
        map(data => {
          return data.map(elem => {
            return {
              name: elem.details[0][0].name,
              vehicleNumber: elem.details[0][0].vehicleNumber
            }
          })
        })
      )

    this.driverForm
      .get('vehicleNumber')
      .valueChanges.pipe(
        debounceTime(500),
        map(value => {
          if (value) {
            let body = {
              filters: [{ vehicleNumber: value }]
            }
            this.filteredVehicles = this.httpService
              .post(
                `${this.baseUrl}/records/${environment.config.shipment.vehicle}/list`,
                body
              )
              .pipe(
                map(data => {
                  return data.map(elem => {
                    return {
                      name: elem.details[0][0].name,
                      vehicleNumber: elem.details[0][0].vehicleNumber
                    }
                  })
                })
              )
          } else {
            this.filteredVehicles = this.httpService
              .post(
                `${this.baseUrl}/records/${environment.config.shipment.vehicle}/list`
              )
              .pipe(
                map(data => {
                  return data.map(elem => {
                    return {
                      name: elem.details[0][0].name,
                      vehicleNumber: elem.details[0][0].vehicleNumber
                    }
                  })
                })
              )
          }
        })
      )
      .subscribe()

    this.filteredDrivers = this.httpService
      .post(
        `${this.baseUrl}/records/${environment.config.shipment.driver}/list`
      )
      .pipe(
        map(data => {
          return data.map(elem => {
            return {
              name: elem.details[0][0].name,
              phone: elem.details[0][0].phone,
              _id: elem._id
            }
          })
        })
      )

    this.driverForm
      .get('driverName')
      .valueChanges.pipe(
        debounceTime(500),
        map(value => {
          if (value) {
            let body = {
              filters: [{ name: value }]
            }
            this.filteredDrivers = this.httpService
              .post(
                `${this.baseUrl}/records/${environment.config.shipment.driver}/list`,
                body
              )
              .pipe(
                map(data => {
                  return data.map(elem => {
                    return {
                      name: elem.details[0][0].name,
                      phone: elem.details[0][0].phone,
                      _id: elem._id
                    }
                  })
                })
              )
          } else {
            this.filteredDrivers = this.httpService
              .post(
                `${this.baseUrl}/records/${environment.config.shipment.driver}/list`
              )
              .pipe(
                map(data => {
                  return data.map(elem => {
                    return {
                      name: elem.details[0][0].name,
                      phone: elem.details[0][0].phone,
                      driverNumber: elem.details[0][0].driverNumber,
                      _id: elem._id
                    }
                  })
                })
              )
          }
        })
      )
      .subscribe()
  }

  submitForm() {
    this.selection.clear()
    this.dateControl.setValue('')
    this.orderId = this.orderIdControl.value
    this.orderItems$ = this.modulesService.getItemsByOrderId(this.orderId)
    this.orderItems$.subscribe(data => {
      if (data.status != 'pendingForDelivery') {
        this.open = false
        this._snackbar.showSnackbar(
          'This order id does not have status pending for delivery.',
          'error'
        )
        return
      }
      this.open = true
      this.orderItems = data.items

      this.recordId = data.recordId
      this.orderItems = this.orderItems.reduce((acc, item) => {
        if (item.type === 'simple') {
          if (item.status == 'invoiced') {
            acc.push(item)
          }
        } else {
          let pkgItemsToBeDelivered = item.packageItems.filter(
            innerItem => innerItem.status === 'invoiced'
          )
          if (!pkgItemsToBeDelivered.length) {
            return acc
          }
          pkgItemsToBeDelivered = pkgItemsToBeDelivered.map(innerItem => {
            return {
              _id: item._id,
              ...innerItem
            }
          })
          acc.push({
            ...item,
            packageItems: pkgItemsToBeDelivered,
            barcode:
              item.metaInfo && item.metaInfo.barcode
                ? item.metaInfo.barcode
                : ''
          })
        }
        return acc
      }, [])

      this.orderItems.forEach(node => {
        this.selection.select(node)
        if (node.packageItems) {
          node.packageItems.forEach(sub => this.selection.select(sub))
        }
      })
    })
  }

  openScannerEvent(i, j = undefined) {
    this.openScanner = false
    this.openScanner = true
    let dialogRef = this.dialog.open(BarcodeScannerComponent, {
      width: '70%'
    })
    dialogRef.componentInstance.result.subscribe(resultString => {
      this.dialog.closeAll()
      if (j == undefined) {
        this.orderItems[i].barcode = resultString
      } else {
        this.orderItems[i].packageItems[j].barcode = resultString
      }
    })
  }

  barcodeChanged(barcode, itemIndex, pkgitemIndex = undefined) {
    if (pkgitemIndex >= 0) {
      this.orderItems[itemIndex].packageItems[pkgitemIndex].barcode = barcode
    } else {
      this.orderItems[itemIndex].barcode = barcode
    }
  }

  addHelper() {
    let form = this.fb.group({
      helperName: this.fb.control(''),
      helperNumber: this.fb.control('')
    })
    ;(<FormArray>this.driverForm.get('helpers')).push(form)
  }

  removeHelper(event, index) {
    ;(<FormArray>this.driverForm.get('helpers')).controls.splice(index, 1)
    ;(<FormArray>this.driverForm.get('helpers')).value.splice(index, 1)
  }

  onCheckboxClicked(event) {
    if (event.checked) {
      this.isAllItemsSelected = true
      this.orderItems.forEach(elem => {
        this.selection.select(elem)
        elem.packageItems.forEach(subNode => {
          this.selection.select(subNode)
        })
      })
    } else {
      this.isAllItemsSelected = false
      this.selection.clear()
    }
  }

  masterToggle(node, item = undefined) {
    this.selection.toggle(node)
    if (node.packageItems) {
      if (this.selection.isSelected(node)) {
        node.packageItems.forEach(subNode => {
          this.selection.select(subNode)
        })
      } else {
        node.packageItems.forEach(subNode => {
          this.selection.deselect(subNode)
        })
      }
    }
    if (item) {
      let allChildSelected = item.packageItems.every(elem =>
        this.selection.isSelected(elem)
      )
      if (allChildSelected) {
        this.selection.select(item)
      } else {
        this.selection.deselect(item)
      }
    }
    this.isAllItemsSelected = this.orderItems.every(elem =>
      this.selection.isSelected(elem)
    )
  }

  submitDeliveryDetails() {
    let selectedItems = this.selection.selected.filter(
      item => item.type != 'package'
    )
    let containsBarcode = selectedItems.filter(item => item.barcode)
    if (selectedItems.length != containsBarcode.length) {
      this._snackbar.showSnackbar(
        'Barcode is required for the selected item(s).',
        'error'
      )
      return
    }
    const body = {}
    let items = []
    this.orderItems.forEach(item => {
      if (item.type === 'package') {
        let childIds = this.selection.selected.filter(
          elem => elem._id === item._id && !elem.childProductId
        )
        if (childIds.length) {
          childIds = childIds.map(child => {
            return {
              productId: child.productId,
              barcode: child.barcode,
              name: child.name
            }
          })
          items.push({
            _id: item._id,
            type: 'package',
            barcode: childIds,
            name: item.name
          })
        }
      } else {
        let elem = this.selection.selected.find(
          selectedItem => selectedItem._id === item._id
        )
        if (elem) {
          items.push({
            _id: item._id,
            type: 'simple',
            barcode: item.barcode,
            name: item.name
          })
        }
      }
    })
    body['itemInfo'] = items
    body['type'] = 'delivery'
    body['reference'] = this.orderId
    body['plannedDate'] = this.dateControl.value
    let form = this.driverForm.value
    form.helpers = form.helpers.map(helper => {
      return {
        ...helper,
        helperName: helper.helperName.name
      }
    })
    body['shipmentInfo'] = {
      driverName: form.driverName.name,
      driverNumber: form.driverNumber,
      helpers: form.helpers,
      transporterName: form.transporterName,
      vehicleNumber: form.vehicleNumber.vehicleNumber,
      _id: form.driverName._id
    }
    body['orderId'] = this.orderId
    body['processId'] = environment.config.sales.order
    body['recordId'] = this.recordId

    this.openDialog()
    this.httpService
      .post(`${this.baseUrl}/dept/sales/outfordelivery`, body)
      .subscribe(
        data => {
          this.open = false
          this.orderIdControl.setValue('')
          this.dateControl.setValue('')
          this.dialog.closeAll()
          this._snackbar.showSnackbar(data.msg, 'success')
          this.driverForm.reset()
        },
        error => {
          this.dialog.closeAll()
          this._snackbar.showSnackbar(error.msg, 'error')
        }
      )
  }

  openDialog() {
    const dialogRef = this.dialog.open(LoadingDialogBoxComponent, {
      disableClose: true,
      panelClass: 'loadingDialog'
    })
  }

  displayFn(option): string {
    return option && option.name ? option.name : ''
  }

  displayFnVehicle(option): string {
    return option && option.vehicleNumber ? option.vehicleNumber : ''
  }

  vehicleSelected(value) {
    this.driverForm.get('transporterName').patchValue(value.name)
  }

  driverSelected(value) {
    this.driverForm.get('driverNumber').patchValue(value.phone)
  }

  changeDateFormat(event) {
    const date = _moment(event.value)
    if (date.isValid()) this.dateControl.setValue(date.format('YYYY-MM-DD'))
  }
}
