import { SESSION_STORAGE } from '../../../../app.constant'
import { HttpErrorResponse } from '@angular/common/http'
import { Component, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop'
import { MatDialog, MatSnackBar } from '@angular/material'
import { StatusCreateDialogComponent } from './status-create-dialog/status-create-dialog.component'
import { environment } from 'src/environments/environment'
import { RecordViewService } from '../../process-records/record-view/record-view.service'
import { HttpService } from 'src/app/core/services/http.service'

export interface State<T> {
  _id: string
  title: string
  code: string
  index: number
  statuses: StateStatus<T>[]
}

export interface StateStatus<T> {
  _id: string
  title: string
  code: string
  index: string
}

export interface StateNode<T> {
  value: State<T>
  next?: StateNode<T>
}

export class StateLinkedList<T> {
  private head: StateNode<T> = null
  private tail: StateNode<T> = null;

  *[Symbol.iterator]() {
    let node = this.head

    while (node !== null) {
      yield node.value
      node = node.next
    }
  }

  public getState(stateId: string): State<T> {
    let state: State<T> = null

    for (let node of this) if (node._id == stateId) state = node

    return state
  }

  public append = (value: State<T>): StateLinkedList<T> => {
    const node = this.forgeNode(value)

    if (this.isEmpty()) {
      this.head = node
      this.tail = node
      return this
    }

    this.appendToTheEndOfTheList(node)
    return this
  }

  public insert = (value: State<T>): StateLinkedList<T> => {
    const node = this.forgeNode(value)
    node.next = this.head
    this.head = node

    if (!this.tail) {
      this.tail = node
    }

    return this
  }

  public add = (newState: State<T>): StateLinkedList<T> => {
    if (this.isEmpty()) return this.append(newState)

    let curr = this.head
    let prev = this.head

    while (curr) {
      if (newState.index < curr.value.index) {
        this.insertAfter(prev, newState)
        break
      } else if (curr === this.tail) {
        this.append(newState)
        break
      } else {
        prev = curr
        curr = curr.next
      }
    }

    return this
  }

  public addStatus = (
    stateId: string,
    status: StateStatus<T>
  ): StateLinkedList<T> => {
    let curr = this.head

    while (curr) {
      if (curr.value._id == stateId) {
        curr.value.statuses.push(status)
        break
      }

      curr = curr.next
    }

    return this
  }

  private insertAfter = (
    node: StateNode<T>,
    value: State<T>
  ): StateLinkedList<T> => {
    const newNode = this.forgeNode(value)
    if (node === this.head) {
      const temp = this.head
      this.head = newNode
      newNode.next = temp
    } else {
      const temp = node.next
      node.next = newNode
      newNode.next = temp
    }

    return this
  }

  public isEmpty = () => !this.head

  public toArray = (): State<T>[] => {
    const result: State<T>[] = []
    let node = this.head
    while (node) {
      result.push(node.value)
      node = node.next
    }
    return result
  }

  public fromArray = (values: State<T>[]): StateLinkedList<T> => {
    values.forEach(v => this.append(v))
    return this
  }

  private appendToTheEndOfTheList = (node: StateNode<T>) => {
    this.tail.next = node
    this.tail = node
  }

  private forgeNode = (value: State<T>): StateNode<T> => {
    return { value, next: null }
  }
}

@Component({
  selector: 'app-process-state',
  templateUrl: './process-state.component.html',
  styleUrls: ['./process-state.component.css']
})
export class ProcessStateComponent implements OnInit {
  states: any[] = []
  baseUrl: string = null
  processId: string = null
  step = 0
  stateLinkedList: StateLinkedList<any>
  private token: string

  constructor(
    private httpService: HttpService,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private recordService: RecordViewService
  ) {
    this.baseUrl = environment.baseURL
    this.token = localStorage.getItem(SESSION_STORAGE.TOKEN)
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      if (params) this.processId = params['processId']
    })

    this.httpService
      .post(`${this.baseUrl}/process/${this.processId}/states`, {})
      .subscribe((res: []) => {
        this.buildStateConfiguration(res)
      })
  }

  private buildStateConfiguration(data: any[]) {
    this.stateLinkedList = new StateLinkedList()

    data.map(stateData => {
      const newState: State<any> = {
        _id: stateData._id,
        title: stateData.title,
        code: stateData.code,
        index: stateData.index,
        statuses: []
      }

      stateData.statuses.map(status => {
        const newStateStatus: StateStatus<any> = {
          _id: status._id,
          title: status.title,
          index: status.index,
          code: status.code
        }

        newState.statuses.push(newStateStatus)
      })

      this.stateLinkedList.add(newState)
    })

    this.states = this.stateLinkedList.toArray()
  }

  dropStatusEvent(data) {
    const dragEvent = data.event
    const state = this.stateLinkedList.getState(data.stateId)
    const statuses: any[] = state.statuses
    const { currentIndex, previousIndex } = dragEvent

    this.correctIndex(statuses, previousIndex, currentIndex)

    this.httpService
      .post(
        `${this.baseUrl}/process/${this.processId}/state/${state._id}/statuses`,
        statuses
      )
      .subscribe(res => {
        this.recordService.showNotification('success', res['msg'])
      }),
      (err: HttpErrorResponse) => {
        this.recordService.showNotification('success', err.error.message)
      }
  }

  dropStateEvent(dragEvent) {
    const { currentIndex, previousIndex } = dragEvent

    this.correctIndex(this.states, previousIndex, currentIndex)

    this.httpService
      .put(
        `${this.baseUrl}/process/${this.processId}/updatestates`,
        this.states
      )
      .subscribe(res => {
        this.recordService.showNotification('success', res['msg'])
      }),
      (err: HttpErrorResponse) => {
        this.recordService.showNotification('success', err.error.message)
      }
  }

  private correctIndex(
    arr: any[],
    previousIndex: number,
    currentIndex: number
  ): void {
    moveItemInArray(arr, previousIndex, currentIndex)

    arr.map((item, index) => {
      if (currentIndex < previousIndex) {
        if (index >= currentIndex && index <= previousIndex) {
          if (index == currentIndex) item.index = index
          else item.index += 1
        }
      } else if (currentIndex > previousIndex) {
        if (index <= currentIndex && index >= previousIndex) {
          if (index == currentIndex) item.index = index
          else item.index -= 1
        }
      }
    })
  }

  addState() {
    const dialogRef = this.dialog.open(StatusCreateDialogComponent, {
      width: '300px',
      data: {
        index: this.states.length,
        title: 'Add New State',
        type: 'state'
      }
    })

    dialogRef.componentInstance.addStateEvent.subscribe(data => {
      this.httpService
        .post(`${this.baseUrl}/process/${this.processId}/addstate`, data)
        .subscribe(
          (res: any) => {
            const newState: State<any> = {
              _id: res._id,
              title: res.title,
              code: res.code,
              index: res.index,
              statuses: []
            }

            this.stateLinkedList.add(newState)
            this.states = this.stateLinkedList.toArray()
            dialogRef.componentInstance.handleResponse()
          },
          (err: HttpErrorResponse) => err
        )
    })
  }

  addStatus(stateId) {
    const [state] = this.states.filter(state => state._id == stateId)

    const dialogRef = this.dialog.open(StatusCreateDialogComponent, {
      width: '300px',
      height: '50%',
      data: {
        index: state.statuses.length,
        title: 'Add New Status',
        type: 'status'
      }
    })

    dialogRef.componentInstance.addStatusEvent.subscribe(data => {
      this.httpService
        .post(
          `${this.baseUrl}/process/${this.processId}/state/${stateId}/addstatus`,
          data
        )
        .subscribe(
          (res: any) => {
            const newStateStatus: StateStatus<any> = {
              _id: res._id,
              title: res.title,
              code: res.code,
              index: res.index
            }

            this.stateLinkedList.addStatus(stateId, newStateStatus)
            this.states = this.stateLinkedList.toArray()
            dialogRef.componentInstance.handleResponse()
          },
          (err: HttpErrorResponse) => console.log(err)
        )
    })
  }
}
