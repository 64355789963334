import { Component, OnInit, Inject } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material'
import { FileFieldComponent } from '../field/file-field/file-field.component'

export interface FileViewData {
  title: string
  fileName: string
  fileUrl: string
  password: string
  multiple?: boolean
}

@Component({
  selector: 'app-file-view-dialog',
  templateUrl: './file-view-dialog.component.html',
  styleUrls: ['./file-view-dialog.component.css']
})
export class FileViewDialogComponent implements OnInit {
  fileName: string
  title: string
  password: string
  isLoading = false
  fileUrl: any = []
  srcObject: any
  multiple: boolean = false

  constructor(
    public dialogRef: MatDialogRef<FileFieldComponent>,
    @Inject(MAT_DIALOG_DATA) public data: FileViewData
  ) {}

  ngOnInit() {
    const { title, fileName, fileUrl, password, multiple } = this.data
    this.title = title
    this.fileName = fileName
    this.password = password
    this.fileUrl = fileUrl
    this.srcObject = { src: this.fileUrl, password: this.password }
    this.multiple = multiple
  }
}
