import { SESSION_STORAGE } from 'src/app/app.constant'
import { Component, OnInit, Inject, Input } from '@angular/core'
import { Router, ActivatedRoute } from '@angular/router'
import { HttpErrorResponse } from '@angular/common/http'
import { MatDialog, MatSnackBar } from '@angular/material'
import { TeamDialogComponent } from './team-dialog/team-dialog.component'
import { TeamCreateDialogComponent } from './team-create-dialog/team-create-dialog.component'
import { environment } from 'src/environments/environment'
import { RecordViewService } from '../../process-records/record-view/record-view.service'
import { HttpService } from 'src/app/core/services/http.service'
import { SnackbarService } from 'src/app/snackbar.service'
import { ConfirmationDialogComponent } from '../../process-records/confirmation-dialog/confirmation-dialog.component'
import { ProcessRecordsService } from '../../process-records/process-records.service'

@Component({
  selector: 'app-process-team',
  templateUrl: './process-team.component.html',
  styleUrls: ['./process-team.component.css']
})
export class ProcessTeamComponent implements OnInit {
  teams: any[] = []
  actions: any[] = []
  widgets: any[] = []
  users: any[] = []
  userInfo: any[] = []
  private processId: string = null
  private baseUrl: string = null
  private token: string

  constructor(
    private httpService: HttpService,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private recordService: RecordViewService,
    private _snackbar: SnackbarService,
    private processRecordservice: ProcessRecordsService
  ) {
    this.baseUrl = environment.baseURL
    this.token = localStorage.getItem(SESSION_STORAGE.TOKEN)
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      if (params) this.processId = params['processId']
    })

    this.httpService
      .post(`${this.baseUrl}/process/${this.processId}/teams`, {})
      .subscribe((res: any[]) => {
        this.teams = res
        this.teams.forEach(team => {
          team.users.forEach(user => {
            if (!this.userInfo[user.userName]) {
              this.userInfo[user.userName] = [team.title]
            } else {
              this.userInfo[user.userName].push(team.title)
            }
          })
        })
      })

    this.httpService
      .post(`${this.baseUrl}/process/${this.processId}/actions`, {})
      .subscribe((res: any[]) => {
        this.actions = res
      })

    this.httpService
      .post(`${this.baseUrl}/user/list`, {})
      .subscribe((res: any[]) => {
        this.users = res
      })

    this.recordService
      .getProcessWidgets(this.processId)
      .subscribe((res: any[]) => {
        this.widgets = res
      })
  }

  addNewTeam(): void {
    const dialogRef = this.dialog.open(TeamCreateDialogComponent, {
      width: '900px',
      data: {
        actions: this.actions,
        widgets: this.widgets
      }
    })
    dialogRef.componentInstance.addTeamEvent.subscribe(team => {
      this.processRecordservice.setLoading(true)

      this.httpService
        .post(`${this.baseUrl}/teams/${this.processId}/addteam`, team)
        .subscribe(res => {
          this.processRecordservice.setLoading(false)
          this.teams.push(res['data'])
          this._snackbar.showSnackbar('Team successfully created.', 'success')
          this.dialog.closeAll()
        }),
        (error: HttpErrorResponse) => {
          this._snackbar.showSnackbar(error.error.msg, 'error')
          this.dialog.closeAll()
        }
    })
  }

  openDialog(teamId): void {
    const [team] = this.teams.filter(team => team._id === teamId)
    const dialogRef = this.dialog.open(TeamDialogComponent, {
      width: '900px',
      data: {
        processId: this.processId,
        team: team,
        actions: this.actions,
        users: this.users,
        widgets: this.widgets,
        userInfo: this.userInfo,
        teams: this.teams
      }
    })

    dialogRef.componentInstance.removedAction.subscribe(actionId => {
      const oldData = dialogRef.componentInstance.data
      const actions = oldData.team.actions
      let index = -1
      let transferAction = {}
      actions.map((teamAction, i) => {
        if (teamAction._id == actionId) {
          index = i
          transferAction = teamAction
        }
      })

      if (index > -1) {
        actions.splice(index, 1)
      }

      dialogRef.componentInstance.restActions.unshift(transferAction)
    })

    dialogRef.componentInstance.addedAction.subscribe(actionId => {
      const actions = dialogRef.componentInstance.restActions

      let index = -1
      let transferAction = {}
      actions.map((teamAction, i) => {
        if (teamAction._id == actionId) {
          index = i
          transferAction = teamAction
        }
      })

      if (index > -1) {
        actions.splice(index, 1)
      }

      dialogRef.componentInstance.data.team.actions.push(transferAction)
    })

    /* widget add or remove */

    dialogRef.componentInstance.addedWidget.subscribe(widgetId => {
      const widgets = dialogRef.componentInstance.restWidgets

      let index = -1
      let transferWidget = {}
      widgets.map((teamWidget, i) => {
        if (teamWidget._id == widgetId) {
          index = i
          transferWidget = teamWidget
        }
      })

      if (index > -1) {
        widgets.splice(index, 1)
      }

      dialogRef.componentInstance.data.team.widgets.push(transferWidget)
    })

    dialogRef.componentInstance.removedWidget.subscribe(widgetId => {
      const oldData = dialogRef.componentInstance.data
      const widgets = oldData.team.widgets
      let index = -1
      let transferWidget = {}
      widgets.map((teamWidget, i) => {
        if (teamWidget._id == widgetId) {
          index = i
          transferWidget = teamWidget
        }
      })

      if (index > -1) {
        widgets.splice(index, 1)
      }

      dialogRef.componentInstance.restWidgets.unshift(transferWidget)
    })

    /* widget add or remove end */

    dialogRef.componentInstance.removedPermission.subscribe(permission => {
      const oldData = dialogRef.componentInstance.data
      const permissions = oldData.team.permissions

      let index = -1
      permissions.map((teamPermission, i) => {
        if (teamPermission == permission) {
          index = i
        }
      })
      if (index > -1) {
        dialogRef.componentInstance.data.team.permissions.splice(index, 1)
      }
    })

    dialogRef.componentInstance.userAdded.subscribe(userId => {
      const oldData = dialogRef.componentInstance.data
      const oldUsers = oldData.users

      oldUsers.map((user, i) => {
        if (user._id == userId) {
          dialogRef.componentInstance.data.team.users.push(user)
        }
      })
    })

    dialogRef.componentInstance.removedUser.subscribe(userId => {
      const users = dialogRef.componentInstance.data.team.users

      let index = -1
      users.map((teamUser, i) => {
        if (teamUser._id == userId) {
          index = i
        }
      })

      if (index > -1) {
        users.splice(index, 1)
      }
    })

    dialogRef.componentInstance.bulkInfoUpdated.subscribe(actionInfo => {
      const actions = dialogRef.componentInstance.teamActions
      const action = actions.find(act => act._id == actionInfo.actionId)
      action.bulk = actionInfo.bulk
    })
  }

  removeTeam(teamId) {
    let dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '300px',
      height: '30%',
      data: {
        header: 'Delete item',
        content: 'Do you wish to delete these records?'
      }
    })
    dialogRef.componentInstance.confirmationEvent.subscribe(data => {
      this.processRecordservice.setLoading(true)
      this.httpService
        .post(`${this.baseUrl}/teams/${teamId}/delete`, {})
        .subscribe(
          res => {
            this._snackbar.showSnackbar(res['msg'], 'success')
            this.dialog.closeAll()
            this.processRecordservice.setLoading(false)
            let teamIndex = this.teams.findIndex(team => team._id == teamId)
            this.teams.splice(teamIndex, 1)
          },
          error => {
            this.processRecordservice.setLoading(false)
            this.dialog.closeAll()
          }
        )
    })
  }
}
