import { Component, OnInit } from '@angular/core'
import { FormBuilder } from '@angular/forms'
import { SnackbarService } from 'src/app/snackbar.service'
import { ModulesService } from '../../modules.service'
import { ProcessRecordsService } from '../../process/process-records/process-records.service'
import { LoadingDialogBoxComponent } from '../../process/process-records/loading-dialog-box-component/loading-dialog-box-component.component'
import { MatDialog } from '@angular/material'
import { map } from 'rxjs/operators'
import { WarehouseDataService } from '../../process/warehouse-data.service'
import { SESSION_STORAGE } from 'src/app/app.constant'
import { Subject, BehaviorSubject } from 'rxjs'
import { FileViewDialogComponent } from '../../process/process-records/file-view-dialog/file-view-dialog.component'

@Component({
  selector: 'app-pickup-approval',
  templateUrl: './pickup-approval.component.html',
  styleUrls: ['./pickup-approval.component.css']
})
export class PickupApprovalComponent implements OnInit {
  pickupIdControl
  pickupId
  pickupItems$
  pickupItems
  initialCondition = 'New'
  conditions = ['New', 'Needs Refurbishment', 'Damaged']
  processId = '5dce9d36d52c2f1b2081f416'
  body = {}
  pickupFormArray
  totalRecords
  panelOpenState = false
  subject: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([])
  pickup$ = this.subject.asObservable()
  pickupImages: string[] = []
  closeIcon: boolean = false

  constructor(
    private fb: FormBuilder,
    private _snackbar: SnackbarService,
    private modulesService: ModulesService,
    private processRecordService: ProcessRecordsService,
    private dialog: MatDialog,
    private warehouseService: WarehouseDataService
  ) {}

  ngOnInit() {
    this.beginTheGame()
  }

  beginTheGame() {
    this.closeIcon = false
    this.openDialog()
    this.body = {
      filters: [{ status: ['pickedUp', 'partiallyPickedUp'] }],
      paginate: { page: 1, perPage: 10 },
      storeCode: JSON.parse(localStorage.getItem(SESSION_STORAGE.WAREHOUSE))
    }
    this.initializeData()
    this.pickupIdControl = this.fb.control('')
    this.warehouseService.warehouseSubject$.subscribe(warehouses => {
      if (warehouses) {
        this.body['storeCode'] = warehouses
        this.initializeData()
      }
    })
  }

  submitForm() {
    this.closeIcon = true
    this.subject.next([])
    this.openDialog()
    this.pickupId = this.pickupIdControl.value
    this.modulesService
      .pickupitemsbyPickupId(this.pickupId)
      .pipe(
        map(record => {
          record.shipment =
            record.shipment &&
            record.shipment.filter(
              shipmentInfo => shipmentInfo.isActive == 'yes'
            )[0]
          record.pickupForm = record.pickupForm.filter(
            pickupFormInfo => pickupFormInfo.isActive == 'yes'
          )[0]
          const pickedItems = record.items.filter(
            item => item.status === 'picked'
          )
          let pickupImages = []
          for (let item of pickedItems) {
            if (item.pickupImages) {
              pickupImages = [
                ...pickupImages,
                ...item.pickupImages
                  .filter(img => img.fileUrl)
                  .map(img => img.fileUrl)
              ]
            }
          }
          record.pickupImages = pickupImages
          record.items = pickedItems.map(item => {
            let pkg = []
            if (item.packageItems) {
              pkg = item.packageItems.map(pkgItem => {
                return {
                  _id: item._id,
                  name: pkgItem.name,
                  barcode: pkgItem.barcode,
                  selected: true,
                  damageAmount: pkgItem.damageAmount || '',
                  damageRemark: pkgItem.damageRemark || '',
                  condition: pkgItem.condition || this.initialCondition
                }
              })
            }
            if (item.metaInfo && item.metaInfo.pickupBarcode) {
              return {
                name: item.name,
                packageItems: pkg,
                barcode: item.metaInfo.pickupBarcode,
                selected: true,
                damageAmount: item.damageAmount || '',
                damageRemark: item.damageRemark || '',
                condition: item.condition || this.initialCondition,
                expanded: true
              }
            } else {
              return {
                name: item.name,
                packageItems: pkg,
                barcode: '',
                selected: true,
                damageAmount: item.damageAmount || '',
                damageRemark: item.damageRemark || '',
                condition: item.condition || this.initialCondition,
                expanded: true
              }
            }
          })
          return record
        })
      )
      .subscribe(pickupData => {
        this.totalRecords = 1
        this.subject.next([pickupData])
        this.dialog.closeAll()
      })
  }

  initializeData() {
    this.processRecordService
      .getData(this.body, this.processId)
      .pipe(
        map(recordList => {
          recordList.map(record => {
            record.shipment =
              record.shipment &&
              record.shipment.filter(
                shipmentInfo => shipmentInfo.isActive == 'yes'
              )[0]
            record.pickupForm =
              record.pickupForm &&
              record.pickupForm.filter(
                pickupFormInfo => pickupFormInfo.isActive == 'yes'
              )[0]
            const pickedItems = record.items.filter(
              item => item.status === 'picked'
            )
            let pickupImages = []
            for (let item of pickedItems) {
              if (item.pickupImages) {
                pickupImages = [
                  ...pickupImages,
                  ...item.pickupImages
                    .filter(img => img.fileUrl)
                    .map(img => img.fileUrl)
                ]
              }
            }
            record.pickupImages = pickupImages
            record.items = pickedItems.map(item => {
              let pkg = []
              if (item.packageItems) {
                pkg = item.packageItems.map(pkgItem => {
                  return {
                    _id: item._id,
                    name: pkgItem.name,
                    barcode: pkgItem.barcode,
                    selected: true,
                    damageAmount: pkgItem.damageAmount || '',
                    damageRemark: pkgItem.damageRemark || '',
                    condition: pkgItem.condition || this.initialCondition
                  }
                })
              }
              if (item.metaInfo && item.metaInfo.pickupBarcode) {
                return {
                  name: item.name,
                  packageItems: pkg,
                  barcode: item.metaInfo.pickupBarcode,
                  selected: true,
                  damageAmount: item.damageAmount || '',
                  damageRemark: item.damageRemark || '',
                  condition: item.condition || this.initialCondition
                }
              } else {
                return {
                  name: item.name,
                  packageItems: pkg,
                  barcode: '',
                  selected: true,
                  damageAmount: item.damageAmount || '',
                  damageRemark: item.damageRemark || '',
                  condition: item.condition || this.initialCondition
                }
              }
            })
            return record
          })
          this.getTotalRecords()
          this.dialog.closeAll()
          return recordList
        })
      )
      .subscribe(pickupData => {
        this.subject.next(pickupData)
      })
  }

  changePage(pageEvent) {
    this.openDialog()
    this.body['paginate'] = {
      page: pageEvent.pageIndex + 1,
      perPage: pageEvent.pageSize
    }

    this.initializeData()
  }

  getTotalRecords() {
    this.processRecordService
      .getTotalRecords(this.processId, this.body)
      .subscribe(size => {
        this.totalRecords = size
      })
  }

  approve(pickup) {
    setTimeout(() => {
      this._snackbar.showSnackbar(`Order ${pickup.orderId} approved`, 'success')
      console.log(pickup.items)
      // this.closePanel(pickup);
      this.openDialog()
      this.initializeData()
      this.pickupIdControl.setValue('')
    }, 10)
  }

  cancel(pickup) {
    this.closePanel(pickup)
  }

  closePanel(pickupItem) {
    pickupItem.expanded = false
  }

  panelOpened(pickupItem) {
    pickupItem.expanded = true
  }

  parentToggled(item) {
    item.packageItems = item.packageItems.map(pkgItem => {
      return {
        ...pkgItem,
        selected: item.selected
      }
    })
  }

  childToggled(item) {
    item.selected = item.packageItems.every(pkgItem => pkgItem.selected)
  }

  openDialog() {
    const dialogRef = this.dialog.open(LoadingDialogBoxComponent, {
      panelClass: 'loadingDialog',
      // height: '30%',
      data: {}
    })
  }

  selectItem(item, event) {
    if (event.value != 'Damaged') {
      item.damageAmount = ''
      item.damageRemark = ''
    }
  }

  viewPickupForm(pickup) {
    const fileName: string = pickup.pickupForm['scannedForm']['title']
    const fileUrl: string = pickup.pickupForm['scannedForm']['fileUrl']
    const password: string = pickup.pickupForm['scannedForm']['password']
    const title: string = 'Pickup Form'
    const dialogRef = this.dialog.open(FileViewDialogComponent, {
      data: {
        fileName,
        fileUrl,
        title,
        password
      }
    })
  }

  viewPickupImages(fileUrl: string) {
    const fileName: string = 'Images'
    const password: string = ''
    const title: string = 'Pickup Images'
    const dialogRef = this.dialog.open(FileViewDialogComponent, {
      data: {
        fileName,
        fileUrl,
        title,
        password
      }
    })
  }
}
