import {
  Component,
  OnInit,
  Inject,
  Input,
  Output,
  EventEmitter
} from '@angular/core'
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material'
import { FormBuilder, FormGroup } from '@angular/forms'
import { ConfirmationDialogComponent } from '../../confirmation-dialog/confirmation-dialog.component'

@Component({
  selector: 'app-record-child-view-dialog',
  templateUrl: './record-child-view-dialog.component.html',
  styleUrls: ['./record-child-view-dialog.component.css']
})
export class RecordChildViewDialogComponent implements OnInit {
  childViewForm: FormGroup

  childData: any[]
  form: any
  actions: any
  access: any
  dataSelected: any
  selectedIndex: any
  isChildListView: boolean

  @Output() updateItem: EventEmitter<any> = new EventEmitter<any>()

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    this.childData = this.data.childData
    this.form = this.data.form
    this.actions = this.data.actions
    this.access = this.data.access
    this.dataSelected = this.data.dataSelected
    this.selectedIndex = this.childData.findIndex(
      data => data._id === this.dataSelected._id
    )
    this.isChildListView = this.data.isChildListView
  }

  selectionChange(event) {
    this.dataSelected = this.childData[event.selectedIndex]
    this.selectedIndex = event.selectedIndex
    return
  }

  updateItemEvent(event) {
    this.updateItem.emit({ index: this.selectedIndex, updatedData: event })
  }
}
