import { Component, OnInit, Input } from '@angular/core'

@Component({
  selector: 'app-loading-dialog-box-component',
  templateUrl: './loading-dialog-box-component.component.html',
  styleUrls: ['./loading-dialog-box-component.component.css']
})
export class LoadingDialogBoxComponent implements OnInit {
  @Input() value?: any
  constructor() {}

  ngOnInit() {}
}
