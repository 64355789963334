import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'app-photo-field',
  templateUrl: './photo-field.component.html',
  styleUrls: ['./photo-field.component.css']
})
export class PhotoFieldComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
