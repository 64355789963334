import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core'
import { LineItemsModel } from 'src/app/models/line-items-model.model'
import * as _ from 'lodash'
import { FormControl, FormArray, FormGroup } from '@angular/forms'

@Component({
  selector: 'po-form',
  templateUrl: './po-form.component.html',
  styleUrls: ['./po-form.component.css']
})
export class PoFormComponent implements OnInit {
  @Input() emptyFormSchema
  @Input() data
  @Output() removeFormEvent = new EventEmitter<any>()
  @Output() emittedData = new EventEmitter<any>()
  customForm
  @Input() type
  constructor() {}

  ngOnInit() {
    this.emptyFormSchema['Vendor Name'] = {
      value: '',
      options: ['Tanpreet Kaur', 'John Smith']
    }
    this.emptyFormSchema['Payment Terms'].options = [
      'Net 15',
      'Net 30',
      'Net 45',
      'Net 60',
      'Due end of the month',
      'Due end of next month',
      'Due on receipt'
    ]
    let li = new LineItemsModel('')
    li['Item Name'].options = ['washing machine', 'refrigerator']
    this.emptyFormSchema['Line Items'] = []
    if (this.data) {
      this.data['Line Items'].forEach(() => {
        this.emptyFormSchema['Line Items'].push(li)
      })
    } else {
      this.emptyFormSchema['Line Items'].push(li)
    }
    let grp = {}
    let ar = []
    for (let field in this.emptyFormSchema) {
      if (_.isArray(this.emptyFormSchema[field])) {
        var sub = {}
        let mainAr = []
        let formAr = new FormArray([])
        this.emptyFormSchema[field].forEach((elem, index) => {
          var sub = {}
          let a = []
          for (let key in this.emptyFormSchema[field][index]) {
            if (this.data) {
              sub[key] = new FormControl(this.data[field][index][key])
            } else {
              sub[key] = new FormControl('')
            }
          }
          for (let key in this.emptyFormSchema[field][0]) {
            a.push([key, this.emptyFormSchema[field][0][key]])
          }
          formAr.push(new FormGroup(sub))
          mainAr.push(a)
        })
        grp[field] = formAr
        ar.push([field, mainAr])
      } else {
        if (this.type == 'Add') {
          grp[field] = new FormControl('')
        } else if (this.type == 'Edit') {
          grp[field] = new FormControl(this.data[field])
        }
        ar.push([field, this.emptyFormSchema[field]])
      }
    }

    this.emptyFormSchema = ar
    this.customForm = new FormGroup(grp)
  }

  close() {
    this.removeFormEvent.emit()
  }

  submit() {
    this.emittedData.emit(this.customForm.value)
  }

  add(event) {}
}
