import { Injectable } from '@angular/core'
import { BehaviorSubject, Observable } from 'rxjs'
import { FilterDialogBoxComponent } from './process-records/filter-dialog-box/filter-dialog-box.component'
import { MatDialog } from '@angular/material/dialog'
import { SESSION_STORAGE } from 'src/app/app.constant'

@Injectable()
export class ProcessService {
  showManageSubject = new BehaviorSubject<boolean>(false)
  showManage$ = this.showManageSubject.asObservable()

  pageIndex = new BehaviorSubject<any>(0)
  pageIndex$ = this.pageIndex.asObservable()

  private processFilters: BehaviorSubject<any[]> = new BehaviorSubject<any[]>(
    []
  )
  private processFilters$: Observable<
    any[]
  > = this.processFilters.asObservable()

  private processBody: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([])
  private processBody$: Observable<any[]> = this.processBody.asObservable()

  constructor(private dialog: MatDialog) {}

  setFilters(processId, filter: any) {
    let processObjects = JSON.parse(
      localStorage.getItem(SESSION_STORAGE.SELECTED_FIELDS)
    )
    if (Array.isArray(processObjects)) {
      let processIdx = processObjects.findIndex(
        processItem => processItem.process === processId
      )
      if (processIdx >= 0) {
        processObjects[processIdx] = {
          ...processObjects[processIdx],
          filter
        }
      } else {
        processObjects.push({
          process: processId,
          filter
        })
      }
    } else {
      processObjects = [
        {
          process: processId,
          filter
        }
      ]
    }
    localStorage.setItem(
      SESSION_STORAGE.SELECTED_FIELDS,
      JSON.stringify(processObjects)
    )
    this.processFilters.next(filter)
  }

  getFiltersObservable(processId) {
    let selectedFields: any = JSON.parse(
      localStorage.getItem(SESSION_STORAGE.SELECTED_FIELDS)
    )
    if (selectedFields) {
      let process = selectedFields.find(
        processObj => processObj.process == processId
      )
      if (process) {
        this.processFilters.next(process.filter)
      } else {
        this.processFilters.next([])
      }
    } else {
      this.processFilters.next([])
    }
    return this.processFilters$
  }

  setBody(bodyData) {
    this.processBody.next(bodyData)
  }

  getBody() {
    return this.processBody$
  }

  getPageIndexObservable() {
    let filter: any = localStorage.getItem(SESSION_STORAGE.PAGE_INDEX)
    this.pageIndex.next(JSON.parse(filter))
    return this.pageIndex$
  }

  setPageIndex(index) {
    localStorage.setItem(SESSION_STORAGE.PAGE_INDEX, JSON.stringify(index))
    this.pageIndex.next(index)
  }
}
