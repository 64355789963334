import { Component, OnInit, Input } from '@angular/core'
import { FormBuilder, FormGroup } from '@angular/forms'
import { element } from 'protractor'
import * as _ from 'lodash'

@Component({
  selector: 'process-form-view',
  templateUrl: './process-form-view.component.html',
  styleUrls: ['./process-form-view.component.css']
})
export class ProcessFormViewComponent implements OnInit {
  @Input() form: any
  @Input() fieldTypesArray
  formInstance: FormGroup
  constructor(private fb: FormBuilder) {}

  ngOnInit() {
    this.formInstance = this.fb.group({})
    Object.entries(this.form).forEach(keyValues => {
      this.createForm(this.formInstance, keyValues)
    })
  }

  createForm(formInstance, keyValues) {
    const [key, value] = keyValues

    if (_.isArray(value)) {
      let fieldArray = this.fb.array([])
      value.forEach(fieldParams => {
        let fieldGroup = this.fb.group({})
        Object.entries(fieldParams).forEach(fieldKeyValue => {
          this.createForm(fieldGroup, fieldKeyValue)
        })
        fieldArray.push(fieldGroup)
      })
      formInstance.addControl(key, fieldArray)
    } else if (!_.isArray(value) && _.isObject(value)) {
      if (value['type'] == 'group') {
        let fieldArray = this.fb.array([])
        value['fields'].forEach(fieldParams => {
          let fieldGroup = this.fb.group({})
          Object.entries(fieldParams).forEach(fieldKeyValue => {
            this.createForm(fieldGroup, fieldKeyValue)
          })
          fieldArray.push(fieldGroup)
        })
        formInstance.addControl(key, fieldArray)
      } else {
        let fieldArray1 = this.fb.array([])
        let fieldGroup1 = this.fb.group({})
        Object.entries(value).forEach(fieldKeyValue => {
          this.createForm(fieldGroup1, fieldKeyValue)
        })
        fieldArray1.push(fieldGroup1)
        formInstance.addControl(key, fieldArray1)
      }
    } else {
      formInstance.addControl(key, this.fb.control(value))
    }
  }

  saveForm() {}
}
