import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'vendor-minor-dashboard',
  templateUrl: './vendor-minor-dashboard.component.html',
  styleUrls: ['./vendor-minor-dashboard.component.css']
})
export class VendorMinorDashboardComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
