import { Injectable } from '@angular/core'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Observable, throwError } from 'rxjs'
import { environment } from '../../../environments/environment'
import { NotifierService } from 'angular-notifier'
import {
  STATUS_CODES,
  RESPONSE_MESSAGES,
  SESSION_STORAGE
} from '../../app.constant'
import { catchError } from 'rxjs/operators'
import { MatSnackBar } from '@angular/material'
import { SnackbarService } from 'src/app/snackbar.service'

@Injectable({
  providedIn: 'root'
})
export class HttpService {
  authToken: any
  userId: any
  userData: any
  userRoles: any

  constructor(protected http: HttpClient, public _snackBar: SnackbarService) {}

  setActivityTime() {
    let now = new Date().getTime() / 1000
    localStorage.setItem('activityTime', now.toString())
  }

  getLastActivityTime() {
    return parseInt(localStorage.getItem('activityTime'))
  }

  getTimeDiffernceOfActivity() {
    let now = new Date().getTime() / 1000
    let inactiveTime = now - this.getLastActivityTime()
    return inactiveTime
  }

  logout() {
    localStorage.clear()
    window.location.href = 'login'
  }

  isInactive() {
    if (this.getTimeDiffernceOfActivity() > +environment.inactiveTimeInSec) {
      // redirect to logout
      //alert("Inactive for "+environment.inactiveTimeInSec+" seconds");
      this.logout()
    } else {
      this.setActivityTime()
    }
  }

  isLoggedIn() {
    if (
      localStorage.getItem(SESSION_STORAGE.TOKEN) &&
      localStorage.getItem(SESSION_STORAGE.ID) &&
      localStorage.getItem(SESSION_STORAGE.DETAILS)
    ) {
      return true
    }
    return false
  }

  loadToken() {
    this.isInactive()
    if (localStorage.getItem(SESSION_STORAGE.TOKEN)) {
      this.authToken = localStorage.getItem(SESSION_STORAGE.TOKEN)
    } else {
      this.authToken = null
    }
  }

  getUserData() {
    this.isInactive()
    if (
      localStorage.getItem(SESSION_STORAGE.DETAILS) &&
      JSON.parse(localStorage.getItem(SESSION_STORAGE.DETAILS))
    ) {
      this.userData = JSON.parse(localStorage.getItem(SESSION_STORAGE.DETAILS))
    } else {
      this.userData = null
    }
    return this.userData
  }

  getUserId() {
    this.isInactive()
    if (localStorage.getItem(SESSION_STORAGE.ID)) {
      this.userId = localStorage.getItem(SESSION_STORAGE.ID)
    } else {
      this.userId = null
    }
    return this.userId
  }

  getLoggedInUserRoles() {
    this.isInactive()
    if (
      localStorage.getItem(SESSION_STORAGE.USER_ROLES) &&
      JSON.parse(localStorage.getItem(SESSION_STORAGE.USER_ROLES))
    ) {
      this.userRoles = JSON.parse(
        localStorage.getItem(SESSION_STORAGE.USER_ROLES)
      )
    } else {
      this.userRoles = null
    }
    return this.userRoles
  }

  getHeaders(method): HttpHeaders {
    this.loadToken()
    this.getUserId()
    if (this.authToken) {
      if (method == 'get') {
        return new HttpHeaders({
          'Cache-Control': 'no-cache',
          Pragma: 'no-cache'
        })
      } else {
        return new HttpHeaders({
          'x-auth-token': this.authToken
        })
      }
    } else {
      return new HttpHeaders()
    }
  }

  post(url: string, body: Object = {}, headers?: Object): Observable<any> {
    let finalHeaders = this.getHeaders('post')
    if (headers && headers['headers']) {
      for (let headerKey in headers['headers']) {
        finalHeaders.set(headerKey, headers['headers']['headerKey'])
      }
    }
    return this.http.post(url, body, { headers: finalHeaders }).pipe(
      catchError((err, source) => {
        return this.onError(err, source)
      })
    )
  }
  get(url: string, headers?: Object): Observable<any> {
    let finalHeaders = this.getHeaders('get')
    if (headers && headers['headers']) {
      for (let headerKey in headers['headers']) {
        finalHeaders.set(headerKey, headers['headers']['headerKey'])
      }
    }
    return this.http.get(url, { headers: finalHeaders })
  }

  put(url: string, body: Object = {}, headers?: Object): Observable<any> {
    let finalHeaders = this.getHeaders('put')
    if (headers && headers['headers']) {
      for (let headerKey in headers['headers']) {
        finalHeaders.set(headerKey, headers['headers']['headerKey'])
      }
    }
    return this.http.put(url, body, { headers: finalHeaders })
  }

  delete(url: string, headers?: Object): Observable<any> {
    let finalHeaders = this.getHeaders('delete')
    if (headers && headers['headers']) {
      for (let headerKey in headers['headers']) {
        finalHeaders.set(headerKey, headers['headers']['headerKey'])
      }
    }
    return this.http.delete(url, { headers: finalHeaders })
  }

  onError(error: any, source) {
    if (!window.navigator.onLine || error.name == 'MongoNetworkError') {
      this._snackBar.showSnackbar('Check your internet connection.', 'error')
    } else if (typeof error.error.message == 'string') {
      this._snackBar.showSnackbar(error.error.message, 'error')
      // return;
    } else if (typeof error.error == 'string') {
      this._snackBar.showSnackbar(error.error, 'error')
      // return;
    }
    return throwError(error)
  }
}
