import {
  Component,
  ViewChild,
  ElementRef,
  TemplateRef,
  AfterViewInit,
  AfterContentInit
} from '@angular/core'
import { AuthService } from './modules/auth/auth.service'
import { Observable } from 'rxjs'
import { MatDrawer } from '@angular/material/sidenav'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  login: Observable<boolean>
  constructor(private authenticationService: AuthService) {}

  ngOnInit() {
    // Subscribe to the login status.
    this.login = this.authenticationService.loginSubject$
  }
}
