import { ActivatedRoute, Router } from '@angular/router'
import { Component, OnInit } from '@angular/core'
import { Title } from '@angular/platform-browser'

@Component({
  selector: 'fir-list',
  templateUrl: './fir-list.component.html',
  styleUrls: ['./fir-list.component.css']
})
export class FirListComponent implements OnInit {
  isListView: boolean = true

  constructor(
    private route: ActivatedRoute,
    private titleService: Title,
    private router: Router
  ) {
    this.route.params.subscribe(param => {
      if (param.id) this.isListView = false
    })
    this.titleService.setTitle('Rmp-Dashboard')
  }

  ngOnInit() {}
}
