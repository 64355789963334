import { Router } from '@angular/router'
import { Observable, forkJoin, from, of, Subject } from 'rxjs'
import { BehaviorSubject } from 'rxjs'
import { HttpClient, HttpErrorResponse } from '@angular/common/http'
import { Location } from '@angular/common'
import {
  Component,
  OnInit,
  ViewChild,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  OnChanges,
  OnDestroy,
  Input
} from '@angular/core'
import {
  FormBuilder,
  FormGroup,
  FormControl,
  FormArray,
  Validators,
  NgForm
} from '@angular/forms'
import { ActivatedRoute } from '@angular/router'
import { MatDialog, MatSnackBar } from '@angular/material'
import * as _ from 'lodash'
import { RequestDialogComponent } from './request-dialog/request-dialog.component'
import * as moment from 'moment'
import { NotifierService } from 'angular-notifier'
import { environment } from 'src/environments/environment'
import { map } from 'rxjs/operators'
import { RecordViewService } from './record-view.service'
import { ProcessRecordsService } from '../process-records.service'
import { LoadingDialogBoxComponent } from '../loading-dialog-box-component/loading-dialog-box-component.component'
import { AuthService } from 'src/app/modules/auth/auth.service'
import { HttpService } from 'src/app/core/services/http.service'
import { SharedService } from 'src/app/shared/services/shared.service'
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component'
import { SnackbarService } from 'src/app/snackbar.service'
import { RequestsSocketService } from '../requests-socket.service'

@Component({
  selector: 'record-view',
  templateUrl: './record-view.component.html',
  styleUrls: ['./record-view.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RecordViewComponent implements OnInit {
  statusLog
  loading = false
  processId: string
  childIds = {}
  recordId: string
  recordData: any
  recordActions: any[] = []
  parent
  parentSubject = new Subject<any>()
  parent$: Observable<any> = this.parentSubject.asObservable()
  recordFirs: Observable<any>
  stateForm: FormGroup
  singleChildData
  renderData: Observable<any>
  renderArray: Observable<any>[] = []
  isExecuting: boolean = true
  state$: Observable<any>
  change = true
  master: Subject<any> = new Subject<any>()
  master$ = this.master.asObservable()
  states: Observable<any>
  statuses: Observable<any>
  baseUrl = environment.baseURL
  selectedOption
  loading$: Observable<any>
  deptId
  permissionObservable
  selectedFir

  constructor(
    private recordService: RecordViewService,
    private _formBuilder: FormBuilder,
    private httpClient: HttpClient,
    private route: ActivatedRoute,
    private router: Router,
    public dialog: MatDialog,
    private _location: Location,
    private cd: ChangeDetectorRef,
    private _notifier: NotifierService,
    private processRecordService: ProcessRecordsService,
    private authService: AuthService,
    private httpService: HttpService,
    private sharedService: SharedService,
    private _snackbar: SnackbarService,
    private recordViewService: RecordViewService,
    private requestSocketService: RequestsSocketService
  ) {
    this.processRecordService.setLoading(false)
    this.openDialog()
  }

  ngOnInit() {
    this.master.next(true)
    this.deptId = this.router.url.split('/')[2]
    this.processId = this.router.url.split('/')[4]
    this.route.params.subscribe(params => {
      if (params) {
        this.recordId = params['recordId']
      }
    })

    this.getApiData()

    this.processRecordService.listItems$.subscribe(childListItemObject => {
      if (childListItemObject) {
        this.childIds[childListItemObject.id] = childListItemObject.childIds
      }
    })
  }

  insertSingleChilds() {
    if (this.recordData) {
      const details = this.recordData.details
      for (const key in details) {
        if (key != '0' && details[key].length == 1) {
          this.childIds[key] = details[key][0]['_id']
        }
      }
    }
  }

  getApiData() {
    const formObervable: Observable<any> = this.recordService.getProcessForms(
      this.processId
    )
    const dataObservable: Observable<any> = this.recordService.getRecordData(
      this.recordId,
      this.processId
    )
    this.permissionObservable = this.recordService.getUserPermission(
      this.processId
    )
    const actionObservable: Observable<any> = this.recordService.getProcessActions(
      this.processId
    )
    this.recordService
      .getRecordFirs(this.processId, this.recordId)
      .subscribe(recordFirs => {
        this.recordFirs = recordFirs
      })
    this.state$ = dataObservable
    this.states = this.httpService.post(
      `${this.baseUrl}/process/${this.processId}/states`,
      {}
    )
    // this.statuses = this.httpService.post(`${this.baseUrl}/process/${this.processId}/states`, {}).pipe(map((data) => {
    // 	return data.map((data) => {
    // 		return data.statuses
    // 	})
    // }))
    this.renderData = forkJoin([
      formObervable,
      dataObservable,
      this.permissionObservable,
      actionObservable
    ]).pipe(
      map(([forms, recordData, permissions, actions]) => {
        this.renderArray = []
        const formattedData: any[] = []
        this.recordData = recordData
        this.insertSingleChilds()
        this.recordService.recordViewSubject.next({
          state: true,
          processId: this.processId,
          recordId: this.recordId,
          details: recordData.details
        })
        this.recordActions = actions
        this.createStateForm(this.recordData)
        forms.map(form => {
          if (form.index == 0) {
            this.parent = {
              form,
              data: recordData.details[form.index]
                ? recordData.details[form.index]
                : [],
              _id: recordData._id,
              access: permissions,
              actions
            }
            this.parentSubject.next(true)
          } else
            this.renderArray.push(
              of({
                form: form,
                data: recordData.details[form.index]
                  ? recordData.details[form.index]
                  : [],
                _id: recordData._id,
                access: permissions,
                actions: actions
              })
            )
        })
        return this.renderArray
      })
    )

    this.state$.subscribe(states => {
      this.sharedService.currentStateSub.next(states)
      this.statusLog = states.statusLog
    })
    this.states.subscribe(states => {
      this.sharedService.statesSub.next(states)
    })
    this.permissionObservable.subscribe(permission => {
      this.sharedService.permissionSub.next(permission)
    })
  }

  createStateForm(record) {
    this.stateForm = this._formBuilder.group({
      state: this._formBuilder.control(record.state),
      status: this._formBuilder.control(record.status)
    })
  }

  executeFromSingleChild(event) {
    this.openDialog()
    if (this.selectedFir) {
      this.childIds = this.selectedFir.childData
    }

    this.processRecordService
      .executeAction(
        this.recordId,
        event.actionId,
        this.childIds,
        this.processId
      )
      .subscribe(
        data => {
          if (data) {
            this._snackbar.showSnackbar(data.msg, 'success')
            this.dialog.closeAll()
            this.refresh()
          }
        },
        err => {
          this.dialog.closeAll()
        }
      )
  }

  refresh() {
    this.parentSubject.next(false)
    this.openDialog()
    this.getApiData()
    this.master.next(true)
  }

  updateItemEvent(data) {
    this.openDialog()
    this.processRecordService
      .updateAction(this.parent.form._id, this.recordId, this.processId, data)
      .subscribe(
        data => {
          this.parent.data[data.index] = data.updatedData
          this.dialog.closeAll()
          this._snackbar.showSnackbar(data.msg, 'success')
        },
        err => {
          this.dialog.closeAll()
        }
      )
  }

  deleteItemEvent(event) {
    this.openDialog()
    let childIds = event.selectedItems.map(elem => elem._id)
    this.processRecordService
      .deleteAction(
        this.parent.form._id,
        this.recordId,
        this.processId,
        childIds
      )
      .subscribe(
        data => {
          this.parent.data = event.data
          this.parent.data = this.parent.data.filter((elem, index) => {
            if (event.selectedItems.indexOf(elem) < 0) {
              return elem
            }
          })
          this.dialog.closeAll()
          this._snackbar.showSnackbar(data.msg, 'success')
        },
        err => {
          this.dialog.closeAll()
        }
      )
  }

  delete() {
    let dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '300px',
      height: '30%',
      data: {
        header: 'Delete item',
        content: 'Do you wish to delete this record?'
      }
    })
    dialogRef.componentInstance.confirmationEvent.subscribe(data => {
      this.recordService
        .deleteRecords(this.processId, [this.recordId])
        .subscribe(
          data => {
            this.dialog.closeAll()

            this.router.navigate([
              'department',
              this.deptId,
              'process',
              this.processId,
              'records'
            ])
            this._snackbar.showSnackbar(`Record deleted.`, 'success')
          },
          err => {
            this.dialog.closeAll()
            this._snackbar.showSnackbar(err, 'error')
          }
        )
    })
  }

  openDialog() {
    const dialogRef = this.dialog.open(LoadingDialogBoxComponent, {
      panelClass: 'loadingDialog',
      // height: '30%',
      data: {}
    })
  }

  onRequest(selectedAction) {
    const requestDialogRef = this.dialog.open(RequestDialogComponent, {
      width: '400px',
      data: {
        action: selectedAction,
        processId: this.processId
      }
    })

    requestDialogRef.componentInstance.firSubmit.subscribe(inputData => {
      if (inputData) {
        const bodyData = {
          assignedTo: inputData.assignedTo,
          priority: inputData.priority,
          comment: inputData.comment,
          processId: this.processId,
          recordId: this.recordId,
          actionId: selectedAction._id,
          childData: this.childIds
        }

        this.openDialog()
        this.recordViewService.createRequest(bodyData).subscribe(
          res => {
            const { msg, data } = res
            this.dialog.closeAll()
            this._snackbar.showSnackbar(res['msg'], 'success')
            // this.refreshList();

            // let totalRequests = res['msg'].slice(res['msg'].indexOf('/') + 1);

            let type
            switch (data.priority) {
              case 'low':
                type = 'success'
                break
              case 'medium':
                type = 'warning'
                break
              default:
                type = 'error'
            }

            let firIds = data.firId
            let id = data._id
            // this.requestSocketService.emit('request event', 1, inputData.assignedTo, JSON.parse(localStorage.getItem('details')).userName, data.action.title, type, firIds, id);
            selectedAction = null
            this.refresh()
          },
          error => {
            this.dialog.closeAll()
          }
        )
      }
    })
  }

  selectedFirEvent(value) {
    this.selectedFir = value
  }

  ngOnDestroy() {
    this.recordService.recordViewSubject.next({ state: false })
  }
}
