import { environment } from 'src/environments/environment'
import { RouterModule } from '@angular/router'
import { NgModule } from '@angular/core'
import { AuthGuardService } from '../auth/auth-guard.service'
import { InventoryComponent } from './inventory.component'
import { PurchaseOrderComponent } from './purchase-order/purchase-order.component'
import { VendorComponent } from './vendor/vendor.component'

export const appRoutes = [
  {
    path: `department/${environment.config.inventory.id}`,
    component: InventoryComponent,
    canActivate: [AuthGuardService],
    children: [
      {
        path: `process/${environment.config.inventory.purchaseOrder}`,
        component: PurchaseOrderComponent
      },
      {
        path: `process/${environment.config.inventory.vendor}`,
        component: VendorComponent
      }
    ]
  }
]

@NgModule({
  imports: [RouterModule.forChild(appRoutes)],

  exports: [RouterModule]
})
export class InventoryRoutingModule {}
