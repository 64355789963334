import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'vendor-purchases',
  templateUrl: './vendor-purchases.component.html',
  styleUrls: ['./vendor-purchases.component.css']
})
export class VendorPurchasesComponent implements OnInit {
  billSource = [
    {
      Date: '23/11/2020',
      'Bill#': 1223,
      'Reference#': 2233,
      'Vendor Name': 'Mahinder',
      Amount: 20000,
      'Balance Due': 5000,
      Status: 'Delivered'
    },
    {
      Date: '22/11/2020',
      'Bill#': 12243,
      'Reference#': 22335,
      'Vendor Name': 'Mahinder',
      Amount: 4000,
      'Balance Due': 0,
      Status: 'Delivered'
    }
  ]
  billCols = [
    'Date',
    'Bill#',
    'Reference#',
    'Vendor Name',
    'Amount',
    'Balance Due',
    'Status'
  ]
  paymentSource = [
    {
      Date: '23/11/2020',
      'Payment#': 1223,
      'Reference#': 2233,
      'Payment Mode': 'Mahinder',
      'Amount Paid': 20000,
      'Unused Amount': 5000
    }
  ]
  paymentCols = [
    'Date',
    'Payment#',
    'Reference#',
    'Payment Mode',
    'Amount Paid',
    'Unused Amount'
  ]
  poSource
  poCols
  receivesSource
  receivesCols
  constructor() {}

  ngOnInit() {}
}
