import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'app-mark-picked-form',
  templateUrl: './mark-picked-form.component.html',
  styleUrls: ['./mark-picked-form.component.css']
})
export class MarkPickedFormComponent implements OnInit {
  picked: boolean = true
  constructor() {}

  ngOnInit() {}

  afterVerification(event) {}

  rengenerateOtp(event) {}
}
