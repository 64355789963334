import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core'

@Component({
  selector: 'custom-list',
  templateUrl: './custom-list.component.html',
  styleUrls: ['./custom-list.component.css']
})
export class CustomListComponent implements OnInit {
  @Input() dataSource
  @Input() displayedColumns
  @Output() clickedItem = new EventEmitter<any>()
  selectedId
  fieldToolTip = 'Testing'

  constructor() {}

  ngOnInit() {}

  emitRow(row) {
    this.clickedItem.emit(row)
  }
}
