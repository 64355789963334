import { Component, OnInit, Input } from '@angular/core'
import { SharedService } from 'src/app/shared/services/shared.service'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { FormControl, FormBuilder, FormGroup } from '@angular/forms'
import { ActivatedRoute } from '@angular/router'
import { RecordViewService } from '../../process/process-records/record-view/record-view.service'
import { ProcessRecordsService } from '../../process/process-records/process-records.service'
import { MatDialog } from '@angular/material'
import { LoadingDialogBoxComponent } from '../../process/process-records/loading-dialog-box-component/loading-dialog-box-component.component'
import { SnackbarService } from 'src/app/snackbar.service'

@Component({
  selector: 'app-change-status',
  templateUrl: './change-status.component.html',
  styleUrls: ['./change-status.component.css']
})
export class ChangeStatusComponent implements OnInit {
  disable = true
  states
  actionId
  currentState
  permission
  isAdmin: boolean
  status: string
  state: string
  statesData
  orderId
  details
  selected
  isViewOpen: Observable<any>
  @Input() recordId
  @Input() processId
  childIds: Array<any> = []

  constructor(
    private sharedService: SharedService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private recordService: RecordViewService,
    private processService: ProcessRecordsService,
    private dialog: MatDialog,
    private _snackbar: SnackbarService
  ) {}

  ngOnInit() {
    this.isViewOpen = this.recordService.recordView$

    this.isViewOpen.subscribe(data => {
      this.processId = data.processId
      this.recordId = data.recordId
      this.details = data.details
    })

    this.sharedService.states$.subscribe(data => {
      this.statesData = data
    })

    this.sharedService.currentState$.subscribe(data => {
      this.currentState = data
      this.orderId = data.details[0][0].orderId
      this.selected = data.status
    })

    this.sharedService.permissionSub$.subscribe(permission => {
      this.checkAdmin(permission)
    })
  }

  checkAdmin(permission) {
    this.isAdmin = permission['teams'].some(team => {
      return team.title === 'admin'
    })
  }

  statusChangeEvent(event) {
    this.actionId = event.value._id
    this.disable = false
  }

  changeStatus() {
    this.openDialog()
    let curState
    this.statesData.find(state => {
      let exists = state.statuses.find(status => status.code === this.selected)
      if (exists) {
        curState = state.code
      }
    })
    const body = {
      recordId: this.recordId,
      state: curState,
      status: this.selected
    }
    this.processService.updatestatus(this.processId, body).subscribe(
      res => {
        this._snackbar.showSnackbar(res['msg'], 'success')
        this.dialog.closeAll()
      },
      error => {
        this._snackbar.showSnackbar(error.error.message, 'error')
        this.dialog.closeAll()
      }
    )
  }
  openDialog() {
    const dialogRef = this.dialog.open(LoadingDialogBoxComponent, {
      disableClose: true,
      panelClass: 'loadingDialog'
    })
  }
}
