import { Injectable } from '@angular/core'
import { HttpService } from '../core/services/http.service'
import { environment } from '../../environments/environment'
import { Subject, BehaviorSubject } from 'rxjs'
import { AuthService } from './auth/auth.service'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { SESSION_STORAGE } from '../app.constant'

@Injectable()
export class ModulesService {
  baseUrl = environment.baseURL
  salesSubject = new Subject<any>()
  hrSubject = new Subject<any>()
  financeSubject = new Subject<any>()
  collectionsSubject = new Subject<any>()
  shipmentSubject = new Subject<any>()
  escalationSubject = new Subject<any>()
  inventorySubject = new Subject<any>()
  breadcrumb: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([])
  breadcrumb$ = this.breadcrumb.asObservable()
  salesProcess$ = this.salesSubject.asObservable()
  hrProcess$ = this.hrSubject.asObservable()
  financeProcess$ = this.financeSubject.asObservable()
  collectionProcess$ = this.collectionsSubject.asObservable()
  shipmentProcess$ = this.shipmentSubject.asObservable()
  inventoryProcess$ = this.inventorySubject.asObservable()
  escalationProcess$ = this.escalationSubject.asObservable()
  breadcrumbSubscription
  workingDays: number
  variablePercentage: number = 17
  advances: number = 0
  bonus: number = 0
  arrears: number = 0
  departments: Array<string> = [
    'Finance',
    'Renewals',
    'Marketing',
    'Collections',
    'Technical',
    'Escalation'
  ] //** Very Important To Set **//

  constructor(
    private httpService: HttpService,
    private authService: AuthService,
    protected http: HttpClient
  ) {}
  getItemsByOrderId(orderId: string) {
    return this.httpService.post(`${this.baseUrl}/records/itemsbyorderId`, {
      orderId
    })
  }

  pickupitemsbyPickupId(pickupId: string) {
    return this.httpService.post(
      `${this.baseUrl}/records/pickupitemsbyPickupId`,
      { pickupId }
    )
  }

  getProducts(body) {
    return this.httpService.post(
      `${this.baseUrl}/records/${environment.config.sales.product}/list`,
      body
    )
  }

  changeProcessType(value) {
    this.breadcrumb.next(value)
  }

  showBreadcrumb(title = undefined, processId = undefined, deptId) {
    let breadcrumbsArray = []
    let deptObj = JSON.parse(
      localStorage.getItem(SESSION_STORAGE.DEPARTMENT_OBJ)
    )
    let processObj = JSON.parse(
      localStorage.getItem(SESSION_STORAGE.PROCESS_OBJ)
    )
    breadcrumbsArray.push({
      title: deptObj[deptId],
      url: `/department/${deptId}`
    })
    if (processId) {
      breadcrumbsArray.push({
        title: processObj[processId],
        url: `/department/${deptId}/process/${processId}`
      })
    }
    if (title) {
      breadcrumbsArray.push({
        title: title,
        url: `/department/${deptId}/process/${processId}/${title}`
      })
    }
    this.breadcrumb.next([...breadcrumbsArray])
  }

  planPickup(body) {
    return this.httpService.post(
      `${this.baseUrl}/dept/collections/planforpickup`,
      body
    )
  }

  getDepartments() {
    return this.departments
  }

  pushEcsToS3(body) {
    return this.httpService.post(
      `${this.baseUrl}/dept/finance/pushEcsToS3`,
      body
    )
  }

  updateEcsInfo(body) {
    return this.httpService.post(
      `${this.baseUrl}/dept/finance/updateEcsInfo`,
      body
    )
  }
}
