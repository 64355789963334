import { Component, OnInit, Input } from '@angular/core'
import { FormControl, FormGroup } from '@angular/forms'
import { SelectInputItem } from '../select-field/select-field.component'

@Component({
  selector: 'multi-select-field',
  templateUrl: './multi-select-field.component.html',
  styleUrls: ['./multi-select-field.component.css']
})
export class MultiSelectFieldComponent implements OnInit {
  @Input() fieldInstance: FormControl | FormGroup
  @Input() label: string
  @Input('fieldIndex') index: number
  @Input() field: any
  @Input() create?: boolean = false
  @Input() isRequired: boolean = false
  @Input() inputValidations: any[] = []
  @Input() items: SelectInputItem[] = []
  @Input() readOnly: boolean = false
  @Input() path: string
  @Input() isAdmin: boolean = false

  constructor() {}

  ngOnInit() {}
}
