import { Component, OnInit } from '@angular/core'
import {
  NgForm,
  FormBuilder,
  FormGroup,
  Validators,
  FormControl
} from '@angular/forms'
import { HttpClient } from '@angular/common/http'
import { AuthService } from '../auth.service'
import { NotifierService } from 'angular-notifier'
import { Router } from '@angular/router'
import { SESSION_STORAGE } from '../../../app.constant'
import { MatSnackBar } from '@angular/material'
import { SnackbarService } from 'src/app/snackbar.service'
import { Title } from '@angular/platform-browser'

@Component({
  selector: 'login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup
  result: string
  message: string

  constructor(
    private fb: FormBuilder,
    private authenticationService: AuthService,
    private notifier: NotifierService,
    private router: Router,
    private _snackBar: SnackbarService,
    private titleService: Title
  ) {
    if (localStorage.getItem(SESSION_STORAGE.TOKEN)) {
      this.router.navigate(['dashboard'])
    }
    this.titleService.setTitle('Rmp-Login')
  }

  ngOnInit() {
    this.loginForm = this.fb.group({
      email: this.fb.control('', [Validators.required, Validators.email]),
      password: this.fb.control('', Validators.minLength(6))
    })
  }

  getEmailControl() {
    return this.loginForm.get('email') as FormControl
  }

  onLogin(form) {
    if (form.valid) {
      this.authenticationService.loginUser(form).subscribe(
        (data: any) => {
          localStorage.setItem(SESSION_STORAGE.TOKEN, data.token)
          localStorage.setItem(
            SESSION_STORAGE.DETAILS,
            JSON.stringify(data.user)
          )
          this.authenticationService.loginSubject.next(true)
          this._snackBar.showSnackbar('Successful Login', 'success')
          this.router.navigate(['/dashboard'])
        },
        error => {}
      )
    }
  }
}
