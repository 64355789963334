import { Component, OnInit, Input } from '@angular/core'

@Component({
  selector: 'process-action-card',
  templateUrl: './process-action-card.component.html',
  styleUrls: ['./process-action-card.component.css']
})
export class ProcessActionCardComponent implements OnInit {
  @Input() processAction
  constructor() {}

  ngOnInit() {}
}
