import { RecordViewService } from '../record-view.service'
import { FormBuilder, Validators } from '@angular/forms'
import { FormGroup } from '@angular/forms'
import { Component, OnInit, Inject, EventEmitter } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material'
import { RecordViewComponent } from '../record-view.component'
import { Observable } from 'rxjs'
import { startWith, map } from 'rxjs/operators'

@Component({
  selector: 'app-request-dialog',
  templateUrl: './request-dialog.component.html',
  styleUrls: ['./request-dialog.component.css']
})
export class RequestDialogComponent implements OnInit {
  priorities: any[] = [
    { title: 'Low', value: 'low' },
    { title: 'Medium', value: 'medium' },
    { title: 'High', value: 'high' },
    { title: 'Urgent', value: 'urgent' }
  ]

  requestForm: FormGroup
  filteredUsers: Observable<any>
  firSubmit: EventEmitter<any> = new EventEmitter<any>()
  requestUsers: any[] = []
  isLoading: boolean = true

  constructor(
    public dialogRef: MatDialogRef<RecordViewComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private recordService: RecordViewService
  ) {
    this.requestForm = this.formBuilder.group({
      assignedTo: this.formBuilder.control('', Validators.required),
      priority: this.formBuilder.control('', Validators.required),
      comment: this.formBuilder.control('', Validators.required)
    })
    this.recordService
      .requestUsersList(
        this.data.processId,
        this.data.action._id,
        this.data.recordId
      )
      .subscribe((users: any[]) => {
        this.isLoading = false
        this.requestUsers = users
        this.filteredUsers = this.requestForm
          .get('assignedTo')
          .valueChanges.pipe(
            startWith(''),
            map(user =>
              user ? this.filterUsers(user) : this.requestUsers.slice()
            )
          )
      })
  }

  ngOnInit() {}

  OnSubmit() {
    const data = this.requestForm.value
    const assignedTo = this.requestUsers.find(
      user => user.userName == data.assignedTo.toString()
    )
    data['assignedTo'] = assignedTo
    this.firSubmit.emit(data)
  }

  filterUsers(name: string) {
    return this.requestUsers.filter(
      user => user.userName.toLowerCase().indexOf(name.toLowerCase()) == 0
    )
  }
}
