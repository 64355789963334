import { RecordChildViewDialogComponent } from '../record-child-view-dialog/record-child-view-dialog.component'
import { MatTableDataSource, MatDialog, MatSnackBar } from '@angular/material'
import { FormArray, FormControl, FormGroup, FormBuilder } from '@angular/forms'
import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ChangeDetectorRef,
  OnChanges,
  DoCheck,
  Inject
} from '@angular/core'
import { SelectionModel } from '@angular/cdk/collections'
import * as _ from 'lodash'
import { RecordViewService } from '../record-view.service'
import { ProcessRecordsService } from '../../process-records.service'
import { BehaviorSubject, Observable, Subject } from 'rxjs'
import { AddItemDialogComponent } from '../add-item-dialog/add-item-dialog.component'
import { ConfirmationDialogComponent } from '../../confirmation-dialog/confirmation-dialog.component'
import { NotifierService } from 'angular-notifier'
import { SnackbarService } from 'src/app/snackbar.service'
import { SESSION_STORAGE } from 'src/app/app.constant'
import { LoadingDialogBoxComponent } from '../../loading-dialog-box-component/loading-dialog-box-component.component'
import { RequestsSocketService } from '../../requests-socket.service'
import { RequestDialogComponent } from '../request-dialog/request-dialog.component'

@Component({
  selector: 'record-child-list',
  templateUrl: './record-child-list.component.html',
  styleUrls: ['./record-child-list.component.css']
})
export class RecordChildListComponent implements OnInit {
  @Input() form: any = null
  @Input() data: any = {}
  @Input() actions?: any = null
  @Input() access?: any = null
  @Input() path?: string
  @Input() selectedStatus?: any = null
  @Input() recordId
  @Input() processId
  @Input() closeDialog$: Observable<boolean>
  @Input() recordFirs
  childIds: any[] = []
  @Output() executeEvent: EventEmitter<any> = new EventEmitter<any>()
  @Output() pushItem: EventEmitter<any> = new EventEmitter<any>()
  @Output() updateItem: EventEmitter<any> = new EventEmitter<any>()
  @Output() deleteItem: EventEmitter<any> = new EventEmitter<any>()
  fields
  clicked = false
  @Output() requestEmitter = new EventEmitter()

  isList: boolean = true

  canDelete: Boolean = false
  readOnly: Boolean = true
  isAdmin: Boolean = false
  selectedAction
  selectedActionSubject = new Subject<any>()
  selectedAction$ = this.selectedActionSubject.asObservable()
  executeSubject = new Subject<any>()
  execute$ = this.executeSubject.asObservable()
  requestSubject = new Subject<any>()
  request$ = this.requestSubject.asObservable()
  actionId
  selectedItems: Array<any> = []

  @Input() displayData = new MatTableDataSource<any>()
  displayedKeys: string[] = ['select', 'view']
  selection = new SelectionModel<any>(true, [])
  totalSelected: number
  showFirMessage = new BehaviorSubject<any>(false)
  showFirMessage$ = this.showFirMessage.asObservable()
  @Output() selectedFir = new EventEmitter()
  assignedTo: any
  raisedBy: any
  firId: any
  userdetails

  constructor(
    private recordService: RecordViewService,
    private dialog: MatDialog,
    private processRecordService: ProcessRecordsService,
    private formBuilder: FormBuilder,
    private _snackBar: SnackbarService,
    private recordViewService: RecordViewService,
    private requestSocketService: RequestsSocketService
  ) {}

  ngOnInit() {
    this.userdetails = JSON.parse(localStorage.getItem(SESSION_STORAGE.DETAILS))
    this.getHeaderKeys()
    // this.generateData();
    this.readOnly = !this.access.permissions.some(
      permission => permission == 'edit'
    )
    this.canDelete = this.access.permissions.some(
      permission => permission == 'delete'
    )
    this.isAdmin = this.access.teams.some(team => team.title == 'admin')
  }

  setPath(rowData, field): string {
    return `${this.path}/${rowData._id}/${field.title}`
  }

  getHeaderKeys() {
    const fields: any[] = this.form.fields
    fields.map(field => {
      if (
        field.type != 'file' &&
        field.type != 'group' &&
        field.type != 'list' &&
        field.type != 'address'
      ) {
        this.displayedKeys.push(field.title)
      }
    })
  }

  generateData() {
    // this.displayData = new MatTableDataSource(this.data);
    // this.displayData = this.data;
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length
    let numRows
    if (this.data) {
      numRows = this.data.length
    }
    return numSelected == numRows
  }

  // getKeysWithoutObjects(data) {
  // 	const keys = [];
  // 	Object.keys(data).map(key => {
  // 		if (!_.isObject(data[key]) && !_.isArray(data[key]))
  // 			keys.push(key);
  // 	})
  // 	return keys;
  // }

  masterToggle() {
    if (this.isAllSelected()) {
      this.selection.clear()
    } else {
      this.selection.clear()
      let childObj = {
        id: 1,
        childIds: this.displayData.data.map(elem => elem._id)
      }
      this.processRecordService.changeListItems(childObj)
      this.displayData.data.forEach((row, index) => this.selection.select(row))
    }
    this.totalSelected = this.selection.selected.length
  }

  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`
    }
    return `${
      this.selection.isSelected(row) ? 'deselect' : 'select'
    } row ${row.position + 1}`
  }

  onViewRecordChild(childData) {
    const dialogRef = this.dialog.open(RecordChildViewDialogComponent, {
      width: '600px',
      height: '75%',
      data: {
        childData: this.data,
        form: this.form,
        actions: this.actions,
        access: this.access,
        dataSelected: Object.assign({}, childData),
        isChildListView: true
      }
    })
    dialogRef.componentInstance.updateItem.subscribe(data => {
      this.updateItem.emit(data)
    })
  }

  selectAction(event) {
    this.onClose()
    this.actionId = event.value
    let firRaised = this.recordFirs.find(
      fir => fir.action._id == this.actionId && fir.status == 'open'
    )

    this.assignedTo = firRaised && firRaised.assignedTo
    this.raisedBy = firRaised && firRaised.raisedBy
    this.firId = firRaised && firRaised.firId

    if (
      this.firId &&
      firRaised.status == 'open' &&
      this.assignedTo == this.userdetails.userName
    ) {
      this.selectedFir.emit(firRaised)
      this.executeSubject.next(true)
      this.showFirMessage.next(true)
    } else if (
      !this.firId ||
      firRaised.status == 'closed' ||
      firRaised.status == 'dismissed'
    ) {
      if (this.access.actions.find(action => action._id == this.actionId)) {
        this.executeSubject.next(true)
      } else {
        this.requestSubject.next(true)
        this.executeSubject.next(false)
      }
    } else if (this.firId && firRaised.status == 'open') {
      this.selectedFir.emit(firRaised)
      this.showFirMessage.next(true)
    }
    this.selectedAction = this.actions.find(
      action => action._id == this.actionId
    )
    this.selectedActionSubject.next(this.selectedAction)

    // this.recordViewService.getFirByRecordId(this.actionId, this.recordFirs).subscribe((firRaised) => {
    // 	this.assignedTo = firRaised.assignedTo;
    // 	this.raisedBy = firRaised.raisedBy;
    // 	this.firId = firRaised.firId;
    // 	if (firRaised.firId && firRaised.status == 'open' && firRaised.assignedTo == this.userdetails.userName) {
    // 		this.selectedFir.emit(firRaised);
    // 		this.executeSubject.next(true);
    // 		this.showFirMessage.next(true);
    // 	} else if (!firRaised.firId || firRaised.status == 'closed' || firRaised.status == 'dismissed') {
    // 		if (this.access.actions.find((action) => action._id == this.actionId)) {
    // 			this.executeSubject.next(true);
    // 		}
    // 		else {
    // 			this.requestSubject.next(true);
    // 			this.executeSubject.next(false);
    // 		}
    // 	}
    // 	else if (firRaised.firId && firRaised.status == 'open') {
    // 		this.selectedFir.emit(firRaised);
    // 		this.showFirMessage.next(true);
    // 	}
    // 	this.selectedAction = this.actions.find((action) => action._id == this.actionId);
    // 	this.selectedActionSubject.next(this.selectedAction);

    // });
  }
  onClose() {
    this.selectedAction = ''
    this.actionId = ''
    this.executeSubject.next(false)
    this.requestSubject.next(false)
    this.showFirMessage.next(false)
  }

  executeAction() {
    if (this.selectedAction.confirmationRequired) {
      //show confirmation modal
      let dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        width: '300px',
        height: '35%',
        data: {
          header: 'Confirm Action',
          content: `\n You are executing \n"${this.selectedAction.title}". \nThis is non reversible. Are you sure?`
        }
      })
      dialogRef.componentInstance.confirmationEvent.subscribe(() => {
        const obj = { id: this.form.index, actionId: this.selectedAction._id }
        this.dialog.closeAll()
        this.executeEvent.emit(obj)
      })
    } else {
      //don't show confirmation modal
      const obj = { id: this.form.index, actionId: this.selectedAction._id }
      this.executeEvent.emit(obj)
    }
  }

  listItemsSelected(row) {
    this.childIds = []
    this.selection.toggle(row)

    this.displayData.data.forEach((row, index) => {
      if (this.selection.isSelected(row)) {
        this.childIds[index] = row['_id']
      }
    })

    this.childIds = this.childIds.filter(childId => {
      if (childId) {
        return childId
      }
    })

    this.totalSelected = this.selection.selected.length
    this.processRecordService.changeListItems({
      id: this.form.index,
      childIds: this.childIds
    })
  }

  addItemEvent(event) {
    this.pushItem.emit({ data: event, formIndex: this.form.index })
  }

  addItem() {
    let childViewForm
    this.fields = this.form.fields
    childViewForm = this.formBuilder.group({})
    childViewForm.addControl('_id', this.formBuilder.control(null))

    this.fields.map(field => {
      const fieldTitle = field.title
      switch (field.type) {
        case 'group': {
          const newGroup = this.formBuilder.group({})
          field.fields.map(internalField => {
            const name = internalField['title']
            const control = this.formBuilder.control('')
            newGroup.addControl(name, control)
          })
          childViewForm.addControl(field.title, newGroup)
          break
        }
        case 'list': {
          const newListArray = this.formBuilder.array([])
          childViewForm.addControl(field.title, newListArray)
          break
        }
        case 'address': {
          const addressGroup = this.formBuilder.group({
            lineOne: this.formBuilder.control(''),
            lineTwo: this.formBuilder.control(''),
            addressType: this.formBuilder.control(''),
            city: this.formBuilder.control(''),
            region: this.formBuilder.control(''),
            pincode: this.formBuilder.control(''),
            country: this.formBuilder.control('')
          })

          childViewForm.addControl(field.title, addressGroup)
          break
        }
        case 'select': {
          childViewForm.addControl(field.title, this.formBuilder.control(''))
          break
        }
        case 'file': {
          let fileGroup = this.formBuilder.group({
            title: '',
            fileUrl: '',
            password: ''
          })

          childViewForm.addControl(field.title, fileGroup)
          break
        }
        case 'dateRange': {
          const dateRangeGroup = this.formBuilder.group({
            from: '',
            to: ''
          })

          childViewForm.addControl(field.title, dateRangeGroup)
          break
        }
        default: {
          childViewForm.addControl(field.title, this.formBuilder.control(''))
          break
        }
      }
    })
    let dialogRef = this.dialog.open(AddItemDialogComponent, {
      width: '600px',
      height: '75%',
      data: {
        form: this.form,
        childViewForm: childViewForm,
        isNew: true,
        access: this.access
      },
      disableClose: true
    })
    dialogRef.componentInstance.pushItem.subscribe(data => {
      this.pushItem.emit({ data: data, formIndex: this.form.index })
    })
  }
  removeChildItem() {
    if (this.selection.selected.length) {
      let dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        width: '400px',
        height: '30%',
        data: {
          header: 'Delete item',
          content: {
            line1: 'Do you wish to delete this item?'
          }
        }
      })
      dialogRef.componentInstance.confirmationEvent.subscribe(data => {
        this.deleteItem.emit({
          data: this.data,
          selectedItems: this.selection.selected
        })
        this.selection = new SelectionModel<Element>(true, [])
        this.totalSelected = this.selection.selected.length
      })
    } else {
      this._snackBar.showSnackbar(
        'Please select atleast one item to delete',
        'warn'
      )
    }
  }

  active() {
    this.clicked = true
  }

  onRequest() {
    this.requestEmitter.emit(this.selectedAction)
  }

  openDialog() {
    const dialogRef = this.dialog.open(LoadingDialogBoxComponent, {
      disableClose: true,
      panelClass: 'loadingDialog',
      // height: '30%',
      data: {}
    })
  }
}
