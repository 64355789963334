import { Injectable } from '@angular/core'
import { AuthService } from './modules/auth/auth.service'
import { ActivatedRoute } from '@angular/router'
import { map } from 'rxjs/operators'
import { ValueConverter } from '@angular/compiler/src/render3/view/template'

@Injectable()
export class ManageGuardService {
  value
  constructor(
    private authService: AuthService,
    private route: ActivatedRoute
  ) {}
  canActivate(route, state): boolean {
    let currentPid = route._urlSegment.segments[3].path

    this.authService.processPermission$
      .pipe(
        map(processDetails => {
          return processDetails.map(process => {
            return process
          })
        })
      )
      .subscribe(processArray => {
        let index = processArray.findIndex(process => {
          return process.processId == currentPid
        })
        this.value = processArray[index].isAdmin
      })
    return this.value
  }
}
