import { EnumerationValue } from 'aws-sdk/clients/lexmodelbuildingservice'
import { Option } from 'aws-sdk/clients/rds'
import { LineItemsModel } from './line-items-model.model'

export class PurchaseModel {
  'Purchase Order#': string
  'Reference #': string
  'Expected Delivery Date': Date
  'Received': Date
  'Billed': Date
  'Payment Terms': {
    value: string
    options: Array<string>
  }
  'Line Items': LineItemsModel[]
  'Vendor Name': {
    value: string
    options: Array<string>
  }

  constructor(po) {
    this['Purchase Order#'] = po['Purchase Order#'] || ''
    this['Reference #'] = po['Reference #'] || ''
    this['Expected Delivery Date'] = po['Expected Delivery Date'] || new Date()
    this['Received'] = po['Received'] || new Date()
    this['Billed'] = po['Billed'] || new Date()
    this['Vendor Name'] = po['Vendor Name'] || { value: '', options: [] }
    this['Payment Terms'] = po['Payment Terms'] || { value: '', options: [] }
  }
}
