import { Component, OnInit } from '@angular/core'
import { ModulesService } from '../../modules.service'

@Component({
  selector: 'app-employee',
  templateUrl: './employee.component.html',
  styleUrls: ['./employee.component.css']
})
export class EmployeeComponent implements OnInit {
  departments
  constructor(private moduleService: ModulesService) {}

  ngOnInit() {
    this.departments = this.moduleService.departments
  }
}
