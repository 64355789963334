import { environment } from 'src/environments/environment'
import { NgModule } from '@angular/core'
import { RouterModule } from '@angular/router'
import { SalesComponent } from './sales.component'
import { AuthGuardService } from '../auth/auth-guard.service'
import { ProcessComponent } from '../process/process.component'
import { ProcessRecordsComponent } from '../process/process-records/process-records.component'
import { ProcessAnalyticsComponent } from '../process/process-analytics/process-analytics.component'
import { ProcessOverviewComponent } from '../process/process-overview/process-overview.component'
import { ProcessManageComponent } from '../process/process-manage/process-manage.component'
import { RecordViewComponent } from '../process/process-records/record-view/record-view.component'
import { ChangeOrderItemsComponent } from './change-order-items/change-order-items.component'
import { OutForDeliveryComponent } from './out-for-delivery/out-for-delivery.component'
import { MarkDeliveredComponent } from './mark-delivered/mark-delivered.component'

export const appRoutes = [
  {
    path: `department/${environment.config.sales.id}`,
    component: SalesComponent,
    canActivate: [AuthGuardService],
    children: [
      {
        path: 'Change Order Items',
        component: ChangeOrderItemsComponent
      },
      {
        path: 'Out For Delivery',
        component: OutForDeliveryComponent
      },
      {
        path: 'Mark Delivered',
        component: MarkDeliveredComponent
      }
    ]
  },
  {
    path: `department/${environment.config.sales.id}/process/:processId`,
    component: ProcessComponent
  },
  {
    path: `department/${environment.config.sales.id}/process/:processId/records`,
    component: ProcessRecordsComponent
  },
  {
    path: `department/${environment.config.sales.id}/process/:processId/records/:recordId`,
    component: RecordViewComponent
  },
  {
    path: `department/${environment.config.sales.id}/process/:processId/analytics`,
    component: ProcessAnalyticsComponent
  },
  {
    path: `department/${environment.config.sales.id}/process/:processId/overview`,
    component: ProcessOverviewComponent
  },
  {
    path: `department/${environment.config.sales.id}/process/:processId/manage`,
    component: ProcessManageComponent
  }
]

@NgModule({
  imports: [RouterModule.forChild(appRoutes)],

  exports: [RouterModule]
})
export class SalesRoutingModule {}
