import { Component, OnInit } from '@angular/core'
import { FormGroup, FormBuilder, FormArray } from '@angular/forms'
import { MatDialog } from '@angular/material'
import { Subject } from 'rxjs'
import { VendorService } from './vendor-service.service'
import { SESSION_STORAGE } from 'src/app/app.constant'
import { VendorModel } from 'src/app/models/vendor-model.model'
import * as _moment from 'moment'
const moment = _moment

@Component({
  selector: 'app-vendor',
  templateUrl: './vendor.component.html',
  styleUrls: ['./vendor.component.css']
})
export class VendorComponent implements OnInit {
  selectedPos
  addVendor
  user = localStorage.getItem(SESSION_STORAGE.DETAILS)
  emptyFormSchema = new VendorModel('')
  log = new Subject<any>()
  log$ = this.log.asObservable()
  dataSource: any[] = [
    {
      position: 0,
      'Company Name': 'Rentickles',
      name: 'Tanpreet Kaur',
      email: 'tanuarora2810@gmail.com',
      phone: 2650171,
      contact: {
        name: 'Tanpreet Kaur',
        email: 'tanuarora2810@gmail.com',
        phone: 2650171,
        mobile: 8953529790
      },
      address: {
        street1: '76/2 Industrial Colony',
        street2: 'Govind Nagar',
        city: 'Kanpur',
        state: 'Uttar Pradesh',
        zip: 208006,
        country: 'India',
        phone: 2650171
      },
      'Contact Persons': [
        {
          name: 'Tanpreet Kaur',
          email: 'tanuarora2810@gmail.com',
          phone: 3350171,
          mobile: 8953529790
        },
        {
          name: 'Atique Firoz',
          email: 'atique.firoz@gmail.com',
          phone: 33504171,
          mobile: 9953529790
        }
      ]
    },
    {
      position: 1,
      'Company Name': 'The Smith Organization',
      name: 'John Smith',
      email: 'john@gmail.com',
      phone: 2650171,
      contact: {
        name: 'John Smith',
        email: 'john@gmail.com',
        phone: 2650171,
        mobile: 8953529790
      },
      address: {
        street1: 'PO Box 3109',
        street2: 'Richmond Avenue',
        city: 'Houston',
        state: 'Texas',
        zip: 77056,
        country: 'US',
        phone: 2650171
      },
      'Contact Persons': [
        {
          name: 'John Smith',
          email: 'john@gmail.com',
          phone: 2650171,
          mobile: 8953529790
        },
        {
          name: 'Mark Smith',
          email: 'mark@gmail.com',
          phone: 3650171,
          mobile: 9953529790
        }
      ]
    }
  ]
  displayedColumns = ['name', 'Company Name', 'email', 'phone']
  comments = [
    {
      content: 'jhfges csdefghkj',
      createdBy: 'Tanpreet Kaur'
    },
    {
      content: 'ggfh vgv',
      createdBy: 'Benedict'
    }
  ]
  listColspan = 399
  viewColspan = 1
  listHeader = 'All Vendors'
  selectedRow

  professionalDetails
  detailsForm: FormGroup
  rowIndex: number
  constructor(
    private fb: FormBuilder,
    private dialog: MatDialog,
    private vendorService: VendorService
  ) {}

  ngOnInit() {}

  vendorClicked(index) {
    this.displayedColumns = ['name']
    this.selectedPos = index
  }

  add() {
    this.addVendor = true
  }

  addVendorFunc(details) {
    let createdAt = moment(new Date()).format('YYYY-MM-DD')
    this.dataSource = [
      ...this.dataSource,
      {
        position: this.dataSource.length,
        createdAt: createdAt,
        ...details
      }
    ]
    this.addVendor = false
  }

  close() {
    this.addVendor = false
    this.selectedPos = undefined
    this.displayedColumns = ['name', 'Company Name', 'email', 'phone']
  }
}
