import { Component, OnInit } from '@angular/core'
import { ParamChangeService } from 'src/app/shared/services/param-change.service'

@Component({
  selector: 'dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  constructor(private paramChange: ParamChangeService) {}

  ngOnInit() {}
}
