import { Injectable } from '@angular/core'
import { MatSnackBar } from '@angular/material'

@Injectable({
  providedIn: 'root'
})
export class SnackbarService {
  constructor(private _snackBar: MatSnackBar) {}

  showSnackbar(message, signal) {
    if (signal === 'success') {
      this._snackBar.open(message, 'close', {
        horizontalPosition: 'center',
        verticalPosition: 'top',
        duration: 4000,
        panelClass: ['greenNotifier']
      })
    } else if (signal === 'error') {
      this._snackBar.open(message, 'close', {
        horizontalPosition: 'center',
        verticalPosition: 'top',
        duration: 4000,
        panelClass: ['redNotifier']
      })
    } else if (signal === 'warn') {
      this._snackBar.open(message, 'close', {
        horizontalPosition: 'center',
        verticalPosition: 'top',
        duration: 4000,
        panelClass: ['yellowNotifier']
      })
    }
  }
}
