import {
  FormGroup,
  Validators,
  FormBuilder,
  FormArray,
  FormControl
} from '@angular/forms'
import { Component, OnInit, Inject, Output, EventEmitter } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material'
import { HttpClient } from '@angular/common/http'
import * as _ from 'lodash'

export interface addDialogData {
  actions: any[]
  widgets: any[]
}

interface Action {
  _id: string
  bulk: boolean
}

@Component({
  selector: 'team-create-dialog',
  templateUrl: './team-create-dialog.component.html',
  styleUrls: ['./team-create-dialog.component.css']
})
export class TeamCreateDialogComponent implements OnInit {
  processPermissions = [
    'view',
    'create',
    'edit',
    'delete',
    'execute',
    'download'
  ]
  teamForm: FormGroup
  processActions: any[] = []
  processWidgets: any[] = []
  selectedPermissions: any[] = []
  selectedActions: Action[] = []
  selectedWidgets: any[] = []
  @Output() addTeamEvent: EventEmitter<any> = new EventEmitter<any>()

  constructor(
    public dialogRef: MatDialogRef<TeamCreateDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: addDialogData,
    private httpClient: HttpClient,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit() {
    this.teamForm = this.formBuilder.group({
      title: ['', Validators.required]
    })
    this.processActions = this.data.actions
    this.processWidgets = this.data.widgets
  }

  toggleAction(action) {
    const actionId = action.option.value
    const index = _.findIndex(this.selectedActions, { _id: actionId })

    if (index >= 0) {
      this.selectedActions.splice(index, 1)
    } else {
      const newAction: Action = {
        _id: actionId,
        bulk: false
      }
      this.selectedActions.push(newAction)
    }
  }

  toggleBulk(actionId, event) {
    const action = this.selectedActions.find(action => action._id == actionId)
    if (!action) return
    if (event.checked) action.bulk = true
    else action.bulk = false
  }

  toggleWidget(widget) {
    const widgetId = widget.option.value

    const index = this.selectedWidgets.indexOf(widgetId)

    if (index >= 0) {
      this.selectedWidgets.splice(index, 1)
    } else {
      this.selectedWidgets.push(widgetId)
    }
  }

  togglePermission(permission): void {
    const data = permission.option.value
    const index = this.selectedPermissions.indexOf(data)

    if (index >= 0) {
      this.selectedPermissions.splice(index, 1)
    } else {
      this.selectedPermissions.push(data)
    }
  }

  onNoClick(): void {
    this.dialogRef.close()
  }

  onSubmit() {
    const title = this.teamForm.value.title
    const team = {
      title,
      actions: this.selectedActions,
      permissions: this.selectedPermissions,
      widgets: this.selectedWidgets
    }
    this.addTeamEvent.emit(team)
  }
}
