import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core'

@Component({
  selector: 'process-team-card',
  templateUrl: './process-team-card.component.html',
  styleUrls: ['./process-team-card.component.css']
})
export class ProcessTeamCardComponent implements OnInit {
  @Input() processTeam
  @Output() teamOpen = new EventEmitter<String>()
  @Output() removeTeam = new EventEmitter<String>()
  constructor() {}

  ngOnInit() {}

  sendToParent(teamId) {
    this.teamOpen.emit(teamId)
  }

  deleteTeam(teamId) {
    this.removeTeam.emit(teamId)
  }
}
