import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'app-location-field',
  templateUrl: './location-field.component.html',
  styleUrls: ['./location-field.component.css']
})
export class LocationFieldComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
