import { ActivatedRoute } from '@angular/router'
import {
  Component,
  OnInit,
  Input,
  ChangeDetectorRef,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  AfterViewInit,
  AfterContentInit,
  OnDestroy
} from '@angular/core'
import { FormGroup, FormBuilder, Validators } from '@angular/forms'
import { RecordViewService } from '../../record-view.service'
import { MatDialog } from '@angular/material'
import { AddCommentDailogComponent } from '../add-comment-dailog/add-comment-dailog.component'
import { BehaviorSubject, Observable, Subscription } from 'rxjs'
import { ProcessRecordsService } from '../../../process-records.service'
import { LoadingDialogBoxComponent } from '../../../loading-dialog-box-component/loading-dialog-box-component.component'
import { SnackbarService } from 'src/app/snackbar.service'

@Component({
  selector: 'record-comments',
  templateUrl: './record-comments.component.html',
  styleUrls: ['./record-comments.component.css']
})
export class RecordCommentsComponent implements OnInit, AfterViewInit {
  commentForm: FormGroup
  @Input() processId: string
  @Input() recordId: string
  commentLevels: any[] = ['low', 'medium', 'high', 'critical']
  selectedValue: string = 'low'
  commentBox: EventEmitter<boolean> = new EventEmitter<boolean>(false)
  autofocus: boolean = true
  hideComment: boolean = true
  clicked = false

  @ViewChild('writeComment', { static: false }) writeComment: ElementRef

  @Input() recordData: any
  recordComments: any
  @Output() loading: EventEmitter<any> = new EventEmitter<any>()
  autoFocusSubscription
  constructor(
    private _formBuilder: FormBuilder,
    private recordService: RecordViewService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private processRecordService: ProcessRecordsService,
    private _snackbar: SnackbarService
  ) {}

  ngOnInit() {
    this.getComments()

    this.commentBox.subscribe(flag => {
      if (flag) {
        this.createCommentForm()
      } else {
        this.createCommentForm()
        // this.commentForm.reset();
        // this.commentForm.markAsUntouched();
      }
    })
  }

  getComments() {
    this.recordService
      .getComments(this.processId, this.recordId)
      .subscribe(data => {
        this.recordComments = data
        this.processRecordService.setLoading(false)
        this.dialog.closeAll()
      })
  }

  ngAfterViewInit() {}

  createCommentForm() {
    this.commentForm = this._formBuilder.group({
      type: this._formBuilder.control('', Validators.required),
      message: this._formBuilder.control('', Validators.required)
    })
  }

  addComment() {
    const dialogRef = this.dialog.open(AddCommentDailogComponent, {
      width: '350px',
      height: '46%',
      data: {
        commentLevels: this.commentLevels
      }
    })
  }

  getColor(level) {
    switch (level) {
      case 'low':
        return
      case 'medium':
        return '#fbb13c'
      case 'high':
        return '#1ac3d8'
      case 'critical':
        return '#ee3042'
      case 'system':
        return '#228B22'
      case 'execute':
        return '#ee3042'
      case 'request':
        return '#1ac3d8'
    }
  }

  getTextColor(level) {
    if (level != 'low') return 'white'
    return
  }

  openCommentBox() {
    this.commentBox.emit(true)
  }

  collapseCommentBox(event) {
    if (event) {
      this.refreshComments()
    }
    this.commentBox.emit(false)
  }

  refreshComments() {
    const dialogRef = this.dialog.open(LoadingDialogBoxComponent, {
      disableClose: true,
      panelClass: 'loadingDialog',
      // height: '30%',
      data: {}
    })
    this.processRecordService.setLoading(true)
    this.getComments()
    this._snackbar.showSnackbar('Comments Refreshed.', 'success')
  }

  active() {
    this.clicked = true
  }
}
