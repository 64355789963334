import { Component, OnInit } from '@angular/core'
import { FormBuilder } from '@angular/forms'
import { ModulesService } from '../../modules.service'
import { Observable } from 'rxjs'

@Component({
  selector: 'app-mark-delivered',
  templateUrl: './mark-delivered.component.html',
  styleUrls: ['./mark-delivered.component.css']
})
export class MarkDeliveredComponent implements OnInit {
  orderId
  open: boolean = false
  orderIdControl
  orderItems: Observable<any>
  selectedOptions
  constructor(
    private fb: FormBuilder,
    private modulesService: ModulesService
  ) {}

  ngOnInit() {
    this.orderIdControl = this.fb.control('')
  }

  submitForm() {
    this.orderId = this.orderIdControl.value
    this.open = true
    this.orderItems = this.modulesService.getItemsByOrderId(this.orderId)
  }

  submitDeliveredItems() {
    let markDeliveredItems = this.selectedOptions.map(
      selectedItem => selectedItem._id
    )
  }
}
