import { Component, OnInit, Inject, EventEmitter } from '@angular/core'
import { MAT_DIALOG_DATA } from '@angular/material'

@Component({
  selector: 'confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.css']
})
export class ConfirmationDialogComponent implements OnInit {
  public confirmationEvent: EventEmitter<any> = new EventEmitter<any>()

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {}

  ngOnInit() {}

  onConfirmation() {
    this.confirmationEvent.emit()
  }
}
