import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core'
import { FormControl, FormGroup } from '@angular/forms'
import {
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS
} from '@angular/material-moment-adapter'
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE
} from '@angular/material/core'

import * as _moment from 'moment'
import { distinctUntilChanged, debounce } from 'rxjs/operators'
const moment = _moment
export const DATE_FORMATS = {
  parse: {
    dateInput: 'YYYY-MM-DD'
  },
  display: {
    dateInput: 'DD-MMM-YYYY',
    monthYearLabel: 'YYYY-MM-DD',
    dateA11yLabel: 'YYYY-MM-DD',
    monthYearA11yLabel: 'YYYY-MM-DD'
  }
}

@Component({
  selector: 'date-field',
  templateUrl: './date-field.component.html',
  styleUrls: ['./date-field.component.css'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE]
    },
    { provide: MAT_DATE_FORMATS, useValue: DATE_FORMATS },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: false } }
  ]
})
export class DateFieldComponent implements OnInit {
  @Input() fieldInstance: FormControl
  @Input() label: string
  @Input('fieldIndex') index: number
  @Input() field: any
  @Input() create?: boolean = false
  @Input() isRequired: boolean = false
  @Input() inputValidations: any[] = []
  @Input() readOnly: boolean = false
  @Input() path: string
  @Input() isAdmin: boolean = false

  constructor(private cd: ChangeDetectorRef) {}

  ngOnInit() {
    // this.fieldInstance.valueChanges
    // 	.pipe(distinctUntilChanged())
    // 	.subscribe((data: _moment.Moment) => {
    // 		const date = moment(data).format('YYYY-MM-DD');
    // 		this.fieldInstance.patchValue(date);
    // 	})
  }

  changeDateFormat(event) {
    const date = moment(event.value)
    if (date.isValid()) this.fieldInstance.setValue(date.format('YYYY-MM-DD'))
  }
}
