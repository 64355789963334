import { Observable } from 'rxjs'
import { Component, OnInit } from '@angular/core'
import { FirService } from '../fir.service'

@Component({
  selector: 'fir-count',
  templateUrl: './fir-count.component.html',
  styleUrls: ['./fir-count.component.css']
})
export class FirCountComponent implements OnInit {
  openFirsCount: Observable<any>
  totalFirCount: Observable<any>
  closedFirsCount: Observable<any>
  forwardFirsCount: Observable<any>

  constructor(private firService: FirService) {}

  ngOnInit() {
    this.openFirsCount = this.firService.getOpenFirCounts()
    this.closedFirsCount = this.firService.getClosedFirCounts()
    this.forwardFirsCount = this.firService.getForwardedFirCounts()
    this.totalFirCount = this.firService.getTotalFirCounts()
  }
}
