import { Component, OnInit } from '@angular/core'
import { FormGroup, FormBuilder, Validators, ValidatorFn } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import { AuthService } from '../auth.service'
import { SnackbarService } from 'src/app/snackbar.service'
import { MatDialog } from '@angular/material'
import { LoadingDialogBoxComponent } from '../../process/process-records/loading-dialog-box-component/loading-dialog-box-component.component'
import { SESSION_STORAGE } from 'src/app/app.constant'
import { Title } from '@angular/platform-browser'

@Component({
  selector: 'reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {
  token
  userId
  resetPasswordForm: FormGroup
  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private authService: AuthService,
    private _snackbar: SnackbarService,
    private router: Router,
    private dialog: MatDialog,
    private titleService: Title
  ) {
    if (localStorage.getItem(SESSION_STORAGE.TOKEN)) {
      this.router.navigate(['dashboard'])
    }
    this.titleService.setTitle('Rmp-change-password')
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.token = params.token
      this.userId = params.userId
    })
    this.resetPasswordForm = this.fb.group(
      {
        password: this.fb.control('', [
          Validators.minLength(8),
          Validators.required
        ]),
        confirmPassword: this.fb.control('', [
          Validators.minLength(8),
          Validators.required
        ])
      },
      { validator: this.checkPasswords() }
    )
  }

  changePassword() {
    this.openDialog()
    let body = {
      password: this.resetPasswordForm.controls.password.value,
      token: this.token,
      userId: this.userId
    }
    this.authService.changePassword(body).subscribe(
      data => {
        this._snackbar.showSnackbar(data.msg, 'success')
        this.router.navigate(['login'])
        this.dialog.closeAll()
      },
      err => {
        this.dialog.closeAll()
      }
    )
  }

  checkPasswords(): ValidatorFn {
    return group => {
      let newPasswordControl = group.get('password')
      let confirmPasswordControl = group.get('confirmPassword')
      if (
        newPasswordControl.value.length >= 8 &&
        confirmPasswordControl.value.length >= 8 &&
        newPasswordControl.value != confirmPasswordControl.value
      ) {
        return { mismatch: true }
      } else {
        return null
      }
    }
  }

  openDialog() {
    const dialogRef = this.dialog.open(LoadingDialogBoxComponent, {
      disableClose: true,
      panelClass: 'loadingDialog'
    })
  }
}
