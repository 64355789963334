import { NgModule } from '@angular/core'
import { CollectionsComponent } from './collections/collections.component'
import { FinanceComponent } from './finance/finance.component'
import { SalesComponent } from './sales/sales.component'
import { HrComponent } from './hr/hr.component'
import { MaterialModule } from '../material.module'
import { CommonModule } from '@angular/common'
import { DepartmentRoutingModule } from './department-routing.module'
import { ChangeOrderItemsComponent } from './sales/change-order-items/change-order-items.component'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { ModulesService } from './modules.service'
import { FormFieldComponent } from './sales/change-order-items/form-field/form-field.component'
import { OutForDeliveryComponent } from './sales/out-for-delivery/out-for-delivery.component'
import { MatOptionFormComponent } from './sales/out-for-delivery/mat-option-form/mat-option-form.component'
import { MarkDeliveredComponent } from './sales/mark-delivered/mark-delivered.component'
import { ProcessComponent } from './process/process.component'
import { BreadcrumbsComponent } from './breadcrumbs/breadcrumbs.component'
import { WarehouseComponent } from './process/warehouse/warehouse.component'
import { ZXingScannerModule } from './public_api'
import { BarcodeScannerComponent } from './sales/out-for-delivery/barcode-scanner/barcode-scanner.component'
import { ChangeStatusComponent } from './breadcrumbs/change-status/change-status.component'
import { ShipmentComponent } from './shipment/shipment.component'
import { SalesListComponent } from './sales/sales-list/sales-list.component'
import { RecordCreateDialogComponent } from './process/process-records/record-create-dialog/record-create-dialog.component'
import { RecordChildCreateComponent } from './process/process-records/record-create-dialog/record-child-create/record-child-create.component'
import { DeliveryHelperComponent } from './sales/out-for-delivery/delivery-helper/delivery-helper.component'
import { CollectionsListComponent } from './collections/collections-list/collections-list.component'
import { OutForPickupComponent } from './collections/out-for-pickup/out-for-pickup.component'
import { PlanForPickupComponent } from './collections/plan-for-pickup/plan-for-pickup.component'
import { EscaltionComponent } from './escaltion/escaltion.component'
import { DateTimeFieldComponent } from './process/process-records/field/date-time-field/date-time-field.component'
import { InventoryComponent } from './inventory/inventory.component'
import { PurchaseOrderComponent } from './inventory/purchase-order/purchase-order.component'
import { VendorComponent } from './inventory/vendor/vendor.component'
import { CustomListComponent } from './shared-customs/custom-list/custom-list.component'
import { CustomCommentComponent } from './shared-customs/custom-comment/custom-comment.component'
import { VendorPurchasesComponent } from './inventory/vendor/vendor-purchases/vendor-purchases.component'
import { VendorOverviewComponent } from './inventory/vendor/vendor-overview/vendor-overview.component'
import { VendorMinorDashboardComponent } from './inventory/vendor/vendor-minor-dashboard/vendor-minor-dashboard.component'
import { VendorLogComponent } from './inventory/vendor/vendor-log/vendor-log.component'
import { PoBillComponent } from './inventory/purchase-order/po-bill/po-bill.component'
import { PoReceiveComponent } from './inventory/purchase-order/po-receive/po-receive.component'
import { PoViewComponent } from './inventory/purchase-order/po-view/po-view.component'
import { PoFormComponent } from './inventory/purchase-order/po-form/po-form.component'
import { PoSubFormComponent } from './inventory/purchase-order/po-form/po-sub-form/po-sub-form.component'
import { VendorViewComponent } from './inventory/vendor/vendor-view/vendor-view.component'
import { VendorFormComponent } from './inventory/vendor/vendor-form/vendor-form.component'
import { VendorSubFormComponent } from './inventory/vendor/vendor-form/vendor-sub-form/vendor-sub-form.component'
import { FinanceListComponent } from './finance/finance-list/finance-list.component'
import { UpdateEcsInformationComponent } from './finance/update-ecs-information/update-ecs-information.component'
import { ProcessHeadingComponent } from './process-heading/process-heading.component'
import { PickupApprovalComponent } from './collections/pickup-approval/pickup-approval.component'

@NgModule({
  imports: [
    ZXingScannerModule,
    MaterialModule,
    CommonModule,
    DepartmentRoutingModule,
    FormsModule,
    ReactiveFormsModule
  ],
  declarations: [
    ProcessComponent,
    ChangeOrderItemsComponent,
    SalesComponent,
    HrComponent,
    FinanceComponent,
    ShipmentComponent,
    CollectionsComponent,
    FormFieldComponent,
    OutForDeliveryComponent,
    MatOptionFormComponent,
    MarkDeliveredComponent,
    BreadcrumbsComponent,
    WarehouseComponent,
    ChangeStatusComponent,
    MatOptionFormComponent,
    BarcodeScannerComponent,
    ShipmentComponent,
    SalesListComponent,
    DeliveryHelperComponent,
    CollectionsListComponent,
    OutForPickupComponent,
    PlanForPickupComponent,
    EscaltionComponent,
    InventoryComponent,
    PurchaseOrderComponent,
    VendorComponent,
    CustomListComponent,
    CustomCommentComponent,
    VendorPurchasesComponent,
    VendorOverviewComponent,
    VendorMinorDashboardComponent,
    VendorLogComponent,
    PoBillComponent,
    PoReceiveComponent,
    PoViewComponent,
    PoFormComponent,
    PoSubFormComponent,
    VendorViewComponent,
    VendorFormComponent,
    VendorSubFormComponent,
    FinanceListComponent,
    UpdateEcsInformationComponent,
    ProcessHeadingComponent,
    PickupApprovalComponent
  ],
  entryComponents: [
    SalesComponent,
    HrComponent,
    FinanceComponent,
    ShipmentComponent,
    EscaltionComponent,
    CollectionsComponent,
    BarcodeScannerComponent
  ],
  providers: [ModulesService],
  exports: [ProcessHeadingComponent]
})
export class DepartmentModule {}
