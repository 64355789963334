import { Component, OnInit, Inject, createPlatformFactory } from '@angular/core'
import { DialogData } from '../../process-team/team-dialog/team-dialog.component'
import { MAT_DIALOG_DATA } from '@angular/material'
import { FormBuilder } from '@angular/forms'
import * as _ from 'lodash'

@Component({
  selector: 'app-show-widget-item',
  templateUrl: './show-widget-item.component.html',
  styleUrls: ['./show-widget-item.component.css']
})
export class ShowWidgetItemComponent implements OnInit {
  rowData
  form
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder
  ) {}

  ngOnInit() {
    this.rowData = this.data.formData
    this.form = this.fb.group({})

    Object.entries(this.rowData).forEach(keyValues => {
      this.createForm(this.form, keyValues)
    })
  }

  createForm(formInstance, keyValues) {
    const [key, value] = keyValues

    if (_.isArray(value)) {
      let fieldArray = this.fb.array([])
      value.forEach(fieldParams => {
        let fieldGroup = this.fb.group({})
        Object.entries(fieldParams).forEach(fieldKeyValue => {
          this.createForm(fieldGroup, fieldKeyValue)
        })
        fieldArray.push(fieldGroup)
      })
      formInstance.addControl(key, fieldArray)
    } else if (!_.isArray(value) && _.isObject(value)) {
      if (value['type'] == 'group') {
        let fieldArray = this.fb.array([])
        value['fields'].forEach(fieldParams => {
          let fieldGroup = this.fb.group({})
          Object.entries(fieldParams).forEach(fieldKeyValue => {
            this.createForm(fieldGroup, fieldKeyValue)
          })
          fieldArray.push(fieldGroup)
        })
        formInstance.addControl(key, fieldArray)
      } else {
        let fieldArray1 = this.fb.array([])
        let fieldGroup1 = this.fb.group({})
        Object.entries(value).forEach(fieldKeyValue => {
          this.createForm(fieldGroup1, fieldKeyValue)
        })
        fieldArray1.push(fieldGroup1)
        formInstance.addControl(key, fieldArray1)
      }
    } else {
      formInstance.addControl(key, this.fb.control(value))
    }
  }

  saveForm() {}
}
