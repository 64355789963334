import { CameraModule } from './shared/components/camera/camera.module'
import { FirService } from './core/components/fir/fir.service'
import { FirTableComponent } from './core/components/fir/fir-table/fir-table.component'
import { FirViewComponent } from './core/components/fir/fir-view/fir-view.component'
import { FirListComponent } from './core/components/fir/fir-list/fir-list.component'
import { FirForwardComponent } from './core/components/fir/fir-forward/fir-forward.component'
import { FirCountComponent } from './core/components/fir/fir-count/fir-count.component'
import { FirComponent } from './core/components/fir/fir.component'
import { ProcessTeamComponent } from './modules/process/process-manage/process-team/process-team.component'
import { ProcessStateCardComponent } from './modules/process/process-manage/process-state/process-state-card/process-state-card.component'
import { ProcessFormPrimitiveComponent } from './modules/process/process-manage/process-form/process-form-primitive/process-form-primitive.component'
import { ProcessFormFieldCardComponent } from './modules/process/process-manage/process-form/process-form-field-card/process-form-field-card.component'
import { ProcessFormDialogComponent } from './modules/process/process-manage/process-form/process-form-dialog/process-form-dialog.component'
import { ProcessActionCardComponent } from './modules/process/process-manage/process-action/process-action-card/process-action-card.component'
import { ProcessActionComponent } from './modules/process/process-manage/process-action/process-action.component'
import { RecordChildViewDialogComponent } from './modules/process/process-records/record-view/record-child-view-dialog/record-child-view-dialog.component'
import { RecordViewService } from './modules/process/process-records/record-view/record-view.service'
import { ConfirmationDialogComponent } from './modules/process/process-records/confirmation-dialog/confirmation-dialog.component'
import { YesNoFieldComponent } from './modules/process/process-records/field/yes-no-field/yes-no-field.component'
import { TextAreaFieldComponent } from './modules/process/process-records/field/text-area-field/text-area-field.component'
import { StringFieldComponent } from './modules/process/process-records/field/string-field/string-field.component'
import { SelectFieldComponent } from './modules/process/process-records/field/select-field/select-field.component'
import { PhotoFieldComponent } from './modules/process/process-records/field/photo-field/photo-field.component'
import { NumberFieldComponent } from './modules/process/process-records/field/number-field/number-field.component'
import { MultiSelectFieldComponent } from './modules/process/process-records/field/multi-select-field/multi-select-field.component'
import { LocationFieldComponent } from './modules/process/process-records/field/location-field/location-field.component'
import { ListFieldComponent } from './modules/process/process-records/field/list-field/list-field.component'
import { GroupFieldComponent } from './modules/process/process-records/field/group-field/group-field.component'
import { FileFieldComponent } from './modules/process/process-records/field/file-field/file-field.component'
import { EmailFieldComponent } from './modules/process/process-records/field/email-field/email-field.component'
import { DateRangeFieldComponent } from './modules/process/process-records/field/date-range-field/date-range-field.component'
import { DateFieldComponent } from './modules/process/process-records/field/date-field/date-field.component'
import { AddressFieldComponent } from './modules/process/process-records/field/address-field/address-field.component'
import { FieldComponent } from './modules/process/process-records/field/field.component'
import { RequestDialogComponent } from './modules/process/process-records/record-view/request-dialog/request-dialog.component'
import { RecordCommentsComponent } from './modules/process/process-records/record-view/record-info/record-comments/record-comments.component'
import { RecordRequestsComponent } from './modules/process/process-records/record-view/record-info/record-requests/record-requests.component'
import { RecordInfoComponent } from './modules/process/process-records/record-view/record-info/record-info.component'
import { RecordChildSingleComponent } from './modules/process/process-records/record-view/record-child-single/record-child-single.component'
import { RecordChildListComponent } from './modules/process/process-records/record-view/record-child-list/record-child-list.component'
import { BrowserModule } from '@angular/platform-browser'
import { NgModule } from '@angular/core'
import { AppComponent } from './app.component'
import { MaterialModule } from './material.module'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { Routes, RouterModule, ActivatedRouteSnapshot } from '@angular/router'
import { HeaderComponent } from './core/components/header/header.component'
import { NavigationComponent } from './core/components/navigation/navigation.component'
import { NavToggleService } from './core/services/navtoggle.service'
import { LayoutModule } from '@angular/cdk/layout'
import { ParamChangeService } from './shared/services/param-change.service'
import { DepartmentComponent } from './department/department.component'
import { DepartmentContentComponent } from './department/department-content/department-content.component'
import { FooterComponent } from './core/components/footer/footer.component'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { HttpClientModule } from '@angular/common/http'
import { AuthService } from './modules/auth/auth.service'
import { AppRoutingModule } from './app-routing.module'
import { ConfigService } from './shared/services/config.service'
import { LoginComponent } from './modules/auth/login/login.component'
import { NotifierModule, NotifierOptions } from 'angular-notifier'
import { DashboardComponent } from './modules/auth/dashboard/dashboard.component'
import { ProcessOverviewComponent } from './modules/process/process-overview/process-overview.component'
import { ProcessAnalyticsComponent } from './modules/process/process-analytics/process-analytics.component'
import { ProcessManageComponent } from './modules/process/process-manage/process-manage.component'
import { ProcessRecordsComponent } from './modules/process/process-records/process-records.component'
import { RecordViewComponent } from './modules/process/process-records/record-view/record-view.component'
import { RecordListComponent } from './modules/process/process-records/record-list/record-list.component'
import { CreateRecordComponent } from './modules/process/process-records/create-record/create-record.component'
import { FilterDialogBoxComponent } from './modules/process/process-records/filter-dialog-box/filter-dialog-box.component'
import { FieldDialogBoxComponent } from './modules/process/process-records/field-dialog-box/field-dialog-box.component'
import { StatusRenderComponent } from './modules/process/process-records/status-render/status-render.component'
import { ReverseArray } from './shared/pipes/reverse-array.pipe'
import { WarehouseDataService } from './modules/process/warehouse-data.service'
import { FileViewDialogComponent } from './modules/process/process-records/file-view-dialog/file-view-dialog.component'
import { RecordChildFormComponent } from './modules/process/process-records/record-view/record-child-form/record-child-form.component'
import { ProcessTeamCardComponent } from './modules/process/process-manage/process-team/process-team-card/process-team-card.component'
import { TeamDialogComponent } from './modules/process/process-manage/process-team/team-dialog/team-dialog.component'
import { ProcessFormComponent } from './modules/process/process-manage/process-form/process-form.component'
import { ProcessStateComponent } from './modules/process/process-manage/process-state/process-state.component'
import { StatusCreateDialogComponent } from './modules/process/process-manage/process-state/status-create-dialog/status-create-dialog.component'
import { ProcessWidgetComponent } from './modules/process/process-manage/process-widget/process-widget.component'
import { WidgetCreateDialogComponent } from './modules/process/process-manage/process-widget/widget-create-dialog/widget-create-dialog.component'
import { ChartsModule } from 'ng2-charts'
import { ProcessFilterComponent } from './modules/process/process-records/process-filter/process-filter.component'
import { ClipboardModule } from 'ngx-clipboard'
import { ProcessStateFilter } from './shared/pipes/process-state-filter.pipe'
import { RecordActionFilter } from './shared/pipes/record-action-filter.pipe'
import { ProcessService } from './modules/process/process.service'
import { AddCommentDailogComponent } from './modules/process/process-records/record-view/record-info/add-comment-dailog/add-comment-dailog.component'
import { LoadingDialogBoxComponent } from './modules/process/process-records/loading-dialog-box-component/loading-dialog-box-component.component'
import { AddCommentComponent } from './modules/process/process-records/record-view/add-comment/add-comment.component'
import { AddItemDialogComponent } from './modules/process/process-records/record-view/add-item-dialog/add-item-dialog.component'
import { TeamCreateDialogComponent } from './modules/process/process-manage/process-team/team-create-dialog/team-create-dialog.component'
import { AuthGuardService } from './modules/auth/auth-guard.service'
import { JwtModule } from '@auth0/angular-jwt'
import { SESSION_STORAGE } from './app.constant'
import {
  RecordChildViewComponent,
  DATA
} from './modules/process/process-records/record-view/record-child-view/record-child-view.component'
import { ShowDialogComponent } from './modules/process/show-dialog/show-dialog.component'
import { StatusBarComponent } from './modules/process/process-records/record-view/status-bar/status-bar.component'
import { ProcessFormViewComponent } from './modules/process/process-manage/process-form/process-form-view/process-form-view.component'
import { ShowWidgetItemComponent } from './modules/process/process-manage/process-widget/show-widget-item/show-widget-item.component'
import { PushToRmpDialogComponent } from './core/components/header/push-to-rmp-dialog/push-to-rmp-dialog.component'
import { ForgotPasswordComponent } from './modules/auth/forgot-password/forgot-password.component'
import { ManageGuardService } from './manage-guard.service'
import { DepartmentModule } from './modules/department.module'
import { SalesComponent } from './modules/sales/sales.component'
import { FinanceComponent } from './modules/finance/finance.component'
import { HrComponent } from './modules/hr/hr.component'
import { CollectionsComponent } from './modules/collections/collections.component'
import { SharedService } from './shared/services/shared.service'
import { InterviewFormComponent } from './core/components/interview-form/interview-form.component'
import { InterviewChildFormComponent } from './core/components/interview-form/interview-child-form/interview-child-form.component'
import { MarkDeliveredFormComponent } from './core/components/mark-delivered-form/mark-delivered-form.component'
import { MarkPickedFormComponent } from './core/components/mark-picked-form/mark-picked-form.component'
import { StandaloneFormComponent } from './core/components/standalone-form/standalone-form.component'
import { MainContainerComponent } from './core/components/main-container/main-container.component'
import { LogoutComponent } from './core/components/logout/logout.component'
import { SetRentalsComponent } from './core/components/set-rentals/set-rentals.component'
import { RentalListComponent } from './core/components/set-rentals/rental-list/rental-list.component'
import { EmployeeComponent } from './modules/hr/employee/employee.component'
import { NgOtpInputModule } from 'ng-otp-input'
import { MatTreeComponent } from './core/components/standalone-form/mat-tree/mat-tree.component'
import { RecordCreateDialogComponent } from './modules/process/process-records/record-create-dialog/record-create-dialog.component'
import { RecordChildCreateComponent } from './modules/process/process-records/record-create-dialog/record-child-create/record-child-create.component'
import { NgxDocViewerModule } from 'ngx-doc-viewer'
import { InfiniteScrollModule } from 'ngx-infinite-scroll'
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker'
import { DateTimeFieldComponent } from './modules/process/process-records/field/date-time-field/date-time-field.component'
import { CustomListComponent } from './modules/shared-customs/custom-list/custom-list.component'
import { ResetPasswordComponent } from './modules/auth/reset-password/reset-password.component'
import { RegisterComponent } from './modules/auth/register/register.component'
import { ProfileComponent } from './modules/auth/profile/profile.component'
import { ChangePasswordComponent } from './modules/auth/profile/change-password/change-password.component'
import { ImageViewerModule } from '@hallysonh/ngx-imageviewer'
import {
  IMAGEVIEWER_CONFIG,
  ImageViewerConfig
} from '@hallysonh/ngx-imageviewer'
import { ProcessHeadingComponent } from './modules/process-heading/process-heading.component'

// import { PdfToBase64Module } from 'pdf-to-base64';
export function jwtTokenGetter() {
  return localStorage.getItem(SESSION_STORAGE.TOKEN)
}

const MY_IMAGEVIEWER_CONFIG: ImageViewerConfig = {
  buttonStyle: {
    bgStyle: '#B71C1C' // custom container's background style
  },
  width: 500,
  height: 600
}

const customNotifierOptions: NotifierOptions = {
  position: {
    horizontal: {
      position: 'left'
    },
    vertical: {
      position: 'bottom',
      distance: 0
    }
  },
  behaviour: {
    autoHide: 31536000,
    stacking: 20
  }
}

@NgModule({
  imports: [
    DepartmentModule,
    BrowserModule,
    BrowserAnimationsModule,
    MaterialModule,
    LayoutModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    AppRoutingModule,
    NotifierModule.withConfig(customNotifierOptions),
    NgxDocViewerModule,
    ChartsModule,
    ClipboardModule,
    NgOtpInputModule,
    CameraModule,
    // PdfToBase64Module,
    JwtModule.forRoot({
      config: {
        tokenGetter: jwtTokenGetter,
        whitelistedDomains: ['rentickle.co.in', 'localhost']
      }
    }),
    InfiniteScrollModule,
    NgxMaterialTimepickerModule,
    ImageViewerModule
  ],
  declarations: [
    AppComponent,
    HeaderComponent,
    NavigationComponent,
    ForgotPasswordComponent,
    DepartmentComponent,
    DepartmentContentComponent,
    FooterComponent,
    LoginComponent,
    DashboardComponent,
    ProcessRecordsComponent,
    RecordViewComponent,
    RecordListComponent,
    CreateRecordComponent,
    ProcessOverviewComponent,
    ProcessAnalyticsComponent,
    ProcessManageComponent,
    FilterDialogBoxComponent,
    FieldDialogBoxComponent,
    StatusRenderComponent,
    ReverseArray,
    RecordActionFilter,
    ProcessStateFilter,
    RecordChildListComponent,
    RecordChildSingleComponent,
    RecordInfoComponent,
    RecordRequestsComponent,
    RecordCommentsComponent,
    RequestDialogComponent,
    FieldComponent,
    AddressFieldComponent,
    DateFieldComponent,
    DateRangeFieldComponent,
    EmailFieldComponent,
    FileFieldComponent,
    GroupFieldComponent,
    ListFieldComponent,
    LocationFieldComponent,
    MultiSelectFieldComponent,
    NumberFieldComponent,
    PhotoFieldComponent,
    SelectFieldComponent,
    StringFieldComponent,
    TextAreaFieldComponent,
    YesNoFieldComponent,
    ConfirmationDialogComponent,
    FileViewDialogComponent,
    RecordChildViewDialogComponent,
    RecordChildFormComponent,
    ProcessActionComponent,
    ProcessActionCardComponent,
    ProcessFormDialogComponent,
    ProcessFormFieldCardComponent,
    ProcessFormPrimitiveComponent,
    ProcessStateCardComponent,
    ProcessTeamComponent,
    ProcessTeamCardComponent,
    TeamDialogComponent,
    ProcessFormComponent,
    ProcessStateComponent,
    StatusCreateDialogComponent,
    ProcessWidgetComponent,
    WidgetCreateDialogComponent,
    FirComponent,
    FirCountComponent,
    FirForwardComponent,
    FirListComponent,
    FirViewComponent,
    ProcessFilterComponent,
    FirTableComponent,
    AddCommentDailogComponent,
    LoadingDialogBoxComponent,
    AddCommentComponent,
    AddItemDialogComponent,
    TeamCreateDialogComponent,
    RecordChildViewComponent,
    ShowDialogComponent,
    StatusBarComponent,
    ProcessFormViewComponent,
    ShowWidgetItemComponent,
    PushToRmpDialogComponent,
    InterviewFormComponent,
    InterviewChildFormComponent,
    MarkDeliveredFormComponent,
    MarkPickedFormComponent,
    MatTreeComponent,
    StandaloneFormComponent,
    MainContainerComponent,
    LogoutComponent,
    SetRentalsComponent,
    RentalListComponent,
    EmployeeComponent,
    RecordCreateDialogComponent,
    RecordChildCreateComponent,
    DateTimeFieldComponent,
    ResetPasswordComponent,
    RegisterComponent,
    ProfileComponent,
    ChangePasswordComponent
  ],
  providers: [
    NavToggleService,
    ParamChangeService,
    AuthService,
    ConfigService,
    WarehouseDataService,
    RecordViewService,
    FirService,
    ProcessService,
    AuthGuardService,
    { provide: DATA, useValue: '' },
    ManageGuardService,
    SharedService,
    { provide: IMAGEVIEWER_CONFIG, useValue: '' }
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    FilterDialogBoxComponent,
    FieldDialogBoxComponent,
    RequestDialogComponent,
    AddCommentDailogComponent,
    RecordChildViewDialogComponent,
    FileViewDialogComponent,
    ProcessFormDialogComponent,
    ProcessTeamComponent,
    TeamDialogComponent,
    ProcessActionComponent,
    ProcessFormComponent,
    ProcessStateComponent,
    StatusCreateDialogComponent,
    ProcessWidgetComponent,
    WidgetCreateDialogComponent,
    RequestDialogComponent,
    ProcessFilterComponent,
    LoadingDialogBoxComponent,
    AddItemDialogComponent,
    TeamCreateDialogComponent,
    RecordChildSingleComponent,
    RecordChildListComponent,
    ShowDialogComponent,
    ShowWidgetItemComponent,
    PushToRmpDialogComponent,
    ConfirmationDialogComponent,
    SalesComponent,
    HrComponent,
    FinanceComponent,
    CollectionsComponent,
    RecordCreateDialogComponent,
    FirForwardComponent
  ]
})
export class AppModule {}
