import {
  Component,
  OnInit,
  Inject,
  EventEmitter,
  Output,
  ViewChild,
  QueryList,
  AfterViewInit
} from '@angular/core'
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatSnackBar,
  MatSlideToggle
} from '@angular/material'
import { FormControl } from '@angular/forms'
import { Observable } from 'rxjs'
import { startWith, map } from 'rxjs/operators'
import { environment } from 'src/environments/environment'
import { RecordViewService } from '../../../process-records/record-view/record-view.service'
import { HttpService } from 'src/app/core/services/http.service'
import { SnackbarService } from 'src/app/snackbar.service'

export interface DialogData {
  team: any
  actions: any[]
  widgets: any[]
  users: any[]
  processId: any
  teams: any[]
}

@Component({
  selector: 'app-team-dialog',
  templateUrl: './team-dialog.component.html',
  styleUrls: ['./team-dialog.component.css']
})
export class TeamDialogComponent implements OnInit {
  visible: boolean = true
  selectable: boolean = true
  removable: boolean = true
  addOnBlur: boolean = false
  teamHash = []
  teams

  public teamActions
  public teamWidgets = []
  public processActions
  public processWidgets
  public users = []
  public permissions = [
    'view',
    'create',
    'edit',
    'delete',
    'execute',
    'download'
  ]
  private teamId: string
  public restActions = []
  public restWidgets = []
  private baseUrl: string = null
  private userId: string = null
  private token: string
  @Output() removedAction = new EventEmitter<any>()
  @Output() addedAction = new EventEmitter<any>()
  @Output() removedWidget = new EventEmitter<any>()
  @Output() addedWidget = new EventEmitter<any>()
  @Output() removedPermission = new EventEmitter<any>()
  @Output() userAdded = new EventEmitter<any>()
  @Output() removedUser = new EventEmitter<any>()
  @Output() bulkInfoUpdated = new EventEmitter<any>()
  @ViewChild('widgetTag', { static: false }) matListToggle

  usersForm: FormControl
  filteredUsers: Observable<any>
  teamUsers = <any>[]
  selectedPermissions: any[] = []

  constructor(
    public dialogRef: MatDialogRef<TeamDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private httpService: HttpService,
    private recordService: RecordViewService,
    private snackbar: SnackbarService
  ) {
    this.usersForm = new FormControl()
    this.baseUrl = environment.baseURL
    this.token = JSON.parse(localStorage.getItem('x-auth-token'))
    this.filteredUsers = this.usersForm.valueChanges.pipe(
      startWith(''),
      map(user => (user ? this.filterUsers(user) : this.users.slice()))
    )
  }

  ngOnInit() {
    this.teamActions = this.data.team.actions
    this.teamWidgets = this.data.team.widgets
    this.processActions = this.data.actions
    this.processWidgets = this.data.widgets
    this.restActions = this.getUniqueObjects(
      this.teamActions,
      this.processActions
    )
    this.restWidgets = this.getUniqueObjects(
      this.teamWidgets,
      this.processWidgets
    )
    this.teamId = this.data.team['_id']
    this.users = this.data.users
    this.teams = this.data.teams.map(team => team._id)
    this.users.forEach(elem => {
      elem.teams = elem.teams.filter(team => this.teams.indexOf(team._id) >= 0)
      this.teamHash[elem.userName] = elem
    })
    this.selectedPermissions = this.data.team.permissions
  }

  isSelected(permission: string): boolean {
    const index = this.selectedPermissions.indexOf(permission)
    return index >= 0
  }

  togglePermission(permission: string): void {
    let index = this.selectedPermissions.indexOf(permission)

    if (index >= 0) {
      this.selectedPermissions.splice(index, 1)
    } else {
      this.selectedPermissions.push(permission)
    }

    this.updatePermission(this.selectedPermissions)
  }

  toggleUser(event) {
    const userId = event.option.value

    if (!event.option.selected) {
      this.removeUserFromTeam(userId)
    }
  }

  toggleAction(event) {
    const actionId = event.option.value

    if (!event.option.selected) this.removeActionFromTeam(actionId)
    else this.addActionInTeam(actionId)
  }

  toggleBulk(actionId, event) {
    const action = this.teamActions.find(action => action._id == actionId)
    if (!action) {
      this.recordService.showNotification('error', 'select the action first!')
      setTimeout(() => {
        event.source.checked = false
      }, 1000)
      return
    }
    if (event.checked) {
      this.updateActionBulkInfo(actionId, true)
    } else {
      this.updateActionBulkInfo(actionId, false)
    }
  }

  toggleWidget(event) {
    const widgetId = event.option.value

    if (!event.option.selected) this.removeWidgetFromTeam(widgetId)
    else this.addWidgetInTeam(widgetId)
  }

  filterUsers(name: string) {
    return this.users.filter(
      user => user.userName.toLowerCase().indexOf(name.toLowerCase()) == 0
    )
  }

  getUniqueObjects(layer, reference) {
    if (reference == undefined || reference == null) {
      return []
    }
    return reference.filter(
      refAction => !layer.some(layerAction => refAction._id == layerAction._id)
    )
  }

  onNoClick(): void {
    this.dialogRef.close()
  }

  updatePermission(permissions: String[]) {
    this.httpService
      .post(`${this.baseUrl}/teams/${this.teamId}/setpermission`, {
        permissions
      })
      .subscribe(res => {
        this.snackbar.showSnackbar(res['msg'], 'success')
      })
  }

  addActionInTeam(actionId: string) {
    this.httpService
      .post(`${this.baseUrl}/teams/${this.teamId}/addaction/${actionId}`, {})
      .subscribe(res => {
        this.addedAction.emit(actionId)
        this.snackbar.showSnackbar(res['msg'], 'success')
      })
  }

  removeActionFromTeam(actionId: string) {
    this.httpService
      .post(`${this.baseUrl}/teams/${this.teamId}/removeaction/${actionId}`, {})
      .subscribe(res => {
        this.removedAction.emit(actionId)
        this.snackbar.showSnackbar(res['msg'], 'success')
      })
  }

  addWidgetInTeam(widgetId: string) {
    this.httpService
      .post(`${this.baseUrl}/teams/${this.teamId}/addwidget/${widgetId}`, {})
      .subscribe(res => {
        this.addedWidget.emit(widgetId)
        this.snackbar.showSnackbar(res['msg'], 'success')
      })
  }

  removeWidgetFromTeam(widgetId: string) {
    this.httpService
      .post(`${this.baseUrl}/teams/${this.teamId}/removewidget/${widgetId}`, {})
      .subscribe(res => {
        this.removedWidget.emit(widgetId)
        this.snackbar.showSnackbar(res['msg'], 'success')
      })
  }

  removeUserFromTeam(userId: string) {
    this.httpService
      .post(`${this.baseUrl}/teams/${this.teamId}/removeuser/${userId}`, {})
      .subscribe(res => {
        this.removedUser.emit(userId)
        this.snackbar.showSnackbar(res['msg'], 'success')
      })
  }

  addUserInTeam() {
    this.userSelected(this.usersForm.value)
    this.httpService
      .post(`${this.baseUrl}/teams/${this.teamId}/adduser/${this.userId}`, {})
      .subscribe(res => {
        this.usersForm.reset()
        this.userAdded.emit(this.userId)
        this.snackbar.showSnackbar(res['msg'], 'success')
      })
  }

  removePermission(permission) {
    this.httpService
      .post(`${this.baseUrl}/teams/${this.teamId}/removepermission`, {
        permission
      })
      .subscribe(res => {
        this.removedPermission.emit(permission)
        this.snackbar.showSnackbar(res['msg'], 'success')
      })
  }

  userSelected(userName) {
    const user = this.users.find(user => user.userName == userName)
    this.userId = user._id
  }

  updateActionBulkInfo(actionId, bulk) {
    this.httpService
      .post(`${this.baseUrl}/teams/${this.teamId}/updatebulk`, {
        actionId,
        bulk
      })
      .subscribe(res => {
        this.bulkInfoUpdated.emit({ actionId, bulk })
        this.snackbar.showSnackbar(res['msg'], 'success')
      })
  }
}
