import { environment } from 'src/environments/environment'
import { Component, OnInit } from '@angular/core'
import { ModulesService } from '../modules.service'
import { Router } from '@angular/router'

@Component({
  selector: 'app-shipment',
  templateUrl: './shipment.component.html',
  styleUrls: ['./shipment.component.css']
})
export class ShipmentComponent implements OnInit {
  departmentId = environment.config.shipment.id

  constructor(private moduleService: ModulesService, private router: Router) {}

  ngOnInit() {}
}
