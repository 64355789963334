import {
  Component,
  OnInit,
  ViewChild,
  ViewContainerRef,
  ComponentFactoryResolver,
  ComponentRef
} from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { Title } from '@angular/platform-browser'
import { AuthService } from 'src/app/modules/auth/auth.service'
import { SalesComponent } from 'src/app/modules/sales/sales.component'
import { HrComponent } from 'src/app/modules/hr/hr.component'
import { CollectionsComponent } from 'src/app/modules/collections/collections.component'
import { FinanceComponent } from 'src/app/modules/finance/finance.component'
import { AppModule } from 'src/app/app.module'

@Component({
  selector: 'app-department-content',
  templateUrl: './department-content.component.html',
  styleUrls: ['./department-content.component.css']
})
export class DepartmentContentComponent implements OnInit {
  departmentName: string
  @ViewChild('departmentContainer', { read: ViewContainerRef, static: false })
  entry
  constructor(
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private authService: AuthService,
    private resolver: ComponentFactoryResolver
  ) {
    this.activatedRoute.params.subscribe((data: any) => {
      this.authService.findDeptbyId(data.deptId).subscribe(processInfo => {
        this.titleService.setTitle('Rmp-' + processInfo.title)
        this.departmentName = processInfo.title
        this.createComponent(processInfo)
      })
    })
  }

  ngOnInit() {}

  createComponent(department) {
    department = department.title
    this.entry.clear()
    let departments = {
      HR: HrComponent,
      Sales: SalesComponent,
      Collections: CollectionsComponent,
      Finance: FinanceComponent
    }
    const factory = this.resolver.resolveComponentFactory(
      departments[department]
    )
    const componentRef = this.entry.createComponent(factory)
  }
}
