import { Component, OnInit } from '@angular/core'
import { FormGroup, FormControl, Validators } from '@angular/forms'
import {
  BreakpointObserver,
  Breakpoints,
  BreakpointState
} from '@angular/cdk/layout'
import { HttpClient } from '@angular/common/http'
import { AuthService } from '../auth.service'
import { Router } from '@angular/router'
import { Title } from '@angular/platform-browser'
import { SESSION_STORAGE } from 'src/app/app.constant'
import { SnackbarService } from 'src/app/snackbar.service'
import { MatDialog } from '@angular/material'
import { LoadingDialogBoxComponent } from '../../process/process-records/loading-dialog-box-component/loading-dialog-box-component.component'

@Component({
  selector: 'register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {
  profileForm
  constructor(
    private authService: AuthService,
    private titleService: Title,
    private router: Router,
    private _snackbar: SnackbarService,
    private dialog: MatDialog
  ) {
    if (localStorage.getItem(SESSION_STORAGE.TOKEN)) {
      this.router.navigate(['dashboard'])
    }
    this.titleService.setTitle('Rmp-register')
  }

  ngOnInit() {
    this.profileForm = new FormGroup({
      firstname: new FormControl(null, Validators.required),
      lastname: new FormControl(null, Validators.required),
      email: new FormControl(null, [Validators.required, Validators.email]),
      password: new FormControl(null, [
        Validators.required,
        Validators.minLength(6)
      ])
    })
  }

  onRegister() {
    this.openDialog()
    this.authService.registerUser(this.profileForm.value).subscribe(
      data => {
        this._snackbar.showSnackbar(data.msg, 'success')
        this.dialog.closeAll()
        this.router.navigate(['login'])
      },
      error => {
        this.dialog.closeAll()
      }
    )
  }

  openDialog() {
    const dialogRef = this.dialog.open(LoadingDialogBoxComponent, {
      disableClose: true,
      panelClass: 'loadingDialog'
    })
  }
}
