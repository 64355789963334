import { environment } from 'src/environments/environment'
import {
  Component,
  OnInit,
  Input,
  ChangeDetectorRef,
  AfterContentInit,
  AfterViewChecked,
  AfterViewInit,
  Output,
  EventEmitter,
  OnDestroy
} from '@angular/core'
import { ParamChangeService } from '../shared/services/param-change.service'
import { DepartmentService } from './department.service'
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  Route,
  Router,
  ActivationEnd,
  Event as NavigationEvent,
  NavigationEnd,
  NavigationStart
} from '@angular/router'
import { BehaviorSubject, Observable } from 'rxjs'
import { map, filter } from 'rxjs/operators'
import { AuthService } from '../modules/auth/auth.service'
import { Title } from '@angular/platform-browser'
import { ModulesService } from '../modules/modules.service'
import { SESSION_STORAGE } from '../app.constant'

@Component({
  selector: 'department',
  templateUrl: './department.component.html',
  styleUrls: ['./department.component.css'],
  providers: []
})
export class DepartmentComponent implements OnInit {
  @Input() link
  department
  departmentId
  processes
  pId
  processActive
  expanded = false
  activeProcessId
  activeDepartmentId
  highlightItemStyle = {
    'background-color': '#595959',
    color: '#fff'
  }
  deptSubjectSubscription
  subjectSubscription
  setColor
  depId
  processid
  constructor(
    private paramChange: ParamChangeService,
    private moduleService: ModulesService,
    private router: Router
  ) {}

  ngOnInit() {
    this.department = this.link[0]
    this.processes = this.link[1]
    this.departmentId = this.processes[0].deptId
    this.moduleService.shipmentProcess$.subscribe(obj => {
      if (obj) {
        this.router.navigate([
          `/department/${obj.departmentId}/process/`,
          obj.processId
        ])
      }
    })
    this.moduleService.salesProcess$.subscribe(obj => {
      if (obj) {
        this.router.navigate([
          `/department/${obj.departmentId}/process/`,
          obj.processId
        ])
      }
    })
    this.moduleService.hrProcess$.subscribe(obj => {
      if (obj) {
        this.router.navigate([
          `/department/${obj.departmentId}/process/`,
          obj.processId
        ])
      }
    })
    this.moduleService.financeProcess$.subscribe(obj => {
      if (obj) {
        this.router.navigate([
          `/department/${obj.departmentId}/process/`,
          obj.processId
        ])
      }
    })
    this.moduleService.collectionProcess$.subscribe(obj => {
      if (obj) {
        this.router.navigate([
          `/department/${obj.departmentId}/process/`,
          obj.processId
        ])
      }
    })
    this.moduleService.escalationProcess$.subscribe(obj => {
      if (obj) {
        this.router.navigate([
          `/department/${obj.departmentId}/process/`,
          obj.processId
        ])
      }
    })
    this.moduleService.inventoryProcess$.subscribe(obj => {
      if (obj) {
        this.router.navigate([
          `/department/${obj.departmentId}/process/`,
          obj.processId
        ])
      }
    })

    this.paramChange.deptSubject$.subscribe(deptId => {
      if (deptId == this.departmentId) {
        this.expanded = true
      } else {
        this.expanded = false
      }
    })
    this.paramChange.subject$.subscribe(processId => {
      this.pId = processId
    })
    if (this.moduleService.breadcrumb.getValue()) {
      let breadcrumbsArray = this.moduleService.breadcrumb.getValue()
      switch (breadcrumbsArray.length) {
        case 3:
        case 2:
          this.activeProcessId = breadcrumbsArray[1].url.slice(
            breadcrumbsArray[1].url.lastIndexOf('/') + 1
          )
        case 1:
          let activeDept = breadcrumbsArray[0].url.slice(
            breadcrumbsArray[0].url.lastIndexOf('/') + 1
          )
          if (this.departmentId == activeDept) {
            this.expanded = true
          }
      }
    }
  }

  deptSelected(departmentId) {
    this.activeProcessId = ''
  }

  triggerProcessClick(departmentId, processId) {
    localStorage.removeItem(SESSION_STORAGE.FILTERED_FIELD)
    localStorage.removeItem(SESSION_STORAGE.PAGE_INDEX)
    this.activeProcessId = processId
    switch (departmentId) {
      case environment.config.sales.id:
        this.moduleService.salesSubject.next({
          departmentId: departmentId,
          processId: processId
        })
        break
      case environment.config.hr.id:
        this.moduleService.hrSubject.next({
          departmentId: departmentId,
          processId: processId
        })
        break
      case environment.config.finance.id:
        this.moduleService.financeSubject.next({
          departmentId: departmentId,
          processId: processId
        })
        break
      case environment.config.collections.id:
        this.moduleService.collectionsSubject.next({
          departmentId: departmentId,
          processId: processId
        })
        break
      case environment.config.shipment.id:
        this.moduleService.shipmentSubject.next({
          departmentId: departmentId,
          processId: processId
        })
        break
      case environment.config.escalation.id:
        this.moduleService.escalationSubject.next({
          departmentId: departmentId,
          processId: processId
        })
        break
      case environment.config.inventory.id:
        this.moduleService.inventorySubject.next({
          departmentId: departmentId,
          processId: processId
        })
        break
    }
  }
}
