import { SESSION_STORAGE } from '../../../../app.constant'
import { Observable, Subject, BehaviorSubject } from 'rxjs'
import { Injectable, Output, EventEmitter, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { HttpResponse, HttpEventType } from '@angular/common/http'
import { map } from 'rxjs/operators'
import { NotifierService } from 'angular-notifier'
import { environment } from 'src/environments/environment'
import { HttpService } from 'src/app/core/services/http.service'
import { MatSnackBar } from '@angular/material'
import { SnackbarService } from 'src/app/snackbar.service'
import { ProcessRecordsService } from '../process-records.service'

export interface WareHouse {
  title: string
  value: string
}

export interface Record {
  _id: string
  state: string
  status: string
  details: any[]
  store: string
  user: {
    _id: string
    userName: string
  }
}

export interface Paginate {
  page: number
  perPage: number
}

export interface SelectionItem {
  rowData: any
  panelIndex: number
}

export interface UserPermission {
  teams: [
    {
      _id: string
      title: string
    }
  ]
  actions: [
    {
      _id: string
      title: string
      index: number
      urlKey: string
      state: string
      status: string
    }
  ]
  widgets?: any[]
  permissions: any[]
}

@Injectable()
export class RecordViewService implements OnInit {
  baseUrl: string
  private token: string
  public warehouseSelectEvent: BehaviorSubject<string[]> = new BehaviorSubject<
    string[]
  >(null)
  private deleteChildItemSubject: Subject<any> = new Subject()
  public queryFilter: any[] = []
  public paginate: Paginate
  public filterDone: boolean = false
  public temporaryProcessId: string
  deleteChildItem$: Observable<any>
  private refreshSubject: Subject<any> = new Subject()
  private disbaleEditSubject: Subject<any> = new Subject()
  refresh$: Observable<any>
  disbaleEdit$: Observable<any>
  private recordService: ProcessRecordsService
  recordViewSubject = new BehaviorSubject<any>(false)
  recordView$ = this.recordViewSubject.asObservable()

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private httpService: HttpService,
    private _notifier: NotifierService,
    private _snackBar: SnackbarService
  ) {
    this.baseUrl = environment.baseURL
    this.token = localStorage.getItem(SESSION_STORAGE.TOKEN)
    this.deleteChildItem$ = this.deleteChildItemSubject.asObservable()
    this.refresh$ = this.refreshSubject.asObservable()
    this.disbaleEdit$ = this.disbaleEditSubject.asObservable()
    this.paginate = { page: 1, perPage: 10 }
  }

  ngOnInit() {}

  setWarehouses(input) {
    this.warehouseSelectEvent.next(input)
  }

  getRecordList(processId, body) {
    if (body) {
      body['filters'] = this.queryFilter
      body['paginate'] = this.paginate
    }
    return this.httpService.post(
      `${this.baseUrl}/records/${processId}/list`,
      body
    )
  }

  getRecordListCount(processId, body) {
    if (body) {
      body['filters'] = this.queryFilter
      body['paginate'] = this.paginate
    }
    return this.httpService.post(
      `${this.baseUrl}/records/${processId}/count`,
      body
    )
  }

  getRecordData(recordId, processId) {
    return this.httpService.post(
      `${this.baseUrl}/records/${processId}/list/${recordId}`,
      {}
    )
  }

  updateRecord(processId, recordId, body) {
    return this.httpService.post(
      `${this.baseUrl}/records/${processId}/update/${recordId}`,
      body
    )
  }

  createRecord(processId, data) {
    return this.httpService.post(
      `${this.baseUrl}/records/${processId}/create`,
      data
    )
  }

  getUserPermission(processId) {
    return this.httpService.post(
      `${this.baseUrl}/process/${processId}/permission`,
      {}
    )
  }

  getProcessForms(processId) {
    return this.httpService.post(
      `${this.baseUrl}/process/${processId}/forms`,
      {}
    )
  }

  getProcessWidgets(processId) {
    return this.httpService.post(
      `${this.baseUrl}/process/${processId}/widgets`,
      {}
    )
  }

  getProcessWidget(processId, widgetId) {
    return this.httpService.post(
      `${this.baseUrl}/process/${processId}/widgets/${widgetId}`,
      {}
    )
  }

  fetchWidgetData(processId, action, title, body) {
    return this.httpService.post(
      `${this.baseUrl}/widgets/${title}/${processId}/${action}`,
      body
    )
  }

  getProcessActions(processId) {
    return this.httpService.post(
      `${this.baseUrl}/process/${processId}/actions`,
      {}
    )
  }

  getUserBulkActions(processId) {
    return this.httpService.post(
      `${this.baseUrl}/process/${processId}/bulkactions`,
      {}
    )
  }

  getProcessStates(processId) {
    return this.httpService.post(
      `${this.baseUrl}/process/${processId}/states`,
      {}
    )
  }

  makeRequest(processId, recordIds, actionId) {
    return this.httpService.post(
      `${this.baseUrl}/records/${processId}/request`,
      { recordIds, actionId }
    )
  }

  executeRequest(processId, body) {
    return this.httpService.post(
      `${this.baseUrl}/records/${processId}/execute`,
      body
    )
  }

  executeList(processId, body) {
    return this.httpService.post(
      `${this.baseUrl}/records/${processId}/executelist`,
      body
    )
  }

  getComments(processId, recordId) {
    return this.httpService.post(
      `${this.baseUrl}/records/${processId}/comments/${recordId}`,
      {}
    )
  }

  addComments(processId, recordId, body) {
    return this.httpService.post(
      `${this.baseUrl}/records/${processId}/addcomment/${recordId}`,
      body
    )
  }

  deleteRecords(processId, recordIds) {
    return this.httpService.post(
      `${this.baseUrl}/records/${processId}/delete`,
      { recordIds }
    )
  }

  markDeleted() {
    this.deleteChildItemSubject.next(true)
  }

  refreshRecord() {
    this.refreshSubject.next(true)
  }

  disableEdit() {
    this.disbaleEditSubject.next(true)
  }

  enableEdit() {
    this.disbaleEditSubject.next(false)
  }

  createRequest(body) {
    return this.httpService.post(`${this.baseUrl}/firs/create`, body)
  }

  createBulkRequest(body) {
    return this.httpService.post(`${this.baseUrl}/firs/createbulk`, body)
  }

  requestUsersList(processId, actionId, recordId) {
    return this.httpService.post(
      `${this.baseUrl}/process/${processId}/requestusers`,
      { actionId, recordId }
    )
  }

  upload(formData, processId) {
    return this.httpService.post(
      `${this.baseUrl}/records/${processId}/upload`,
      formData
    )
  }

  genDocSignedUrl(formData, processId) {
    return this.httpService.post(
      `${this.baseUrl}/records/${processId}/genDocSignedUrl`,
      formData
    )
  }

  getRecordFirs(processId, recordId) {
    return this.httpService.post(`${this.baseUrl}/records/${processId}/fir`, {
      recordId
    })
  }

  checkIfFirExists(processId, recordIds, actionId) {
    return this.httpService.post(
      `${this.baseUrl}/records/${processId}/fircheck`,
      { recordIds, actionId }
    )
  }

  getFirByRecordId(actionId, firData$) {
    let assignedTo
    let raisedBy
    let firId
    let status
    let childData
    let recordFirs = firData$.pipe(
      map((firs: any[]) => {
        let existFirs = firs.map(fir => {
          return (
            fir.action._id === actionId &&
            fir.assignedTo.userName ===
              JSON.parse(localStorage.getItem(SESSION_STORAGE.DETAILS)).userName
          )
        })
        for (let i = 0; i < firs.length; i++) {
          if (firs[i].action._id === actionId) {
            assignedTo = firs[i].assignedTo.userName
            raisedBy = firs[i].raisedBy.userName
            firId = firs[i].firId
            status = firs[i].status
            childData = firs[i].childData
          }
        }
        return {
          boolean: existFirs.indexOf(true) >= 0,
          assignedTo: assignedTo,
          raisedBy: raisedBy,
          firId: firId,
          status,
          childData
        }
      })
    )
    return recordFirs
  }

  /**
   * Show a notification
   *
   * @param {string} type    Notification type
   * @param {string} message Notification message
   */
  public showNotification(type: string, message: string): void {
    this._snackBar.showSnackbar(message, 'success')
  }

  recordViewOpened(obj) {
    this.recordViewSubject.next(obj)
  }

  countOverviewRecords(processId, title) {
    return this.httpService.post(
      `${this.baseUrl}/widgets/order/${processId}/${title}/count`
    )
  }

  /**
   * order process specific api for fetching order Items
   */
}
