import { Component, OnInit } from '@angular/core'
import { ProcessRecordsService } from 'src/app/modules/process/process-records/process-records.service'
import { RecordViewService } from 'src/app/modules/process/process-records/record-view/record-view.service'
import { Observable, Subject, BehaviorSubject } from 'rxjs'
import { ModulesService } from '../../modules.service'
import { debounceTime, map, mergeMap } from 'rxjs/operators'
import { FormBuilder, FormGroup, FormControl, FormArray } from '@angular/forms'
import { SnackbarService } from 'src/app/snackbar.service'

@Component({
  selector: 'app-change-order-items',
  templateUrl: './change-order-items.component.html',
  styleUrls: ['./change-order-items.component.css']
})
export class ChangeOrderItemsComponent implements OnInit {
  productName: boolean = false
  productId: boolean = false
  productsByName: Observable<any>
  productsById: Observable<any>
  open: boolean = false
  orderId
  orderItems: Observable<any>
  orderItemsControl: FormControl
  productForm: FormGroup
  productFormArray: FormArray
  orderIdControl: FormControl
  selectedItems: Array<any> = []

  constructor(
    private modulesService: ModulesService,
    private fb: FormBuilder,
    private _snackBar: SnackbarService
  ) {}

  ngOnInit() {
    this.orderIdControl = this.fb.control('')
    this.productFormArray = this.fb.array([this.addNewUpgradeForm()])
  }

  submitForm() {
    this.orderId = this.orderIdControl.value
    this.orderItems = this.modulesService.getItemsByOrderId(this.orderId)
    this.open = true
  }

  addNewUpgradeForm(): FormGroup {
    return this.fb.group({
      productNameControl: this.fb.control(''),
      productIdControl: this.fb.control(''),
      orderItemsControl: this.fb.control('')
    })
  }

  addNextItem() {
    this.orderItems.subscribe(orderItems => {
      orderItems.items = orderItems.items.filter(item => item.type != 'package')
      if (this.productFormArray.value.length < orderItems.items.length) {
        this.productFormArray.push(this.addNewUpgradeForm())
      } else {
        this._snackBar.showSnackbar(
          `Only ${this.productFormArray.value.length} simple items are present in this order.`,
          'warn'
        )
      }
    })
  }

  saveProducts() {
    let upgradeInfo = this.productFormArray.controls
      .map(element => {
        if (
          element.get('productIdControl').value ||
          element.get('productNameControl').value
        ) {
          return {
            itemId: element.get('orderItemsControl').value.itemId,
            productId:
              element.get('productIdControl').value ||
              element
                .get('productNameControl')
                .value.substr(
                  element.get('productNameControl').value.length - 4,
                  4
                ),
            orderId: this.orderId
          }
        } else {
          this._snackBar.showSnackbar('Fill necessary data', 'warn')
        }
      })
      .filter(elem => elem)
    if (upgradeInfo.length == this.productFormArray.controls.length) {
      //post request
    }
  }

  removeFormEmitter({ itemGroup, index }) {
    if (this.productFormArray.value.length > 1) {
      let i = this.selectedItems.findIndex(
        selectedItem => itemGroup._id === selectedItem._id
      )
      this.productFormArray.value.splice(i, 1)

      this.productFormArray.controls.splice(index, 1)
    }
  }

  onSelectedItemsChange(event) {
    this.selectedItems = event
  }
}
