import { EventEmitter } from '@angular/core'
import { Subject } from 'rxjs'

export class NavToggleService {
  navSubject: Subject<any> = new Subject()
  navSubject$ = this.navSubject.asObservable()

  toggleDrawer() {
    this.navSubject.next()
  }
}
