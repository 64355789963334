import { Component, OnInit, AfterContentInit, ViewChild } from '@angular/core'
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout'
import { AuthService } from 'src/app/modules/auth/auth.service'
import { NavToggleService } from '../../services/navtoggle.service'
import { NotifierService } from 'angular-notifier'
import { RequestsSocketService } from 'src/app/modules/process/process-records/requests-socket.service'
import { Router } from '@angular/router'
import { SESSION_STORAGE } from '../../../app.constant'
import { MatDrawer } from '@angular/material'
import { async } from '@angular/core/testing'

@Component({
  selector: 'app-main-container',
  templateUrl: './main-container.component.html',
  styleUrls: ['./main-container.component.css']
})
export class MainContainerComponent implements OnInit, AfterContentInit {
  @ViewChild('drawer', { static: false }) drawer: MatDrawer
  title = 'rmp'
  links: any[]
  mobile: boolean
  mobWidth = {
    width: '80%'
  }
  webWidth = {
    width: '45%'
  }
  mode
  opened = false
  navSubscription
  // @ViewChild("customNotification", { static: true }) customNotificationTmpl;
  constructor(
    private breakpointObserver: BreakpointObserver,
    private authenticationService: AuthService,
    private navService: NavToggleService,
    private notifier: NotifierService,
    // private requestSocketService: RequestsSocketService,
    private router: Router
  ) {}

  ngAfterContentInit() {
    let navigationFlag = localStorage.getItem(SESSION_STORAGE.NAVIGATION)
    if (navigationFlag == 'closed') {
      this.opened = false
    }
  }

  ngOnInit() {
    this.navSubscription = this.navService.navSubject$.subscribe(() => {
      this.drawer.toggle()
      if (!navigationFlag) {
        if (this.opened)
          localStorage.setItem(SESSION_STORAGE.NAVIGATION, 'closed')
        else localStorage.setItem(SESSION_STORAGE.NAVIGATION, 'opened')
      } else {
        navigationFlag = navigationFlag == 'opened' ? 'closed' : 'opened'
        localStorage.setItem(SESSION_STORAGE.NAVIGATION, navigationFlag)
      }
    })
    let navigationFlag = localStorage.getItem(SESSION_STORAGE.NAVIGATION)
    this.authenticationService.loginSubject$.subscribe(loginState => {
      if (loginState) {
        this.breakpointObserver
          .observe(['(max-width: 767px)'])
          .subscribe((state: BreakpointState) => {
            if (state.matches) {
              this.mode = 'over'
              this.opened = false
            } else {
              this.mode = 'side'
              this.opened = true
            }
          })
      } else {
        if (this.notifier) {
          this.notifier.hideAll()
        }
      }
    })

    // this.requestSocketService.listen('request event').subscribe((data) => {
    // 	this.showNotifications(data);
    // });

    // if (localStorage.getItem('requestQueue')) {
    // 	JSON.parse(localStorage.getItem('requestQueue')).forEach((data) => {
    // 		this.requestSocketService.emit('request event', 0, data.receiver, data.sender, data.action, data.type, data.firIds, data.id);
    // 	})
    // }
  }

  // closeNotification(index) {
  // 	this.notifier.hide(index);
  // 	let requestQueue = [...JSON.parse(localStorage.getItem('requestQueue'))];
  // 	requestQueue.splice(requestQueue.findIndex((req) => req.id === index), 1);
  // 	localStorage.setItem('requestQueue', JSON.stringify(requestQueue));
  // }

  // showNotifications(data) {
  // 	if (data['receiver']['userName'] == JSON.parse(localStorage.getItem(SESSION_STORAGE.DETAILS)).userName) {
  // 		this.notifier.show({
  // 			message: `Sender: ${data['sender']} \u00A0\u00A0 Fir Id: ${data['firIds']} \u00A0\u00A0  Action: ${data['action']}`,
  // 			type: data['type'],
  // 			template: this.customNotificationTmpl,
  // 			id: data['id']
  // 		});
  // 		if (data.store) {
  // 			let requestQueue = [];
  // 			if (!localStorage.getItem('requestQueue')) {
  // 				requestQueue = [data];
  // 			} else {
  // 				requestQueue = [...JSON.parse(localStorage.getItem('requestQueue')), data];
  // 			}
  // 			localStorage.setItem('requestQueue', JSON.stringify(requestQueue));
  // 		}

  // 	}
  // }
}
