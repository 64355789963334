import { Component, OnInit } from '@angular/core'
import { ModulesService } from '../modules.service'
import { Router } from '@angular/router'
import { environment } from 'src/environments/environment'

@Component({
  selector: 'app-escaltion',
  templateUrl: './escaltion.component.html',
  styleUrls: ['./escaltion.component.css']
})
export class EscaltionComponent implements OnInit {
  constructor(private moduleService: ModulesService, private router: Router) {}

  departmentId = environment.config.escalation.id

  ngOnInit() {}
}
