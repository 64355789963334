import {
  Component,
  OnInit,
  ViewChild,
  ViewContainerRef,
  ComponentFactoryResolver
} from '@angular/core'
import { ChangeOrderItemsComponent } from './change-order-items/change-order-items.component'
import { ModulesService } from '../modules.service'
import { Observable } from 'rxjs'
import { Router, ActivatedRoute } from '@angular/router'
import { AuthService } from '../auth/auth.service'
import { environment } from 'src/environments/environment'

@Component({
  selector: 'app-sales',
  templateUrl: './sales.component.html',
  styleUrls: ['./sales.component.css']
})
export class SalesComponent implements OnInit {
  injectRouter: boolean = false
  selected = 'none'
  processId
  salesActions = ['Change Order Items', 'Out For Delivery', 'Mark Delivered']
  departmentId = environment.config.sales.id
  constructor(
    private resolver: ComponentFactoryResolver,
    private moduleService: ModulesService,
    private router: Router,
    public route: ActivatedRoute,
    private authService: AuthService
  ) {}

  ngOnInit() {}
}
