import { ActivatedRoute, Router } from '@angular/router'
import { Component, OnInit } from '@angular/core'
import { Subject } from 'rxjs'

@Component({
  selector: 'app-fir',
  templateUrl: './fir.component.html',
  styleUrls: ['./fir.component.css']
})
export class FirComponent implements OnInit {
  firSubject = new Subject<any>()
  fir$ = this.firSubject.asObservable()
  constructor(private route: ActivatedRoute, private router: Router) {}

  ngOnInit() {
    // this.router.navigate(['list'], { relativeTo: this.route, queryParamsHandling: 'preserve' });
  }
}
