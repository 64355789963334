import { FormBuilder, FormGroup, FormControl } from '@angular/forms'
import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  Inject
} from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { ProcessService } from '../../../process.service'
import { ProcessRecordsService } from '../../process-records.service'
import { DATA } from '../record-child-view/record-child-view.component'
import { Observable, Subject, BehaviorSubject } from 'rxjs'
import { RecordViewService } from '../record-view.service'
import { RequestDialogComponent } from '../request-dialog/request-dialog.component'
import { MatDialog } from '@angular/material'
import { RequestsSocketService } from '../../requests-socket.service'
import { SnackbarService } from 'src/app/snackbar.service'
import { SESSION_STORAGE } from 'src/app/app.constant'
import { LoadingDialogBoxComponent } from '../../loading-dialog-box-component/loading-dialog-box-component.component'
import { ConfirmationDialogComponent } from '../../confirmation-dialog/confirmation-dialog.component'

@Component({
  selector: 'record-child-single',
  templateUrl: './record-child-single.component.html',
  styleUrls: ['./record-child-single.component.css']
})
export class RecordChildSingleComponent implements OnInit {
  @Input() form: any = null
  @Input() data: any = {}
  @Input() actions?: any = null
  @Input() access?: any = null
  @Input() selectedStatus?: any = null
  @Input() recordId
  @Input() processId
  @Input() closeDialog$: Observable<boolean>
  @Input() recordFirs
  @Output() executeEvent: EventEmitter<any> = new EventEmitter<any>()
  @Output() pushItem: EventEmitter<any> = new EventEmitter<any>()
  @Output() updateItem: EventEmitter<any> = new EventEmitter<any>()
  @Output() deleteItem: EventEmitter<any> = new EventEmitter<any>()

  childId
  executeSubject = new BehaviorSubject<any>(false)
  execute$ = this.executeSubject.asObservable()
  requestSubject = new BehaviorSubject<any>(false)
  request$ = this.requestSubject.asObservable()
  canDelete: Boolean = false
  readOnly: Boolean = true
  isAdmin: Boolean = false
  isNew = false
  selectedAction
  selectedActionSubject = new Subject<any>()
  selectedAction$ = this.selectedActionSubject.asObservable()
  fir: any
  showFirMessage = new BehaviorSubject<any>(false)
  showFirMessage$ = this.showFirMessage.asObservable()
  @Output() selectedFir = new EventEmitter()
  assignedTo
  raisedBy
  firId
  actionId
  clicked: boolean = false
  userdetails
  @Output() requestEmitter = new EventEmitter()

  childViewForm: FormGroup

  constructor(
    private route: ActivatedRoute,
    private processRecordService: ProcessRecordsService,
    private recordViewService: RecordViewService,
    @Inject(DATA) private dataInjected: string,
    private dialog: MatDialog,
    private requestSocketService: RequestsSocketService,
    private _snackbar: SnackbarService
  ) {}

  ngOnInit() {
    this.userdetails = JSON.parse(localStorage.getItem(SESSION_STORAGE.DETAILS))
    // this.data = this.data[0];
    if (!this.data) this.isNew = true

    this.readOnly = !this.access.permissions.some(
      permission => permission == 'edit'
    )
    this.canDelete = this.access.permissions.some(
      permission => permission == 'delete'
    )
    this.isAdmin = this.access.teams.some(team => team.title == 'admin')
  }

  selectAction(event) {
    this.onClose()
    this.actionId = event.value
    let firRaised = this.recordFirs.find(
      fir => fir.action._id == this.actionId && fir.status == 'open'
    )

    this.assignedTo = firRaised && firRaised.assignedTo
    this.raisedBy = firRaised && firRaised.raisedBy
    this.firId = firRaised && firRaised.firId

    if (this.firId && this.assignedTo.userName == this.userdetails.userName) {
      this.selectedFir.emit(firRaised)
      this.executeSubject.next(true)
      this.showFirMessage.next(true)
    } else if (
      !this.firId ||
      firRaised.status == 'closed' ||
      firRaised.status == 'dismissed'
    ) {
      if (this.access.actions.find(action => action._id == this.actionId)) {
        this.executeSubject.next(true)
      } else {
        this.requestSubject.next(true)
        this.executeSubject.next(false)
      }
    } else if (this.firId && firRaised.status == 'open') {
      this.selectedFir.emit(firRaised)
      this.showFirMessage.next(true)
    }
    this.selectedAction = this.actions.find(
      action => action._id == this.actionId
    )
    this.selectedActionSubject.next(this.selectedAction)

    // this.recordViewService.getFirByRecordId(this.actionId, this.recordFirs).subscribe((firRaised) => {
    // 	this.assignedTo = firRaised.assignedTo;
    // 	this.raisedBy = firRaised.raisedBy;
    // 	this.firId = firRaised.firId;
    // 	if (firRaised.firId && firRaised.status == 'open' && firRaised.assignedTo == this.userdetails.userName) {
    // 		this.selectedFir.emit(firRaised);
    // 		this.executeSubject.next(true);
    // 		this.showFirMessage.next(true);
    // 	} else if (!firRaised.firId || firRaised.status == 'closed' || firRaised.status == 'dismissed') {
    // 		if (this.access.actions.find((action) => action._id == this.actionId)) {
    // 			this.executeSubject.next(true);
    // 		}
    // 		else {
    // 			this.requestSubject.next(true);
    // 			this.executeSubject.next(false);
    // 		}
    // 	}
    // 	else if (firRaised.firId && firRaised.status == 'open') {
    // 		this.selectedFir.emit(firRaised);
    // 		this.showFirMessage.next(true);
    // 	}
    // 	this.selectedAction = this.actions.find((action) => action._id == this.actionId);
    // 	this.selectedActionSubject.next(this.selectedAction);

    // });
  }

  onClose() {
    this.selectedAction = ''
    this.executeSubject.next(false)
    this.requestSubject.next(false)
    this.showFirMessage.next(false)
  }

  onAddListItem() {}

  addChildItem() {}

  removeChildItem() {}

  checkPermission() {}

  executeAction() {
    if (this.selectedAction.confirmationRequired) {
      //show confirmation modal
      let dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        width: '300px',
        height: '35%',
        data: {
          header: 'Confirm Action',
          content: `\n You are executing \n"${this.selectedAction.title}". \nThis is non reversible. Are you sure?`
        }
      })
      dialogRef.componentInstance.confirmationEvent.subscribe(() => {
        const obj = { id: this.form.index, actionId: this.selectedAction._id }
        this.dialog.closeAll()
        this.executeEvent.emit(obj)
      })
    } else {
      //don't show confirmation modal
      const obj = { id: this.form.index, actionId: this.selectedAction._id }
      this.executeEvent.emit(obj)
    }
  }

  addItemEvent(event) {
    this.pushItem.emit({ data: event, formIndex: this.form.index })
  }

  updateItemEvent(event) {
    this.updateItem.emit({ index: 0, updatedData: event })
  }

  deleteItemEvent(event) {
    this.deleteItem.emit({ data: [this.data], selectedItems: [this.data] })
  }

  active() {
    this.clicked = true
  }

  onRequest() {
    this.requestEmitter.emit(this.selectedAction)
  }

  openDialog() {
    const dialogRef = this.dialog.open(LoadingDialogBoxComponent, {
      disableClose: true,
      panelClass: 'loadingDialog',
      // height: '30%',
      data: {}
    })
  }
}
