import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core'
import { FormControl, FormGroup } from '@angular/forms'
import { MomentDateAdapter } from '@angular/material-moment-adapter'
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE
} from '@angular/material/core'

import * as _moment from 'moment'
import { MatDatepickerInputEvent } from '@angular/material'
const moment = _moment
export const DATE_RANGE_FORMATS = {
  parse: {
    dateInput: 'LL'
  },
  display: {
    dateInput: 'LL',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  }
}

@Component({
  selector: 'date-range-field',
  templateUrl: './date-range-field.component.html',
  styleUrls: ['./date-range-field.component.css'],
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE]
    },

    { provide: MAT_DATE_FORMATS, useValue: DATE_RANGE_FORMATS }
  ]
})
export class DateRangeFieldComponent implements OnInit {
  @Input() fieldInstance: FormControl | FormGroup
  @Input() label: string
  @Input('fieldIndex') index: number
  @Input() field: any
  @Input() create?: boolean = false
  @Input() isRequired: boolean = false
  @Input() inputValidations: any[] = []
  @Output() dateInput: EventEmitter<MatDatepickerInputEvent<any>>
  @Input() readOnly: boolean = false
  @Input() path: string
  @Input() isAdmin: boolean = false

  rangeFrom: Date | null = null

  constructor() {}

  ngOnInit() {
    this.fieldInstance.get('to').disable()
  }

  valueChange(event) {
    this.rangeFrom = event.value
    this.fieldInstance.get('to').enable()
  }
}
