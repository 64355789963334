import { Component, OnInit, Input } from '@angular/core'
import { FormControl, FormGroup } from '@angular/forms'

export interface SelectInputItem {
  _id: string
  value: any
  viewValue: any
}

@Component({
  selector: 'select-field',
  templateUrl: './select-field.component.html',
  styleUrls: ['./select-field.component.css']
})
export class SelectFieldComponent implements OnInit {
  @Input() fieldInstance: FormControl
  @Input() label: string
  @Input('fieldIndex') index: number
  @Input() field: any
  @Input() create?: boolean = false
  @Input() isRequired: boolean = false
  @Input() inputValidations: any[] = []
  @Input() readOnly: boolean = false
  @Input() path: string
  @Input() isAdmin: boolean = false

  constructor() {}

  ngOnInit() {}
}
