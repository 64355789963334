import { environment } from 'src/environments/environment'
import { Component, OnInit } from '@angular/core'
import { ModulesService } from '../modules.service'
import { Router, ActivatedRoute } from '@angular/router'

@Component({
  selector: 'app-collections',
  templateUrl: './collections.component.html',
  styleUrls: ['./collections.component.css']
})
export class CollectionsComponent implements OnInit {
  constructor(
    private moduleService: ModulesService,
    private router: Router,
    public route: ActivatedRoute
  ) {}

  departmentId = environment.config.collections.id

  ngOnInit() {}
}
