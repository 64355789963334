import { ProcessActionComponent } from './process-action/process-action.component'
import { Component, OnInit } from '@angular/core'
import { ProcessTeamComponent } from './process-team/process-team.component'
import { ProcessFormComponent } from './process-form/process-form.component'
import { ProcessStateComponent } from './process-state/process-state.component'
import { ProcessWidgetComponent } from './process-widget/process-widget.component'
import { AuthService } from 'src/app/modules/auth/auth.service'
import { ActivatedRoute, Router } from '@angular/router'
import { ModulesService } from '../../modules.service'

@Component({
  selector: 'app-process-manage',
  templateUrl: './process-manage.component.html',
  styleUrls: ['./process-manage.component.css']
})
export class ProcessManageComponent implements OnInit {
  public partComponent = null
  processId
  deptId
  loads: boolean
  constructor(
    private authService: AuthService,
    private route: ActivatedRoute,
    private moduleService: ModulesService,
    private router: Router
  ) {
    this.partComponent = ProcessTeamComponent
  }

  assignComponent(event) {
    switch (event.index) {
      case 1: {
        this.partComponent = ProcessActionComponent
        break
      }
      case 2: {
        this.partComponent = ProcessFormComponent
        break
      }
      case 3: {
        this.partComponent = ProcessStateComponent
        break
      }
      case 4: {
        this.partComponent = ProcessWidgetComponent
        break
      }
      default: {
        this.partComponent = ProcessTeamComponent
        break
      }
    }
  }
  ngOnInit() {
    this.deptId = this.router.url.split('/')[2]
    this.processId = this.router.url.split('/')[4]
  }
}
