import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core'
import { debounceTime, map } from 'rxjs/operators'
import { HttpService } from 'src/app/core/services/http.service'
import { environment } from '../../../../../environments/environment'

@Component({
  selector: 'status-render',
  templateUrl: './status-render.component.html',
  styleUrls: ['./status-render.component.css']
})
export class StatusRenderComponent implements OnInit {
  @Input() processId
  @Input() options
  apiUrl = environment.baseURL
  states = []
  statuses = []
  isLoading = true
  searchStatus: any = null
  @Output() chosenStatus: any = new EventEmitter()
  constructor(private httpService: HttpService) {}

  ngOnInit() {
    this.httpService
      .post(`${this.apiUrl}/process/${this.processId}/states`)
      .subscribe(states => {
        this.isLoading = false
        this.states = states
      })
  }

  onStatusChange(event) {
    let status = []
    this.chosenStatus.emit({ status: event.source._value })
  }
}
