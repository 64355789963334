import { Pipe, PipeTransform } from '@angular/core'
import { map } from 'rxjs/operators'
import { Observable } from 'rxjs'

@Pipe({ name: 'reverseArray' })
export class ReverseArray implements PipeTransform {
  transform(value) {
    let filterArray = []

    return value.subscribe(data => {
      filterArray = data
    })
  }
}
