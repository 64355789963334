import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core'

@Component({
  selector: 'process-state-card',
  templateUrl: './process-state-card.component.html',
  styleUrls: ['./process-state-card.component.css']
})
export class ProcessStateCardComponent implements OnInit {
  @Input() processState
  @Input() statesCount
  @Output() dropChildStatusEvent: EventEmitter<any> = new EventEmitter<any>()
  @Output() addChildStatusEvent: EventEmitter<any> = new EventEmitter<any>()

  constructor() {}

  ngOnInit() {}

  drop(event, stateId): void {
    const data = {
      event,
      stateId
    }

    this.dropChildStatusEvent.emit(data)
  }

  addStatus(stateId) {
    this.addChildStatusEvent.emit(stateId)
  }
}
