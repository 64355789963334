import { SESSION_STORAGE } from './../../../app.constant'
import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { combineLatest, Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { NotifierService } from 'angular-notifier'
import { environment } from 'src/environments/environment'
import { Paginate } from '../../../modules/process/process-records/record-view/record-view.service'
import { MatSnackBar } from '@angular/material'
import { SnackbarService } from 'src/app/snackbar.service'
import { HttpService } from '../../services/http.service'

@Injectable()
export class FirService {
  baseUrl: string
  private token: string

  public queryFilter: any[] = []
  public paginate: Paginate
  public filterDone: boolean = false
  private user: any
  private openFirFilter = []
  private closedFirFilter = []
  private forwardedFirFilter = []

  constructor(
    private httpService: HttpService,
    private _snackBar: SnackbarService
  ) {
    this.baseUrl = environment.baseURL
    this.token = localStorage.getItem(SESSION_STORAGE.TOKEN)
    this.user = JSON.parse(localStorage.getItem(SESSION_STORAGE.DETAILS))
  }

  // getOpenFirCount() {
  // 	return this.httpClient.post(`${this.baseUrl}/firs/opencount`, {}, { headers: { 'x-auth-token': this.token } });
  // }

  // getForwardFirCount() {
  // 	return this.httpClient.post(`${this.baseUrl}/firs/forwardcount`, {}, { headers: { 'x-auth-token': this.token } });
  // }

  // getClosedFirCount() {
  // 	return this.httpClient.post(`${this.baseUrl}/firs/closecount`, {}, { headers: { 'x-auth-token': this.token } });
  // }

  getFirList(body) {
    return this.httpService.post(`${this.baseUrl}/firs`, body)
  }

  getFirsCount(body) {
    return this.httpService.post(`${this.baseUrl}/firs/count`, body)
  }

  getFirById(id) {
    return this.httpService.post(`${this.baseUrl}/firs/${id}`, {})
  }

  addComment(id, comment) {
    return this.httpService.post(`${this.baseUrl}/firs/${id}/comment`, {
      comment
    })
  }

  forward(id, body) {
    return this.httpService.post(`${this.baseUrl}/firs/${id}/forward`, body)
  }

  escalate(id) {
    return this.httpService.post(`${this.baseUrl}/firs/${id}/escalate`, {})
  }

  dismiss(id) {
    return this.httpService.post(`${this.baseUrl}/firs/${id}/dismiss`, {})
  }

  getOpenFirCounts(body = {}) {
    const openFirFilter = [...this.openFirFilter]
    openFirFilter.push({ 'assignedTo._id': this.user._id })
    openFirFilter.push({ status: 'open' })
    return this.getFirsCount({ filters: [...openFirFilter, body] }).pipe(
      map((count: number) => count)
    )
  }

  getClosedFirCounts(body = {}) {
    const closedFirFilter = [...this.closedFirFilter]
    closedFirFilter.push({ 'closedBy._id': this.user._id })
    closedFirFilter.push({ status: 'closed' })
    return this.getFirsCount({ filters: [...closedFirFilter, body] }).pipe(
      map((count: number) => count)
    )
  }

  getForwardedFirCounts(body = {}) {
    const forwardedFirFilter = [...this.forwardedFirFilter]
    forwardedFirFilter.push({ 'forwardedBy._id': this.user._id })
    return this.getFirsCount({ filters: [...forwardedFirFilter, body] }).pipe(
      map((count: number) => count)
    )
  }

  getTotalFirCounts(body = {}) {
    return this.getFirsCount({ filters: [body] }).pipe(
      map((count: number) => count)
    )
  }

  /**
   * Show a notification
   *
   * @param {string} type    Notification type
   * @param {string} message Notification message
   */
  public showNotification(type: string, message: string): void {
    this._snackBar.showSnackbar(message, 'success')
  }
}
