import { environment } from 'src/environments/environment'
import { Injectable } from '@angular/core'
// import * as io from 'socket.io-client';
import { Observable } from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class RequestsSocketService {
  socket: any
  url = environment.baseURL
  constructor() {
    // this.socket = io(this.url);
  }

  // listen(event) {
  // 	return new Observable((subscriber) => {
  // 		this.socket.on(event, (store, receiver, sender, action, type, ids, id) => {
  // 			let data = { store, sender: sender, receiver: receiver, action: action, type: type, firIds: ids, id };
  // 			subscriber.next(data);
  // 		})
  // 	})
  // }

  // emit(event, store, username, sender, action, type, firids, id) {
  // 	this.socket.emit(event, store, username, sender, action, type, firids, id);
  // }
}
