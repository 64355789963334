import { RegisterComponent } from './register/register.component'
import { LoginComponent } from './login/login.component'
import { ProfileComponent } from './profile/profile.component'
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core'
import { RouterModule } from '@angular/router'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { MaterialModule } from 'src/app/material.module'
import { LayoutModule } from '@angular/cdk/layout'
import { NO_ERRORS_SCHEMA } from '@angular/compiler/src/core'
import { AppComponent } from 'src/app/app.component'
import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component'
import { ChangePasswordComponent } from './profile/change-password/change-password.component'

const authRoutes = [
  {
    path: 'register',
    component: RegisterComponent
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'profile',
    component: ProfileComponent
  },
  {
    path: 'forgot',
    component: ForgotPasswordComponent
  }
]

@NgModule({
  declarations: [],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule.forChild(authRoutes),
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    LayoutModule
  ],
  exports: [RouterModule]
})
export class AuthRoutingModule {}
