import { Injectable } from '@angular/core'
import { Subject } from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class VendorService {
  log = new Subject<any>()
  log$ = this.log.asObservable()
  constructor() {}
}
