import { Component, OnInit, OnDestroy } from '@angular/core'
import {
  Router,
  ActivationEnd,
  Event as NavigationEvent,
  ActivatedRoute
} from '@angular/router'
import { filter } from 'rxjs/operators'
import { environment } from 'src/environments/environment'
import { HttpService } from 'src/app/core/services/http.service'
import { ModulesService } from '../../modules.service'
import { AuthService } from '../../auth/auth.service'

@Component({
  selector: 'analytics',
  templateUrl: './process-analytics.component.html',
  styleUrls: ['./process-analytics.component.css']
})
export class ProcessAnalyticsComponent implements OnInit, OnDestroy {
  loads: boolean = false
  dataSource
  processId
  deptId
  displayedColumns
  apiUrl = environment.baseURL
  constructor(
    public activatedRoute: ActivatedRoute,
    private httpService: HttpService,
    private moduleService: ModulesService,
    private authService: AuthService,
    public router: Router
  ) {}

  ngOnInit() {
    this.deptId = this.router.url.split('/')[2]
    this.processId = this.router.url.split('/')[4]
  }
  ngOnDestroy() {
    this.loads = false
  }
}
