import { Component, OnInit } from '@angular/core'
import { FormGroup, FormControl, FormArray, FormBuilder } from '@angular/forms'
import { Observable } from 'rxjs'
import { ModulesService } from 'src/app/modules/modules.service'
import { SnackbarService } from 'src/app/snackbar.service'

@Component({
  selector: 'app-set-rentals',
  templateUrl: './set-rentals.component.html',
  styleUrls: ['./set-rentals.component.css']
})
export class SetRentalsComponent implements OnInit {
  orderIdControl: FormControl
  showOrderDetails: boolean = false
  orderItems: Observable<any>
  tenure
  selectedOptions
  nobrokerOrders: FormGroup
  constructor(
    private fb: FormBuilder,
    private modulesService: ModulesService
  ) {}

  ngOnInit() {
    this.orderIdControl = this.fb.control('')
    this.nobrokerOrders = this.fb.group({
      ordersArray: this.fb.array([])
    })
  }

  submitForm() {
    this.nobrokerOrders = this.fb.group({
      ordersArray: this.fb.array([])
    })
    this.orderItems = this.modulesService.getItemsByOrderId(
      this.orderIdControl.value
    )
    this.orderItems.subscribe(items => {
      items.forEach(item => {
        let formGrp: FormGroup = this.fb.group({
          product: this.fb.control(item.name),
          rentals: this.fb.control(''),
          tenure: this.fb.control(item.rental.tenure)
        })
        // this.nobrokerOrders.controls.ordersArray.push(formGrp);
      })
    })
  }

  submitRentalsForm() {}
}
