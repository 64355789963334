import { Component, OnInit, Input } from '@angular/core'
import { ProcessRecordsService } from '../process/process-records/process-records.service'
import { ProcessService } from '../process/process.service'
import { map } from 'rxjs/operators'
import { AuthService } from '../auth/auth.service'
import { RecordViewService } from '../process/process-records/record-view/record-view.service'

@Component({
  selector: 'process-heading',
  templateUrl: './process-heading.component.html',
  styleUrls: ['./process-heading.component.css']
})
export class ProcessHeadingComponent implements OnInit {
  loading$
  showManage$
  @Input() processId
  @Input() departmentId
  @Input() type
  constructor(
    private processRecordService: ProcessRecordsService,
    private processService: ProcessService,
    private authService: AuthService,
    private recordViewService: RecordViewService
  ) {}

  ngOnInit() {
    this.loading$ = this.processRecordService.loading$

    this.authService.processPermission$
      .pipe(
        map(processDetails => {
          return processDetails.map(process => {
            return process
          })
        })
      )
      .subscribe(processArray => {
        let index = processArray.findIndex(process => {
          return process.processId == this.processId
        })
        this.recordViewService
          .getUserPermission(this.processId)
          .subscribe(adminInfo => {
            let isAdmin = adminInfo.teams.some(team => team.title == 'admin')
            this.processService.showManageSubject.next(isAdmin)
          })
      })
    this.showManage$ = this.processService.showManage$
  }
}
