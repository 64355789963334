import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ChangeDetectorRef
} from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { RecordViewService } from '../../record-view.service'
import { LoadingDialogBoxComponent } from '../../../loading-dialog-box-component/loading-dialog-box-component.component'
import { MatDialog } from '@angular/material'

@Component({
  selector: 'record-requests',
  templateUrl: './record-requests.component.html',
  styleUrls: ['./record-requests.component.css']
})
export class RecordRequestsComponent implements OnInit {
  @Input() processId: string
  recordId: string

  @Input() recordFirs: any
  recordFirsData: any[] = []
  @Output() loading: EventEmitter<any> = new EventEmitter<any>()

  constructor(
    private recordService: RecordViewService,
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    this.route.params.subscribe(params => {
      if (params) this.recordId = params['recordId']
    })
    this.recordFirsData = this.recordFirs
  }

  getColor(level) {
    switch (level) {
      case 'open':
        return '#ee3042'
      case 'closed':
        return '#228B22'
      case 'dismissed':
        return '#228B22'
    }
  }

  getTextColor() {
    return 'white'
  }

  // getAgeing(createdAt) {
  // 	let before: any = moment(createdAt).utc();
  // 	let now: any = moment().utc();
  // 	let duration = moment.duration(now.diff(before));
  // 	let age: string = '';
  // 	if (duration.months())
  // 		age = `${duration.months()} month(s)`;
  // 	age = `${age} ${duration.days()} day(s) ${duration.hours()} hour(s) ${duration.minutes()} minute(s) old.`;
  // 	return age;
  // }

  viewFir(firId) {
    this.router.navigate([`request/${firId}`])
  }

  refreshRequests() {
    this.openDialog()
    this.recordService.getRecordFirs(this.processId, this.recordId).subscribe(
      data => {
        this.recordFirsData = data
        this.dialog.closeAll()
      },
      error => {
        this.dialog.closeAll()
      }
    )
  }

  openDialog() {
    const dialogRef = this.dialog.open(LoadingDialogBoxComponent, {
      disableClose: true,
      panelClass: 'loadingDialog',
      // height: '30%',
      data: {}
    })
  }
}
