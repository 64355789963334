import { Component, OnInit, Input } from '@angular/core'

@Component({
  selector: 'po-bill',
  templateUrl: './po-bill.component.html',
  styleUrls: ['./po-bill.component.css']
})
export class PoBillComponent implements OnInit {
  @Input() billSource
  displayedColumns = [
    'Bill#',
    'Date',
    'Status',
    'Due Date',
    'Amount',
    'Balance Due'
  ]
  constructor() {}

  ngOnInit() {}
}
