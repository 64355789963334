import { environment } from 'src/environments/environment'
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core'
import { ModulesService } from '../../modules.service'
import { FormBuilder, FormGroup, FormControl } from '@angular/forms'
import {
  HttpRequest,
  HttpClient,
  HttpEvent,
  HttpEventType
} from '@angular/common/http'
import { map } from 'rxjs/operators'
import { RecordViewService } from '../../process/process-records/record-view/record-view.service'
import { SnackbarService } from 'src/app/snackbar.service'
import { LoadingDialogBoxComponent } from '../../process/process-records/loading-dialog-box-component/loading-dialog-box-component.component'
import { MatDialog } from '@angular/material'

@Component({
  selector: 'app-update-ecs-information',
  templateUrl: './update-ecs-information.component.html',
  styleUrls: ['./update-ecs-information.component.css']
})
export class UpdateEcsInformationComponent implements OnInit {
  file
  exists: boolean = false
  uploadForm: FormGroup
  @ViewChild('myInput', { static: true }) myInput
  constructor(
    private moduleService: ModulesService,
    private fb: FormBuilder,
    private httpClient: HttpClient,
    private recordService: RecordViewService,
    private snackbar: SnackbarService,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    this.uploadForm = this.fb.group({
      profile: ['']
    })
  }

  onChange(e) {
    this.file = e.target.files[0]
    this.exists = true
  }

  onSubmit() {
    let body = {
      fileName: this.file.name
    }
    this.moduleService.pushEcsToS3(body).subscribe(
      res => {
        this.openDialog()
        if (res['signedUrl']) {
          const uploadRequest = new HttpRequest(
            'PUT',
            res['signedUrl'],
            this.file,
            {
              reportProgress: true
            }
          )
          this.httpClient
            .request(uploadRequest)
            .pipe(
              map(event => {
                const percent = this.getEventMessage(event)
                return percent
              })
            )
            .subscribe(response => {
              if (response == 101) {
                let body = {
                  key: res['key'],
                  processId: environment.config.finance.ecs
                }

                this.moduleService.updateEcsInfo(body).subscribe(
                  data => {
                    this.exists = false
                    this.snackbar.showSnackbar(data.msg, 'success')
                    this.myInput.nativeElement.value = ''
                    this.dialog.closeAll()
                  },
                  error => {}
                )
              } else if (response == -1) {
                this.dialog.closeAll()
                this.recordService.enableEdit()
                this.recordService.showNotification(
                  'error',
                  'S3 upload not complete!'
                )
              }
            })
        }
      },
      err => {
        this.dialog.closeAll()
        this.recordService.showNotification(
          'error',
          'Server Error in file upload!.'
        )
      }
    )
  }

  getEventMessage(event: HttpEvent<any>) {
    switch (event.type) {
      case HttpEventType.Sent:
        return 0

      case HttpEventType.UploadProgress:
        // Compute and show the % done:
        const percentDone = Math.round((100 * event.loaded) / event.total)
        return percentDone

      case HttpEventType.Response:
        return 101

      default:
        return -1
    }
  }

  openDialog() {
    const dialogRef = this.dialog.open(LoadingDialogBoxComponent, {
      disableClose: true,
      panelClass: 'loadingDialog'
    })
  }
}
