import { Component, OnInit } from '@angular/core'
import {
  FormBuilder,
  FormGroup,
  Validators,
  ValidatorFn,
  FormControl,
  ValidationErrors
} from '@angular/forms'
import { AuthService } from '../auth.service'
import { SnackbarService } from 'src/app/snackbar.service'
import { Router } from '@angular/router'
import { MatDialog } from '@angular/material'
import { LoadingDialogBoxComponent } from '../../process/process-records/loading-dialog-box-component/loading-dialog-box-component.component'
import { SESSION_STORAGE } from 'src/app/app.constant'
import { Title } from '@angular/platform-browser'

@Component({
  selector: 'forgot',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {
  forgotPasswordForm: FormGroup
  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private _snackbar: SnackbarService,
    private router: Router,
    private dialog: MatDialog,
    private titleService: Title
  ) {
    if (localStorage.getItem(SESSION_STORAGE.TOKEN)) {
      this.router.navigate(['dashboard'])
    }
    this.titleService.setTitle('Rmp-send-reset-link')
  }

  ngOnInit() {
    this.createForm()
  }

  createForm() {
    this.forgotPasswordForm = this.fb.group({
      email: this.fb.control('', Validators.email)
    })
  }

  sendPasswordResetLink() {
    this.openDialog()
    this.authService
      .sendPasswordResetLink(this.forgotPasswordForm.controls.email.value)
      .subscribe(
        data => {
          this._snackbar.showSnackbar(data.msg, 'success')
          this.router.navigate(['login'])
          this.dialog.closeAll()
        },
        err => {
          this.dialog.closeAll()
        }
      )
  }

  openDialog() {
    const dialogRef = this.dialog.open(LoadingDialogBoxComponent, {
      disableClose: true,
      panelClass: 'loadingDialog'
    })
  }
}
