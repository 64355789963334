import { FormControl, Validators } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import { FirService } from './../fir.service'
import { Component, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core'
import * as moment from 'moment'
import { Observable } from 'rxjs'
import { MatDialog, MatSnackBar } from '@angular/material'
import { FirForwardComponent } from '../fir-forward/fir-forward.component'
import { HttpErrorResponse } from '@angular/common/http'
import { AuthService } from 'src/app/modules/auth/auth.service'
import { SESSION_STORAGE } from 'src/app/app.constant'
import { RecordViewService } from 'src/app/modules/process/process-records/record-view/record-view.service'

@Component({
  selector: 'fir-view',
  templateUrl: './fir-view.component.html',
  styleUrls: ['./fir-view.component.css']
})
export class FirViewComponent implements OnInit, OnDestroy {
  isAdmin: boolean = false
  fir: any
  age: any = ''
  firId: string
  allowForward: boolean = true
  allowEscalate: boolean = false
  msg: FormControl = new FormControl('', Validators.required)
  user: any
  closedAt: any = 'Not Updated!'

  constructor(
    private firService: FirService,
    private router: Router,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private cd: ChangeDetectorRef,
    private authService: AuthService,
    private recordViewService: RecordViewService
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false
  }

  ngOnInit() {
    this.initialize()
  }

  checkEscalate() {
    if (this.fir.forwardUsers.includes(this.user.userName) || this.isAdmin) {
      this.allowEscalate = true
    }
  }

  checkForward() {
    this.allowForward = this.fir.assignedTo._id == this.user._id || this.isAdmin
  }

  getColor(priority) {
    switch (priority) {
      case 'low':
        return 'white'
      case 'medium':
        return '#fbb13c'
      case 'high':
        return '#0d3b4c'
      case 'urgent':
        return '#ee3042'
    }
  }

  getTextColor(priority) {
    switch (priority) {
      case 'low':
        return 'grey'
      case 'medium':
        return 'white'
      case 'high':
        return 'white'
      case 'urgent':
        return 'white'
    }
  }

  addComment() {
    this.firService.addComment(this.firId, this.msg.value).subscribe(res => {
      this.fir = res['data']
      this.firService.showNotification('success', res['msg'])
      this.msg.reset()
      this.cd.detectChanges()
    })
  }

  forwardFir() {
    const forwardDialogRef = this.dialog.open(FirForwardComponent, {
      width: '300px',
      height: '50%',

      data: {
        action: this.fir.action,
        processId: this.fir.process.processId,
        fir: this.fir
      }
    })

    forwardDialogRef.componentInstance.firSubmit.subscribe(data => {
      if (data) {
        const bodyData = {
          forwardedTo: data.forwardedTo,
          priority: data.priority,
          comment: data.comment
        }

        this.firService.forward(this.firId, bodyData).subscribe(
          res => {
            this.firService.showNotification('success', res['msg'])
            this.fir = res['data']
            forwardDialogRef.close()
            this.refresh()
          },
          (error: HttpErrorResponse) => {
            this.firService.showNotification('error', error.error['msg'])
            forwardDialogRef.close()
          }
        )
      }
    })
  }

  escalateFir() {
    this.firService.escalate(this.firId).subscribe(
      res => {
        this.firService.showNotification('success', res['msg'])
        this.fir = res['data']
        this.refresh()
      },
      (error: HttpErrorResponse) => {
        this.firService.showNotification('error', error.error['msg'])
      }
    )
  }

  dismissFir() {
    this.firService.dismiss(this.firId).subscribe(
      res => {
        this.firService.showNotification('success', res['msg'])
        this.refresh()
      },
      (error: HttpErrorResponse) => {
        this.firService.showNotification('error', error.error['msg'])
      }
    )
  }

  viewRecord() {
    this.authService
      .findProcessbyId(this.fir.process.processId)
      .subscribe(processObj => {
        this.router.navigate([`${processObj.url}/records/${this.fir.recordId}`])
      })
  }

  refresh() {
    this.initialize()
  }

  initialize() {
    this.age = ''
    this.user = JSON.parse(localStorage.getItem(SESSION_STORAGE.DETAILS))
    this.route.params.subscribe(params => {
      if (params) {
        this.firId = params['id']
      }
    })
    this.firService.getFirById(this.firId).subscribe(firData => {
      this.recordViewService
        .getUserPermission(firData.process.processId)
        .subscribe(permission => {
          if (permission.teams.findIndex(team => team.title == 'admin') >= 0) {
            this.isAdmin = true
          }
          this.checkForward()
          this.checkEscalate()
        })
      this.fir = firData
      if (firData['closedAt']) this.closedAt = moment(firData['closedAt'])

      /* age calculations after fir is fetched */
      let before: any = moment(this.fir.createdAt).utc()
      let now: any
      if (this.fir.status == 'open') now = moment().utc()
      else now = moment(this.fir['closedAt']).utc()
      let duration = moment.duration(now.diff(before))
      if (duration.months()) this.age = `${duration.months()} month(s)`
      this.age = `${
        this.age
      } ${duration.days()} day(s) ${duration.hours()} hour(s) ${duration.minutes()} minute(s).`

      if (this.fir.status == 'open') this.age = 'Open since ' + this.age
      else if (this.fir.status == 'closed') this.age = 'Closed in ' + this.age
      else if (this.fir.status == 'dismissed')
        this.age = 'Dismissed in ' + this.age
      /* ends here */
    })
  }

  ngOnDestroy() {
    this.cd.detach()
    this.closedAt = ''
  }
}
